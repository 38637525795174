<template>
  <div class="expand-item panzoom-exclude">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1" v-if="indexTitle">
        {{ item.index_code }}
      </h2>
      <input
        type="text"
        class="bg-transparent fs-14 fw-bold w-full ellipse"
        v-model="item.title"
        :id="'item_input_' + item.id"
        :data-item-title-level-id="item.id"
        @change="regularUpdate($event, item)"
      />
    </div>
    <div class="item-chart-div">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <select
            class="
              brainstorm-prop-selector
              fs-10
              bg-transparent
              text-secondary-five
            "
            v-model="item.choosed_property_id"
          >
            <option
              :value="prop.id"
              v-for="(prop, propIndex) in moduleProperties"
              :key="propIndex"
              @change="setCollectionProperty(prop, item)"
            >
              {{ prop.title }}
            </option>
          </select>
          <select
            class="
              brainstorm-prop-selector
              fs-10
              text-primary-one
              bg-transparent
            "
            v-model="item.choosed_property_param_id"
            @change="setCollectionPropertyParam($event, item)"
          >
            <option
              :value="propParam.id"
              v-for="(propParam, propParamIndex) in getModulePropertyParams(
                item
              )"
              :key="propParamIndex"
            >
              {{ propParam.title }}
            </option>
          </select>
          <h4 class="fs-16 fw-bold font-poppins text-primary-one py-1">
            {{
              item.current_prop_param ? item.current_prop_param.toFixed(2) : 0.0
            }}
          </h4>
        </div>
        <div class="w-50 pl-4 flex justify-between items-end chartcontent pb-2">
          <div
            class="
              flex
              justify-end
              bg-primary-four
              opacity-75
              relative
              bar-radius
              shadow-two
            "
            style="max-height: 100%;"
            v-for="(propCollection, propCIndex) in getPropertyValue(item)"
            :key="propCIndex"
            :class="{
              'opacity-100':
                item.choosed_property_param_id &&
                parseInt(item.choosed_property_param_id) ===
                  parseInt(propCollection.property_collection_id),
            }"
            :style="{
              width: propCollection.width + 'px',
              height: propCollection.sample_value * 10 + '%',
            }"
          >
            <!--                      <div class="absolute max-prop text-primary-four fs-10" v-if="propCollection.max">-->
            <!--                        <h6>{{ propCollection.title }}:{{ propCollection.sample_value.toFixed(2) }}</h6>-->
            <!--                      </div>-->
          </div>
        </div>
      </div>
      <div @click="moveToNextDefinitionSet()">
        <h4 class="fs-12 font-poppins text-primary-one">
          {{ getLevelDefinition(item.current_prop_param) }}
        </h4>
      </div>
    </div>
    <div class="fs-10">
      <property
        :properties="getModulePropertyParams(item)"
        :activeScenarioCollection="item"
        :selectedPropSet="item.choosed_property_id"
      ></property>
    </div>
    <!--    <div class="fs-10">-->
    <!--      <p>Disruptive capabilities and business models is extending value creation.  Sample text.</p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Property from "@/components/brainstormv2/canvas/ScenarioItemProperty";
import { getFlattenCollections } from '@/store/modules/global/services';

import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  name: "ChartFeature",
  components: {
    Property,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    indexTitle: {
      type: Boolean,
    },
    moduleProperties: {
      type: Array,
    },
    module: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      definitionSets: "definition/definitionSets",
    }),
  },
  data() {
    return {
      activeDefinitionSet: 0,
    };
  },
  methods: {
    getLevelDefinition(value = 0) {
      let definitionSet = this.definitionSets[this.activeDefinitionSet];
      if (definitionSet) {
        let definition = definitionSet.items.find((data) => data.type === 2);
        if (definition) {
          if (value > 0) {
            value = value - 0.000001;
          }
          value = Math.floor(value / 2);
          if (value > 4) {
            value = 4;
          }
          let defText = definition.range_with_definition[value].definition;
          if (defText) {
            return defText;
          }
        }
      }
      return "Not found";
    },
    moveToNextDefinitionSet() {
      let length = this.definitionSets.length;
      if (length === this.activeDefinitionSet + 1) {
        this.activeDefinitionSet = 0;
      } else {
        this.activeDefinitionSet++;
      }
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    getModulePropertyParams(item) {
      let params = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(item.choosed_property_id)
      );
      if (params && params.property_collections.length > 0) {
        return params.property_collections.filter(
          (param) => !param.option || param.option == 1
        );
      }
      return [];
    },
    setCollectionPropertyParam(e, item) {
      this.setPropertyToOther(e, this.module)
      item.current_prop_param = parseFloat(
        this.getPropertyValueWithParam(item)
      );
      if(!this.flippedArray) return
      let data = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      if (data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    setPropertyToOther(e, module) {
      let allItem = getFlattenCollections(module.collections)
      allItem.forEach(item => item.choosed_property_param_id = e.target.value)
    },
    getPropertyValue(item) {
      let collection = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(item.choosed_property_id)
      );
      if (collection && collection.property_collections.length > 0) {
        let element = $(".chartcontent");
        let parameters = this.getSliderProperties(
          item,
          collection.property_collections
        );
        let values = parameters.filter((value) =>
          collection.property_collections.find(
            (prop_collection) =>
              parseInt(prop_collection.id) ===
              parseInt(value.property_collection_id)
          )
        );
        let width = (element.width() - values.length * 7) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if (value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if (index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          if (index === 0) {
            item.current_prop_param = parseFloat(
              this.getPropertyValueWithParam(item)
            );
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(
        (value) =>
          parseInt(value.property_collection_id) ===
          parseInt(item.choosed_property_param_id)
      );
      if (valueObj) {
        return valueObj.sample_value;
      }
      return 0;
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties
        .filter((data) => !data.option || data.option == 1)
        .map((param) => param.id);
      return item.properties.filter((data) =>
        sliderParams.includes(data.property_collection_id)
      );
    },
  },
};
</script>

<style scoped>
.chartcontent {
  height: 60px;
  width: 116px;
}
</style>
