<template>
  <div class="fs-14" :class="appearance == 0 ? 'text-white textarea-primary-three' : 'text-secondary-one textarea-secondary-one'">

    <!-- all level box container  -->
    <div class="flex">
      <!-- level-1 loop  -->
      <div v-for="(l1item, l1index) in getData(dataset.collections)" :key="l1index">
        <div class="flex">
          <vertical-item :item="l1item"
                         :index="l1index"
                         :module-properties="getModuleProperties"
                         :index-title="indexTitle"
                         :appearance="appearance"
                         :default-colors="defaultColors"
                         :active-data="activeData"
                         :flipped-array="flippedArray"
                         :expanded-array="expandedArray"
                         :isolated-modules="isolatedModules"
                         :l1="l1item"
                         :parent="module"
                         @setActiveData="setActiveData"
                         @menuHandler="menuHandler"
                         @changedDataFromCollaboration="changedDataFromCollaboration"
                         @removeItem="removeItem"
                         @regularUpdate="regularUpdate"
                         @collpaseItem="collpaseItem"
                         @expandItem="expandItem"
                         @newRootChild="newRootChild"
                         @newSubChild="newSubChild"
                         @updateParent="updateParent" />
        </div>

        <!-- level2 box container -->
        <div class="mb-8" :class="checkPosition(l1item.child) === 'h' ? 'flex' : 'flex-col'">
          <!-- level 2 loop  -->
          <div v-for="(l2item, l2index) in getData(l1item.child)" :key="l2index">
            <vertical-item :item="l2item"
                           :index="l2index"
                           :module-properties="getModuleProperties"
                           :index-title="indexTitle"
                           :appearance="appearance"
                           :default-colors="defaultColors"
                           :active-data="activeData"
                           :flipped-array="flippedArray"
                           :expanded-array="expandedArray"
                           :isolated-modules="isolatedModules"
                           :l1="l1item"
                           :l2="l2item"
                           :parent="l1item"
                           @setActiveData="setActiveData"
                           @menuHandler="menuHandler"
                           @changedDataFromCollaboration="changedDataFromCollaboration"
                           @removeItem="removeItem"
                           @regularUpdate="regularUpdate"
                           @collpaseItem="collpaseItem"
                           @expandItem="expandItem"
                           @newRootChild="newRootChild"
                           @newSubChild="newSubChild"
                           @updateParent="updateParent" />

            <!-- level3 box container -->
            <div class="mb-8" :class="checkPosition(l2item.child) === 'h' ? 'flex' : 'flex-col'">
              <!-- level 3 loop  -->
              <div v-for="(l3item, l3index) in getData(l2item.child)" :key="l3index">
                <vertical-item :item="l3item"
                               :index="l3index"
                               :module-properties="getModuleProperties"
                               :index-title="indexTitle"
                               :appearance="appearance"
                               :default-colors="defaultColors"
                               :active-data="activeData"
                               :flipped-array="flippedArray"
                               :expanded-array="expandedArray"
                               :isolated-modules="isolatedModules"
                               :l1="l1item"
                               :l2="l2item"
                               :l3="l3item"
                               :parent="l2item"
                               @setActiveData="setActiveData"
                               @menuHandler="menuHandler"
                               @changedDataFromCollaboration="changedDataFromCollaboration"
                               @removeItem="removeItem"
                               @regularUpdate="regularUpdate"
                               @collpaseItem="collpaseItem"
                               @expandItem="expandItem"
                               @newRootChild="newRootChild"
                               @newSubChild="newSubChild"
                               @updateParent="updateParent" />

                <!-- level4 box container -->
                <div class="flex-col mb-8">
                  <!-- level 4 loop -->
                  <div v-for="(l4item, l4index) in getData(l3item.child)" :key="l4index">
                    <vertical-item :item="l4item"
                                   :index="l4index"
                                   :module-properties="getModuleProperties"
                                   :index-title="indexTitle"
                                   :appearance="appearance"
                                   :default-colors="defaultColors"
                                   :active-data="activeData"
                                   :flipped-array="flippedArray"
                                   :expanded-array="expandedArray"
                                   :isolated-modules="isolatedModules"
                                   :l1="l1item"
                                   :l2="l2item"
                                   :l3="l3item"
                                   :l4="l4item"
                                   :parent="l3item"
                                   @setActiveData="setActiveData"
                                   @menuHandler="menuHandler"
                                   @changedDataFromCollaboration="changedDataFromCollaboration"
                                   @removeItem="removeItem"
                                   @regularUpdate="regularUpdate"
                                   @collpaseItem="collpaseItem"
                                   @expandItem="expandItem"
                                   @newRootChild="newRootChild"
                                   @newSubChild="newSubChild"
                                   @updateParent="updateParent" />
                  </div>
                </div>
                <!-- level4 box container -->
              </div>
            </div>
            <!-- level3 box container -->
          </div>
        </div>
        <!-- level2 box container -->
      </div>
    </div>
    <!-- all level box container  -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from "jquery";
import ChildCreateBox from "./ChildCreateBox";
import Property from './ItemProperty';
import VerticalItem from "@/components/brainstormv2/canvas/Module/Vertical/VerticalItem";
export default {
  name: "VrCanvas",
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
    customEmit: function (data) {
      // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  components: {VerticalItem, ChildCreateBox,Property},
  props: ['appearance', 'indexTitle', 'isolatedModules', 'contextModule'],
  data() {
    return {
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
      activeData: {},
      expandedArray: [],
      flippedArray: [],
    }
  },
  computed: {
    ...mapGetters({
      dataset: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes"
    }),
    moduleSettings() {
      if(this.dataset.module) {
        let settings = JSON.parse(this.dataset.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    getModuleProperties() {
      if(this.dataset && this.dataset.module_id) {
        return this.dataset.module.properties;
      }
      return [];
    },
    getLevelData() {
      if(this.dataset) {
        return this.dataset.collections;
      }
      return [];
    },
    module() {
      if(this.dataset && this.dataset.module_id) {
        return this.dataset.module;
      }
      return null;
    }
  },
  methods: {
    checkSelected(id) {
      if (this.activeData.id == id) {
        return true;
      }
      return false;
    },
    setActiveData(item) {
      this.activeData = item;
      this.$store.dispatch("build/getActiveModuleCollection", item)
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.dataset.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getWidth(item) {
      let child = this.getData(item.child);
      let pos = this.checkPosition(child)
      let count = child.length
      let width = 0
      let margin = 32

      if (pos == 'v') {
        return width += 265
      }

      if (count > 0) {
        child.forEach(el => {
          width += this.getWidth(el)
        });
        width += margin*(count-1)
      }
      else {
        width += 265
      }
      return width
    },
    checkPosition(child) {
      let pos = 'v'
      child.forEach(el => {
        if (el.child.length > 0) {
          pos = 'h'
        }
      });
      return pos
    },
    newRootChild() {
      this.$emit("newRootChild");
    },
    newSubChild(data) {
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$Progress.start();
      data.brainstorm_id = this.$route.params.brainstorm_id;
      if(this.dataset.module_id) {
        this.$store.dispatch("brainstormv1/buildParentUpdate", data).then(response => {
          this.$Progress.finish();
        });
      } else {
        this.$store.dispatch("brainstormv1/regularUpdate", data).then(response => {
          this.$Progress.finish();
        });
      }
    },
    regularUpdate(e, obj) {
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          id: obj.id
        };
        if(this.dataset.module_id) {
          this.$store.dispatch("build/regularUpdate", item);
        } else {
          item.brainstorm_id = this.$route.params.brainstorm_id;
          this.$store.dispatch("brainstormv1/regularUpdate", item);
        }
      }
    },
    removeItem(e, item) {
      if (e.keyCode === 46) {
        this.$Progress.start();
        if(this.dataset.module_id) {
          this.$store.dispatch("build/deleteItem", item).then(response => {
            this.$Progress.finish();
            this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id});
          });
        } else {
          this.$store.dispatch("brainstormv1/deleteItem", item).then(response => {
            this.$Progress.finish();
          });
        }

        this.$emit("changedDataFromCollaboration", {
          load: true
        });
      }
    },
    getL1Indexing(index, selfLevel, own = false) {
      let settings = this.moduleSettings;
      if(settings) {
        let indexing = '';
        let collection = settings.collections[selfLevel];
        if(settings.index_number) {
          indexing = parseFloat(collection.index_number) + index;
        }
        return indexing;
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      let settings = this.moduleSettings;
      return currentData + '.' + (index + 1);
    },
    getIndexId(selfLevel) {
      let settings = this.moduleSettings;
      if(settings) {
          if(settings.index_id) {
            let collection = settings.collections[selfLevel];
            return collection.index_id;
          }
      }
      return '';
    },
    menuHandler(e, item) {
      let top = e.y
      let left = e.x
      this.$emit('menu', {top: top, left: left, item: item})
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
    getData(items) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.dataset.module_id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", {
        id: `${initial}${item.id}`,
        flip_1: `flip_1_l${item.level}_${item.id}`,
        flip_2: `flip_2_l${item.level}_${item.id}`,
        value: value,
        title: item.title,
        description: item.description,
        load: false
      });
    },
    getModulePropertyParams(item) {
      let params = null
      if (this.dataset && this.dataset.module) {
        params = this.dataset.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      }

      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    getPropertyValue(item) {
      let collection = null
      if (this.dataset && this.dataset.module) {
        collection = this.dataset.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      }

      if(collection && collection.property_collections.length > 0) {
        let element = $(".chartcontent");
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (element.width() - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if(index === 0) {
            item.current_prop_param = this.getPropertyValueWithParam(item);
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await this.getPropertyValueWithParam(item);
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return parseFloat(valueObj.sample_value);
      }
      return 0;
    },
    // expandItem(item, partname) {
    //   item.partname = partname
    //   this.expandedArray.push(item.id)
    //   if(this.dataset && this.dataset.module_id && this.dataset.module && this.dataset.module.properties && this.dataset.module.properties.length > 0) {
    //     item.choosed_property_id = this.dataset.module.properties[0].id;
    //     item.choosed_property_param_id = this.dataset.module.properties[0].property_collections[0].id;
    //   }
    // },
    // collpaseItem(item) {
    //   var rmindex = this.expandedArray.findIndex(el => el == item.id);
    //   this.expandedArray.splice(rmindex, 1)
    // }
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id == item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id == item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    expandItem(item, partname) {
      if(this.dataset && this.dataset.module_id && this.dataset.module && this.dataset.properties && this.dataset.module.properties.length > 0) {
        item.choosed_property_id = this.dataset.module.properties[0].id;
        item.choosed_property_param_id = this.dataset.module.properties[0].property_collections[0].id;
      }
      this.expandedArray.push({id: item.id, partname: partname, prop: item.choosed_property_id, param: item.choosed_property_param_id, value: item.current_prop_param})
    },
    collpaseItem(item) {
      var rmindex = this.expandedArray.findIndex(el => el.id == item.id);
      this.expandedArray.splice(rmindex, 1)
    }
  },
  mounted() {
    // this.sockets.subscribe('brainstorm_data_updated', function (res) {
    //   if(res) {
    //     if(!res.load) {
    //       $(`#${res.id}`).val(res.value);
    //     }
    //   }
    // });
  }
}
</script>

<style scoped>
  /* .single {
    width: 265px;
  }
  .double {
    width: 562px;
  } */
  .default-width {
    width: 265px;
  }
  .item-des {
    height: 132px;
  }
  .item-no-des {
    height: 50px;
  }
  /*textarea {*/
  /*  height: 72px;*/
  /*}*/
  .textarea-primary-three textarea::placeholder {
    color: #C9C9C9;
  }
  .textarea-secondary-one textarea::placeholder {
    color: #8d8d8d;
  }
  .content-desc-height {
    height: 90px;
  }
  .property-image-icon {
  left: 10px;
  bottom: 10px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  left: 250px;
  bottom: -25px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  left: 210px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 10px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
