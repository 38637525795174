<template>
    <div>
        <div class="col_width todo">
            <Sticky :getSticky="getSticky" :deleteSticky="deleteSticky" :setStickyNotes="setStickyNotes"
                :createSticky="createSticky" :stickyNotes="stickyNotes" :dataConvertToSticky="dataConvertToSticky"
                :removeDeletedSticky="removeDeletedSticky" :activeBrainstormData="activeBrainstormData" />
        </div>
        <!-- <div
            @dragover.prevent
            class="relative brainstrom_wrapper main_dropzone w-full"
        >
            <template v-for="note in stickyNotes">
                <StickyNote
                :key="note.id"
                v-if="hasCoordination(note)"
                @deleteNote="(id) => deleteNote(id)"
                :id="note.id"
                :title="note.title"
                :description="note.description"
                :color="(note.settings && note.settings.bg_color) ? note.settings.bg_color : '#E7EDC0'"
                :coordination="{ x: note.coordination.x, y: note.coordination.y }"
                :class="'_absolute'"
                :data="note"
                />
            </template>
        </div> -->
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import router from "@/routes/routes.js";
import { useSticky } from "@/components/teamwork/brainstorm/sticky/stickyController";
import Sticky from "@/components/teamwork/brainstorm/sticky/Sticky";

const props =  defineProps({
    activeBrainstormData: Object
})

const route = router.currentRoute;

const {
      getSticky,
      deleteSticky,
      stickyNotes,
      setStickyNotes,
      createSticky,
      updateSticky,
      removeDeletedSticky,
      dataConvertToSticky,
      hasCoordination
} = useSticky();



onMounted(async () => {
    let notes = await getSticky(route.params.brainstorm_id);
    setStickyNotes(notes);
});
</script>