<template>
  <div class="flex flex-col prop-div-root justify-between">
    <div class="flex flex-col">
      <div class="flex">
        <h2 class="fs-14 fw-bold mr-1 text-primary-one font-poppins" v-if="indexTitle">{{ item.index_code }}</h2>
        <h2 class="fs-14 fw-bold w-full ellipse text-primary-one font-poppins" :id="'flip_1_item_' + item.id">{{ item.title }}</h2>
      </div>
      <select class="brainstorm-prop-selector fs-10 text-primary-one" v-model="item.choosed_property_id">
        <option :value="prop.id" v-for="(prop, propIndex) in moduleProperties" :key="propIndex" @change="setCollectionProperty(prop, item)">{{ prop.title }}</option>
      </select>
      <select class="brainstorm-prop-selector fs-10 text-primary-one" v-model="item.choosed_property_param_id" @change="setCollectionPropertyParam(item)">
        <option :value="propParam.id" v-for="(propParam, propParamIndex) in getModulePropertyParams(item)" :key="propParamIndex">{{ propParam.title }}</option>
      </select>
    </div>
    <div class="flex justify-content-between items-end">
      <div class="w-50 overflow-hidden pb-1">
        <h4 class="fs-16 fw-bold font-poppins text-primary-one py-1">
          {{ item.current_prop_param ? item.current_prop_param.toFixed(2) : 0.00 }}
        </h4>
        <h4 class="fs-12 font-poppins text-primary-one">Scaled capability</h4>
      </div>
      <div class="w-50 pl-4 flex justify-between items-end chartcontent pb-2">
        <div class="flex justify-end bg-primary-four opacity-75 relative bar-radius shadow-two"
             v-for="(propCollection, propCIndex) in getPropertyValue(item)"
             :key="propCIndex"
             :class="{'opacity-100': item.choosed_property_param_id && parseInt(item.choosed_property_param_id) === parseInt(propCollection.property_collection_id)}"
             :style="{'width': propCollection.width + 'px', 'height': propCollection.sample_value * 10 + '%'}">
          <!--                      <div class="absolute max-prop text-primary-four fs-10" v-if="propCollection.max">-->
          <!--                        <h6>{{ propCollection.title }}:{{ propCollection.sample_value.toFixed(2) }}</h6>-->
          <!--                      </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";

export default {
  name: "FlippedParameterScreen",
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    },
    moduleProperties: {
      type: Array
    },
    flippedArray: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
    })
  },
  created() {
    if(this.item.choosed_property_id) {
      let propItem = this.moduleProperties.find(item => item.id === this.item.choosed_property_id);
      this.item.choosed_property_id = propItem.id;
      let collections = propItem.property_collections && propItem.property_collections.length > 0 ? propItem.property_collections.filter(item => !item.option || item.option == 1) : [];
      if(collections.length > 0) {
        let collectionId = collections.map(item => item.id);
        let filterFromItems = this.item.properties.filter(item => collectionId.includes(item.property_collection_id));
        if(filterFromItems.length > 0) {
          const max = filterFromItems.reduce((prev, current) => (prev.sample_value > current.sample_value) ? prev : current);
          if(max) {
            this.item.choosed_property_param_id = max.property_collection_id;
            this.item.current_prop_param = max.sample_value;
          }
        }
      }
    }
  },
  methods: {
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await this.getPropertyValueWithParam(item);
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return parseFloat(valueObj.sample_value);
      }
      return 0;
    },
    getModulePropertyParams(item) {
      let params = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    getPropertyValue(item) {
      let collection = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(collection && collection.property_collections.length > 0) {
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let element = $(".chartcontent");
        let boxWidth = element.width() ? element.width() : 116;
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (boxWidth - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if(index === 0) {
            item.current_prop_param = this.getPropertyValueWithParam(item);
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
  }
}
</script>

<style scoped>
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.prop-div-root {
  height: 116px;
}
.prop-div-exp-root {
  height: 100px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.expanded-icon img {
  width: 10px;
}
.expand-item {
  height: 250px;
}
</style>
