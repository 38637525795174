<template>
  <div>
    <div class="flex justify-between fs-14 text-secondary-one concept-body mt-10 mb-46">
      <span class="text-primary-one fw-600">Business Concepts</span>
      <div class="flex">
        <img class="ml-5 cursor-pointer" :class="{'opacity-60' : link != 1, 'pointer-events-none': Object.keys(concept).length == 0}"
             src="/images/icons/brainstormV2/items.svg" alt=""
             @click="toggleItem" />
        <img class="ml-5 cursor-pointer" :class="{'opacity-60' : link != 2, 'pointer-events-none': Object.keys(concept).length == 0 || Object.keys(activeItem).length == 0}"
             src="/images/icons/brainstormV2/properties.svg" alt=""
             @click="toggleProperties" />
        <img class="ml-5 cursor-pointer" src="/images/icons/brainstormV2/save_as.svg" alt="" @click="conceptCreateModalVisibility = true" />
      </div>
    </div>
    <!-- default view  -->
    <div v-if="link == 0 && Object.keys(concept).length > 0" class="default-container">
      <!-- styles -->
      <div class="i-mb-30 bg-primary-three">
        <div
          class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer"
          :class="!canEdit(project.cans)&&'pointer-events-none'"
          @click="toggleDropdown('h-style-set')">

          <span class="truncate">{{ canvasStyles[activeStyle] }}</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
          <div style="display: none;" id="h-style-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden">
            <h4 v-for="(item, index) in canvasStyles" :key="index"
                class="py-2 px-5 hover:bg-secondary-four"
                v-if="item && index !== 2 || (index === 2 && activeBrainstormData.scenario_id)"
                :disabled="activeBrainstormData && !activeBrainstormData.scenario_id && index === 2"
                :class="{'pointer-events-none opacity-50 cursor-default': activeBrainstormData && !activeBrainstormData.scenario_id && index === 2}"
                @click="setCanvasStyle(index)">
              {{ item }}
            </h4>
          </div>
        </div>
      </div>

      <div v-if="activeStyle !== 2">
        <div class="i-mb-30" v-if="activeBrainstormData && activeBrainstormData.scenario_id">
          <h2 class="text-primary-one i-mb-15">Module</h2>
          <div
            class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
            @click="toggleDropdown('h-module-set')"
            :class="!canEdit(project.cans)&&'pointer-events-none'"
          >
            <span class="truncate">{{ oneCanvasModule ? getModuleName : 'Select module' }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
            <div style="display: none; max-height: 400px; width: 274px" id="h-module-set"
                 class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-y-auto i-border-1 border-secondary-five">
              <h4 v-for="(item, index) in activeBrainstormData.modules" :key="index"
                  class="py-2 px-5 hover:bg-secondary-four "
                  @click="setModule(item)">
                {{ item.title }}
              </h4>
            </div>
          </div>
        </div>

        <!-- horizontal proper selection -->
        <div class="flex-col i-mb-30">
          <h2 class="text-primary-one i-mb-15">Horizontal Parameter</h2>

          <div
            class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
            @click="toggleDropdown('h-prop-set')"
            :class="!canEdit(project.cans)&&'pointer-events-none'"
          >
            <span class="truncate">{{ propset[hrPropSet] ? propset[hrPropSet].title : 'Select property set' }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
            <div style="display: none; max-height: 400px; width: 274px" id="h-prop-set"
                 class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
              <p v-for="(item, index) in propset.filter(param => !param.option || param.option == 1)" :key="index"
                 class="py-2 px-5 hover:bg-secondary-four "
                 @click="hrPropSet = index, storePropIntoDb('h', item)">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
            @click="toggleDropdown('h-param-set')"
            :class="!canEdit(project.cans)&&'pointer-events-none'"
          >
            <span class="truncate">{{ propset[hrPropSet] && propset[hrPropSet].property_collections[hrPropParam] ? propset[hrPropSet].property_collections[hrPropParam].title : 'Select property param' }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
            <div style="display: none; max-height: 400px; width: 274px" id="h-param-set"
                 class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="propset[hrPropSet]">
              <p v-for="(item, index) in propset[hrPropSet].property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                 class="py-2 px-5 hover:bg-secondary-four "
                 @click="updateHorizontalParam(index, item.id)">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
        <!-- horizontal proper selection -->

        <!-- vertical proper selection -->
        <div class="flex-col i-mb-30">
          <h2 class="text-primary-one i-mb-15">Vertical Parameter</h2>
          <div
            class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
            @click="toggleDropdown('v-prop-set')"
            :class="!canEdit(project.cans)&&'pointer-events-none'"
          >
            <span class="truncate">{{ propset[vrPropSet] ? propset[vrPropSet].title : 'Select property set' }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
            <div style="display: none; max-height: 400px; width: 274px" id="v-prop-set"
                 class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
              <p v-for="(item, index) in propset" :key="index"
                 class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                 @click="vrPropSet = index, storePropIntoDb('v', item)">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
            @click="toggleDropdown('v-param-set')"
            :class="!canEdit(project.cans)&&'pointer-events-none'"
          >
            <span class="truncate">{{ propset[vrPropSet] &&  propset[vrPropSet].property_collections[vrPropParam] ? propset[vrPropSet].property_collections[vrPropParam].title : 'Select property param' }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
            <div style="display: none; max-height: 400px; width: 274px" id="v-param-set"
                 class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="propset[vrPropSet]">
              <p v-for="(item, index) in propset[vrPropSet].property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                 class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                 @click="updateVerticalParam(index, item.id)">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
        <!-- vertical proper selection -->
      </div>

      <div v-else>
        <!-- canvas one -->
        <div class="flex justify-between">
          <h2 class="fs-14 text-primary-one fw-bold mb-1">Canvas One</h2>
          <img src="/images/icons/chevron_down_black.svg" alt="icon"
            class="cursor-pointer"
            :class="{'rotate-180': !canvasToggler.first}"
            @click="canvasToggler.first = !canvasToggler.first" />
        </div>
        <div v-if="canvasToggler.first" class="i-border-b-1 border-secondary-two">
          <div class="i-mb-30" v-if="activeBrainstormData && activeBrainstormData.scenario_id">
            <h2 class="text-primary-one i-mb-15">Module</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-module1-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ cOneModule ? getModuleName : 'Select module' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none; max-height: 400px; width: 274px" id="h-module1-set"
                   class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
                <h4 v-for="(item, index) in activeBrainstormData.modules" :key="index"
                    class="py-2 px-5 hover:bg-secondary-four "
                    @click="setConceptSideBySideModule(0, item)">
                  {{ item.title }}
                </h4>
              </div>
            </div>
          </div>

          <!-- horizontal proper selection -->
          <div class="flex-col i-mb-30">
            <h2 class="text-primary-one i-mb-15">Horizontal Parameter</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-prop1-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ canvasOneHProperty ? canvasOneHProperty.title : 'Select property set' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none; max-height: 400px; width: 274px" id="h-prop1-set"
                   class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="canvasFirstModule">
                <p v-for="(item, index) in canvasFirstModule.properties" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four "
                   @click="setModulePropertyForLogic('h', item)">
                  {{ item.title }}
                </p>
              </div>
            </div>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-param1-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span id="c_one_h_p">Select property param</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none; max-height: 400px; width: 274px" id="h-param1-set"
                   class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="canvasOneHProperty">
                <p v-for="(item, index) in canvasOneHProperty.property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four "
                   @click="setPropertyParamValue(1, 'h', item, 'c_one_h_p')">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
          <!-- horizontal proper selection -->

          <!-- vertical proper selection -->
          <div class="flex-col i-mb-30">
            <h2 class="text-primary-one i-mb-15">Vertical Parameter</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('v-prop1-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ canvasOneVProperty ? canvasOneVProperty.title : 'Select property set' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none; max-height: 400px; width: 274px" id="v-prop1-set"
                   class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="canvasFirstModule">
                <p v-for="(item, index) in canvasFirstModule.properties" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                   @click="setModulePropertyForLogic('v', item)">
                  {{ item.title }}
                </p>
              </div>
            </div>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('v-param1-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span id="c_one_v_p">Select property param</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none; max-height: 400px; width: 274px" id="v-param1-set"
                   class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto" v-if="canvasOneHProperty">
                <p v-for="(item, index) in canvasOneHProperty.property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                   @click="setPropertyParamValue(1, 'v', item, 'c_one_v_p')">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
          <!-- vertical proper selection -->
        </div>

        <div class="flex justify-between i-mt-30" :class="{'i-mb-30': !canvasToggler.second}">
          <h2 class="fs-14 text-primary-one fw-bold mb-1">Canvas Two</h2>
          <img src="/images/icons/chevron_down_black.svg" alt="icon"
               class="cursor-pointer"
               :class="{'rotate-180': !canvasToggler.second}"
               @click="canvasToggler.second = !canvasToggler.second" />
        </div>

        <!-- canvas two -->
        <div v-if="canvasToggler.second">
          <div class="i-mb-30" v-if="activeBrainstormData && activeBrainstormData.scenario_id">
            <h2 class="text-primary-one i-mb-15">Module</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-module2-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ canvasSecondModule ? canvasSecondModule.title : 'Select module' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none;" id="h-module2-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden">
                <h4 v-for="(item, index) in activeBrainstormData.modules" :key="index"
                    class="py-2 px-5 hover:bg-secondary-four "
                    @click="setConceptSideBySideModule(1, item)">
                  {{ item.title }}
                </h4>
              </div>
            </div>
          </div>

          <!-- horizontal proper selection -->
          <div class="flex-col i-mb-30">
            <h2 class="text-primary-one i-mb-15">Horizontal Parameter</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-prop2-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ canvasTwoHProperty ? canvasTwoHProperty.title : 'Select property set' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none;" id="h-prop2-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden" v-if="canvasSecondModule">
                <p v-for="(item, index) in canvasSecondModule.properties" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four "
                   @click="canvasTwoHProperty = item">
                  {{ item.title }}
                </p>
              </div>
            </div>

            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('h-param2-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span id="c_two_h_p">Select property param</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none;" id="h-param2-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden" v-if="canvasTwoHProperty">
                <p v-for="(item, index) in canvasTwoHProperty.property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four "
                   @click="setPropertyParamValue(2, 'h', item, 'c_two_h_p')">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
          <!-- horizontal proper selection -->

          <!-- vertical proper selection -->
          <div class="flex-col i-mb-30">
            <h2 class="text-primary-one i-mb-15">Vertical Parameter</h2>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('v-prop2-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span class="truncate">{{ canvasTwoVProperty ? canvasTwoVProperty.title : 'Select property set' }}</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none;" id="v-prop2-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden" v-if="canvasSecondModule">
                <p v-for="(item, index) in canvasSecondModule.properties" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                   @click="canvasTwoVProperty = item">
                  {{ item.title }}
                </p>
              </div>
            </div>
            <div
              class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
              @click="toggleDropdown('v-param2-set')"
              :class="!canEdit(project.cans)&&'pointer-events-none'"
            >
              <span id="c_two_v_p">Select property param</span>
              <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
              <div style="display: none;" id="v-param2-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden" v-if="canvasTwoVProperty">
                <p v-for="(item, index) in canvasTwoVProperty.property_collections.filter(param => !param.option || param.option == 1)" :key="index"
                   class="py-2 px-5 hover:bg-secondary-four cursor-pointer"
                   @click="setPropertyParamValue(2, 'v', item, 'c_two_v_p')">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
          <!-- vertical proper selection -->
        </div>
      </div>

      <div>
        <div class="flex justify-between">
          <h2 class="fs-14 text-primary-one fw-bold mb-1">Definitions</h2>
          <img src="/images/icons/chevron_down_black.svg" alt="icon"
               class="cursor-pointer"
               :class="{'rotate-180': !canvasToggler.definition}"
               @click="canvasToggler.definition = !canvasToggler.definition" />
        </div>
        <!-- horizontal definition -->
        <div v-if="canvasToggler.definition" class="flex-col i-mb-30">
          <div class="flex justify-between items-center i-mb-15">
            <span>Horizontal Definitions</span>
            <img
              v-if="canCreate(project.cans)"
              class="i-ml-30 cursor-pointer"
              src="/images/icons/brainstormV2/plus_circle_outline.svg"
              alt=""
              @click="addNewDefinition(0)">
          </div>
          <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm def-item bg-primary-three" v-for="(item, index) in getHrDefinitions" :key="index">
            <input
              type="text"
              class="bg-transparent"
              :value="item" @change="updateDefinition($event, index, 0)"
              :disabled="!canEdit(project.cans)"
            >
            <img
              class="i-ml-30 cursor-pointer invisible rm-icon"
              src="/images/icons/brainstormV2/close.svg"
              alt=""
              v-if="canDelete(project.cans)"
              @click="removeDefinition(index, 0)">
          </div>
        </div>
        <!-- horizontal definition -->

        <!-- vertical definition -->
        <div v-if="canvasToggler.definition" class="flex-col i-mb-30">
          <div class="flex justify-between items-center i-mb-15">
            <span>Vertical Definitions</span>
            <img
              v-if="canCreate(project.cans)"
              class="i-ml-30 cursor-pointer"
              src="/images/icons/brainstormV2/plus_circle_outline.svg"
              alt=""
              @click="addNewDefinition(1)">
          </div>
          <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm def-item bg-primary-three" v-for="(item, index) in getVrDefinitions" :key="index">
            <input
              type="text"
              class="bg-transparent"
              :value="item"
              @change="updateDefinition($event, index, 1)"
              :disabled="!canEdit(project.cans)"
            >
            <img
              class="i-ml-30 cursor-pointer invisible rm-icon"
              src="/images/icons/brainstormV2/close.svg"
              alt=""
              v-if="canDelete(project.cans)"
              @click="removeDefinition(index, 1)">
          </div>
        </div>
        <!-- vertical definition -->
      </div>
    </div>
    <!-- default view  -->
    <div class="concept-item-navigation overflow-y-auto" v-if="link === 1">
      <item-list :serial="1"
                 :isolatedModules="isolatedModules"
                 @toggleIsolation="toggleIsolation" @removeIsolation="removeIsolation"></item-list>
      <item-list :serial="2"
                 :isolatedModules="canvasTwoIsolation"
                 v-if="canvasSecondModule && activeStyle === 2"
                 @toggleIsolation="toggleIsolation" @removeIsolation="removeIsolation"></item-list>
    </div>
    <property v-else-if="link == 2" @notifyUpdate="notifyUpdate"></property>


    <Modal  v-model="conceptCreateModalVisibility">
      <loading-modal :dModal="loadingModal" :rootItem="rootItem" @close="() => conceptCreateModalVisibility = false" />
    </Modal>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  import Modal from '@/elements/atom/Modal'

  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import { mapGetters } from "vuex";
import ItemList from "./ItemList";
import Property from "./Property";
import LoadingModal from "./LoadingModal";
import { get } from 'lodash'
export default {
  name: "LeftDefault",
  props: ['isolatedModules'],
  components: {ItemList, Property, LoadingModal},
  created() {
    this.setPropertyParams();
    this.$store.dispatch("concept/getConcepts", {
      brainstorm_id: this.$route.params.brainstorm_id,
      project_id: this.$route.params.id
    }).then(response => {
      //
    });
  },
  data() {
    return {
      link: 0,
      conceptCreateModalVisibility: false,
      loadingModal: false,
      rootItem: [],
      hrPropSet: null,
      vrPropSet: null,
      hrPropParam: null,
      vrPropParam: null,
      canvasStyles: ['Default style', '4 zone concept', 'Side by side logic', '', '3 Horizons'],
      canvasOneHProperty: null,
      canvasOneVProperty: null,
      canvasTwoHProperty: null,
      canvasTwoVProperty: null,
      canvasToggler: {
        first: true,
        second: true,
        definition: true
      }
    };
  },
  watch: {
    concept: function (obj) {
      if (obj) {
        this.setPropertyParams()
      }
    }
  },
  computed: {
    ...mapGetters({
      brainstorms: "brainstormv1/brainstorms",
      propset: "build/modulePropertySet",
      concept: "concept/concept",
      activeModuleCollection: "build/activeModuleCollection",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      conceptModule: "concept/conceptModule",
      canvasFirstModule: "concept/canvasFirstModule",
      canvasSecondModule: "concept/canvasSecondModule",
      canvasPropValue: "concept/canvasPropValue",
      canvasTwoIsolation: "concept/canvasTwoIsolation",
      project: "project/project",
    }),
    getHrDefinitions() {
      let data = []
      if (Object.keys(this.concept).length > 0 && this.concept.definitions) {
        data = this.concept.definitions[0]
      }
      return data
    },
    getVrDefinitions() {
      let data = []
      if (Object.keys(this.concept).length > 0 && this.concept.definitions) {
        data = this.concept.definitions[1]
      }
      return data
    },
    activeItem() {
      if (this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
        return this.activeScenarioCollection
      }
      else {
        return this.activeModuleCollection
      }
    },
    activeStyle() {
      return this.concept.style;
    },
    cOneModule() {
      if(this.concept.scenario_settings.module_id) {
        this.setFullModule();
        return this.concept.scenario_settings.module_id || this.concept.module_id;
      }
      return null;
    },
    oneCanvasModule() {
      if(this.concept.scenario_settings.module_id) {
        this.setOneCanvasModuleSettings();
        return this.concept.scenario_settings.module_id;
      }
      return null;
    },
    getModuleName() {
      let data = this.activeBrainstormData.modules.find(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.module_id));
      if(data) {
        return data.title;
      }
      return null;
    }
  },
  methods: {
    setFullModule() {
      let moduleId = this.concept.scenario_settings.module_id;
      let module = this.activeBrainstormData.modules.find(data => parseInt(data.id) === parseInt(moduleId));
      this.$store.dispatch("concept/setSideBySideModules", {
        index: 0,
        module: module
      });

      if(module) {
        this.setSideProperty(module);
      }
    },
    setSideProperty(module) {
      if(module.properties.length > 0) {
        if(this.concept.scenario_settings.property_h_id) {
          let item = module.properties.find(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_id));
          if(item) {
            this.canvasOneHProperty = item;
            this.setSidePropertyParam(item,  'h');
          }
        }

        if(this.concept.scenario_settings.property_v_id) {
          let item = module.properties.find(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_v_id));
          if(item) {
            this.canvasOneVProperty = item;
            this.setSidePropertyParam(item, 'v');
          }
        }
      }
    },
    setSidePropertyParam(item , type) {
      if(item.property_collections.length > 0) {
        if(this.concept.scenario_settings.property_h_p_id && type === 'h') {
          let pItem = item.property_collections.find(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_p_id));
          if(pItem) {
            let data = {
              type: 'h',
              serial: 1,
              item: pItem
            }

            this.$store.dispatch("concept/setSideLogicCanvasPropertyParams", data);

            setTimeout(() => {
              let el = document.getElementById('c_one_h_p');
              el.innerHTML = pItem.title;
            }, 200);
          }
        }

        if(this.concept.scenario_settings.property_h_v_id && type === 'v') {
          let pItem = item.property_collections.find(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_v_id));
          if(pItem) {
            let data = {
              type: 'v',
              serial: 1,
              item: pItem
            }

            this.$store.dispatch("concept/setSideLogicCanvasPropertyParams", data);

            setTimeout(() => {
              let el = document.getElementById('c_one_v_p');
              el.innerHTML = pItem.title;
            }, 200);
          }
        }
      }
    },
    setOneCanvasModuleSettings() {
      let moduleId = this.concept.scenario_settings.module_id;
      let module = this.activeBrainstormData.modules.find(data => parseInt(data.id) === parseInt(moduleId));
      this.selectedModule = module;
      this.$store.dispatch("concept/setConceptModule", module);
      if(get(module, 'properties') && module.properties.length) {
        this.$store.dispatch("build/tempSetPropertySet", module.properties);

        // horizontal
        let propertyHIndex = module.properties.findIndex(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_id));
        if(propertyHIndex >= 0) {
          this.hrPropSet = propertyHIndex;
          let property = module.properties[propertyHIndex];
          if(property && property.property_collections && property.property_collections.length > 0) {
            let propertyHPIndex = property.property_collections.findIndex(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_p_id));
            if(propertyHPIndex >= 0) {
              this.hrPropParam = propertyHPIndex;
            }
          }
        }

        // vertical
        let propertyVIndex = module.properties.findIndex(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_v_id));
        if(propertyVIndex >= 0) {
          this.vrPropSet = propertyVIndex;
          let property = module.properties[propertyHIndex];
          if(property && property.property_collections && property.property_collections.length > 0) {
            let propertyVPIndex = property.property_collections.findIndex(data => parseInt(data.id) === parseInt(this.concept.scenario_settings.property_h_v_id));
            if(propertyVPIndex >= 0) {
              this.vrPropParam = propertyVPIndex;
            }
          }
        }
      }
    },
    toggleItem() {
      if (this.link != 1) {
        this.link = 1
      }
      else {
        this.link = 0
      }
    },
    toggleProperties() {
      if (this.link != 2) {
        this.link = 2
      }
      else {
        this.link = 0
      }
    },
    toggleLoadingModalMode(value) {
      this.loadingModal = value;
    },
    toggleDropdown(id) {
      let el = document.getElementById(id)

      if (el) {
        let display = el.style.display
        if (display == 'none') {
          el.style.display = 'flex'
        }
        else if(display == 'flex') {
          el.style.display = 'none'
        }
      }
    },
    setCanvasStyle(index) {
      this.concept.style = index;
      let data = {
        style: index,
        id: this.concept.id,
        title: this.concept.title,
        project_id: this.$route.params.id,
        brainstorm_id: this.$route.params.brainstorm_id
      };
      this.$store.dispatch("concept/setCanvasStyle", index);
      this.$store.dispatch("concept/updateConcept", data);
    },
    setPropertyParamValue(serial, type, item, id) {
      let el = document.getElementById(id);
      el.innerHTML = item.title;
      let data = {
        type: type,
        serial: serial,
        item: item
      }
      this.$store.dispatch("concept/setSideLogicCanvasPropertyParams", data);

      if(serial === 1) {
        if(type === 'h') {
          this.concept.scenario_settings.property_h_p_id = item.id;
        } else {
          this.concept.scenario_settings.property_h_v_id = item.id;
        }

        let data = {
          id: this.concept.id,
          title: this.concept.title,
          scenario_settings: this.concept.scenario_settings
        };
        this.$store.dispatch("concept/updateConcept", data);
      }
    },
    storePropIntoDb(type, item) {
      if(type === 'h') {
        this.concept.scenario_settings.property_h_id = item.id;
      } else {
        this.concept.scenario_settings.property_v_id = item.id;
      }

      if(this.activeBrainstormData.scenario_id) {
        let data = {
          id: this.concept.id,
          title: this.concept.title,
          scenario_settings: this.concept.scenario_settings
        };
        this.$store.dispatch("concept/updateConcept", data);
      }
    },
    updateHorizontalParam(index, id) {
      this.hrPropParam = index
      this.concept.hr_property_collection_id = id

      if(this.activeBrainstormData.scenario_id) {
        this.concept.scenario_settings.property_h_p_id = id;
      }

      this.$store.dispatch("concept/loadParametersDefinition", {
        parameters: [this.concept.hr_property_collection_id, this.concept.vr_property_collection_id],
        vertical_parameter: this.concept.vr_property_collection_id
      });
      this.$store.dispatch("concept/updateConcept", this.concept);
    },
    updateVerticalParam(index, id) {
      this.vrPropParam = index;
      this.concept.vr_property_collection_id = id;

      if(this.activeBrainstormData.scenario_id) {
        this.concept.scenario_settings.property_h_v_id = id;
      }

      this.$store.dispatch("concept/loadParametersDefinition", {
        parameters: [this.concept.hr_property_collection_id, this.concept.vr_property_collection_id],
        vertical_parameter: this.concept.vr_property_collection_id
      });

      this.$store.dispatch("concept/updateConcept", this.concept);
    },
    setPropertyParams() {
      if (Object.keys(this.concept).length > 0 && this.propset.length > 0) {
        this.propset.forEach((el, index) => {
          let hrparamIndex = el.property_collections.findIndex(i => i.id == this.concept.hr_property_collection_id)
          let vrparamIndex = el.property_collections.findIndex(i => i.id == this.concept.vr_property_collection_id)
          if (hrparamIndex != -1) {
            this.hrPropSet = index
            this.hrPropParam = hrparamIndex
          }
          else {
            this.hrPropSet = null
            this.hrPropParam = null
          }
          if (vrparamIndex != -1) {
            this.vrPropSet = index
            this.vrPropParam = vrparamIndex
          }
          else {
            this.vrPropSet = null
            this.vrPropParam = null
          }
        });
      }
    },
    addNewDefinition(defIndex) {
      if (this.concept.definitions) {
        let defArr = this.concept.definitions[defIndex]
        if (defArr.length == 5) {
          alert('You have reached the maximum limit!')
          return false
        }
        this.concept.definitions[defIndex].push('New Definition')
      }
      else {
        this.concept.definitions = [['New Definition'], ['New Definition']]
      }
      this.$store.dispatch("concept/updateConcept", this.concept)
    },
    updateDefinition(e, index, defIndex) {
      let val = e.target.value
      this.concept.definitions[defIndex][index] = val
      this.$store.dispatch("concept/updateConcept", this.concept)
    },
    removeDefinition(index, defIndex) {
      let defArr = this.concept.definitions[defIndex]
      if (defArr.length == 2) {
        alert('You must keep atleast two definitions!')
        return false
      }
      defArr.splice(index, 1)
      this.$store.dispatch("concept/updateConcept", this.concept)
    },
    setModule(item) {
      this.selectedModule = item;
      this.$store.dispatch("concept/setConceptModule", item);
      this.$store.dispatch("build/tempSetPropertySet", item.properties);

      this.concept.scenario_settings.module_id = item.id;
      let data = {
        id: this.concept.id,
        title: this.concept.title,
        scenario_settings: this.concept.scenario_settings
      };
      this.$store.dispatch("concept/updateConcept", data);
    },
    setConceptSideBySideModule(index, item) {
      this.selectedModule = item;
      this.$store.dispatch("concept/setSideBySideModules", {
        index: index,
        module: item
      });

      if(index === 0) {
        this.concept.scenario_settings.module_id = item.id;

        let data = {
          id: this.concept.id,
          title: this.concept.title,
          scenario_settings: this.concept.scenario_settings
        };
        this.$store.dispatch("concept/updateConcept", data);
      }

      this.$store.dispatch("concept/emptyIsolation");
    },
    setModulePropertyForLogic(type, item) {
      if(type === 'h') {
        this.canvasOneHProperty = item;
        this.concept.scenario_settings.property_h_id = item.id;
      } else {
        this.canvasOneVProperty = item;
        this.concept.scenario_settings.property_v_id = item.id;
      }

      let data = {
        id: this.concept.id,
        title: this.concept.title,
        scenario_settings: this.concept.scenario_settings
      };
      this.$store.dispatch("concept/updateConcept", data);
    },
    toggleIsolation(data) {
      this.$emit("toggleIsolation", data);
    },
    removeIsolation(moduleId) {
      this.$emit("removeIsolation", moduleId);
    }
  },
};
</script>

<style scoped>
.concept-body {
  max-height: 907px;
  overflow: scroll;
}
.dropdown-box {
  top: 40px;
  left: 0;
  z-index: 10;
}
.def-item:hover .rm-icon {
  visibility: visible;
}
.default-container {
  max-height: 800px;
  overflow-y: scroll;
}
.mb-46 {
  margin-bottom: 46px;
}
.concept-item-navigation {
  height: 785px;
  width: 275px;
  overflow-x: hidden;
}

/* @media all and (max-width: 1850px){
  .concept-body{
    flex-wrap: wrap;
    gap: 10px;
  }
  .concept-body img{
    margin: 0;
  }
} */
</style>
