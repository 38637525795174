import { ref } from 'vue';
import { io } from "socket.io-client";

export const useSocket = () => {
    const socket = ref(null);
    // VUE_APP_LOCAL_SOCKET_URL
    // VUE_APP_SERVER_SOCKET_URL
    socket.value = io(process.env.VUE_APP_SERVER_SOCKET_URL);
    
    socket.value.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          socket.connect();
        }
      });
    return {
        socket,
        io
    }
} 