<template>
    <div class="property-body fs-14 text-secondary-one">
        <div class="mb-6 relative">
          <img v-if="previewURL" :src="previewURL" alt="image" class="block w-full _my_img_size">
          <img v-else :src="(activeScenarioCollection.file) ? activeScenarioCollection?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full _my_img_size">
          <label class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
            <!-- <input @input="(e)=>{onChange(e), updateImage({file, id:activeScenarioCollection.id})}" hidden type="file" accept="image/x-png,image/jpeg" /> -->

            <input @input="(e)=>{onChange(e), updateImage(
              {file, id:activeScenarioCollection.id},
                ()=>{
                  activeScenarioCollection.file = {file_path: previewURL}
                  previewURL = ''
                }
              )}"
                   :disabled="!canEdit(project.cans)"
              hidden type="file" accept="image/x-png,image/jpeg" />

            <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
              <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
            </svg>
          </label>
        </div>

        <div class="mb-10" v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0">
          <div class="flex items-center space-x-1">
            <span class="text-primary-one ls-5 font-poppins fs-14 fw-600">{{ activeScenarioCollection.index_code }}</span>
            <input type="text" class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-primary-one fw-600 ls-5 font-poppins fs-14"
                   v-model="activeScenarioCollection.title"
                   :disabled="!canEdit(project.cans)"
                   @change="updateItem(activeScenarioCollection)" />
          </div>
          <!-- <p v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0"
             :contenteditable="canEdit(project.cans)"
             @input="updateItem(activeScenarioCollection, $event)"
             class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize">
            {{ activeScenarioCollection.description }}
          </p> -->
          <TextareaAutoHeight
            v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0"
            v-model="activeScenarioCollection.description"
            :isDisabled="!canEdit(project.cans)"
            @onType="updateItem(activeScenarioCollection)"
          />
        </div>
        <router-link :to="{path: '/build/'+$route.params.id+'/properties/'+activeScenarioCollection.module_id+'/'+activeScenarioCollection.id}" :event="activeModule && activeScenarioCollection.id ? 'click' : ''" :title="!activeScenarioCollection.id ? 'Please, select an item first' : ''">
          <div class="flex justify-between cursor-pointer items-center pb-3 mb-8 i-border-b-1 border-secondary-two">
            <p class="fw-500 fs-12 text-secondary-two">
              Manage properties and Association
            </p>
            <img src="/images/icons/build/manage_property.svg" alt="icon" />
          </div>
        </router-link>
        <div class="p-0 bg-transparent relative" v-if="!hideProperty">
            <div class="i-border-b-1 border-secondary-two i-mb-30" v-for="(dataset, index) in properties.filter(el => el.is_visible)" :key="index">
                <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
<!--                  <span class="">{{ dataset.title }}</span>-->
                  <input type="text" v-model="dataset.title" class="bg-transparent text-primary-one fw-600 w-full" @input="updateProperty(dataset)" />
                  <img :id="'down-arrow-'+index" class="p-2" src="/images/icons/build/chevron_down.svg" alt="image" @click="statusShow(index)">
                  <img :id="'up-arrow-'+index" class="p-2 hidden" src="/images/icons/build/chevron_up.svg" alt="image" @click="statusHide(index)">
                </div>
                <div class="hidden" :id="'value-cont-'+index">
                  <div class="flex justify-start items-center i-mb-25" v-for="(prop, pIndex) in dataset.property_collections.filter(el => el.is_visible)" :key="pIndex"
                       @mouseover="showValue(index, pIndex)" @mouseleave="hideValue(index, pIndex)">
<!--                      <p class="prop-items i-mr-15">{{ prop.title }}</p>-->
                      <input type="text" v-model="prop.title" class="bg-transparent prop-items i-mr-15" @input="updateParameter(prop)" />
                      <property-sample v-if="!prop.option || prop.option == 1"
                                       :property="prop"
                                       :index="index"
                                       :p-index="pIndex"
                                       :item="activeScenarioCollection"
                                       @updateParameterData="updateData" />
                      <input-property v-if="prop.option == 2"
                                      :property="prop"
                                      :index="index"
                                      :p-index="pIndex"
                                      :item="activeScenarioCollection"
                                      @updateParameterData="updateData" />
<!--                        <span class="absolute hidden" :id="'value-hover-'+index+'-'+pIndex" :style="{'left': '48%', top: '-25px'}">-->
<!--                          {{ !prop.format || parseInt(prop.format) === 1 ? prop.sample_value : (prop.sample_value * 10) + '%' }}-->
<!--                        </span>-->
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'
  // import usePermission from '@/composable/usePermission.js'
  import usePermission from '@/components/teamwork/brainstorm/usePermission.js'
  import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight'
  const { onChange, previewURL, file } = useFile()
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios'
    import PropertySample from "../../properties/PropertySample";
    import InputProperty from "@/components/properties/InputProperty";
    import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'
    export default {
      name: "ScenarioProperty",
      components: {InputProperty, PropertySample},
      // props: ['viewer', 'hideProperty'],
      props: {
        hideProperty: {
          type: Boolean,
          default: false
        }
      },
        data() {
          return {
            // active : this.properties[0]
          }
        },
        computed: {
          ...mapGetters({
              properties: "build/modulePropertySet",
              activeScenarioCollection: "programmatic/activeScenarioCollection",
              activeModule: "build/navigatorModule",
              activeBrainstormData: "brainstormv1/activeBrainstormData",
              project: "project/project",
          })
        },
        methods: {
          statusShow(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'block'
            up.style.display = 'block'
            down.style.display = 'none'
          },
          statusHide(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'none'
            up.style.display = 'none'
            down.style.display = 'block'
          },
          showValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'block'
          },
          hideValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'none'
          },
          async updateImage({file, id}, callback)
          {
            // console.log(file)
            let formData = new FormData()
            formData.append('id', id)
            formData.append('image', file)
            await axios.post('projects/scenarios/collections/image', formData)
            callback()
          },

          updateData(obj) {
            let { shareContentApi } = brainstormContentSharing();
            shareContentApi();
            let updateItem = this.activeScenarioCollection.properties.find(p => p.property_collection_id == obj.id);
            if(updateItem) {
              updateItem.sample_value = parseFloat(obj.sample_value)
            } else {
              updateItem = {
                scenario_collection_id: this.activeScenarioCollection.id,
                sc_id: this.activeScenarioCollection.id,
                scenario_id: this.activeScenarioCollection.scenario_id,
                module_id: this.activeScenarioCollection.module_id,
                property_collection_id: obj.property_collection_id,
                id: obj.property_collection_id,
                module_collection_id: this.activeScenarioCollection.module_collection_id,
                sample_value: obj.sample_value,
                value: obj.sample_value
              };
              this.activeModuleCollection.properties.push(updateItem);
            }

            this.$store.dispatch("brainstormv1/updatePropertyItemFromScenario", updateItem)
            .then(response => {
                if (response) {
                  this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.activeBrainstormData.id})
                }
            })
            this.$emit("changedDataFromCollaboration", {
              load: true
            });
          },
          updateItem(obj, e = null) {
            // console.log(obj, 'obj')
            let objData = Object.assign({}, obj);
            if(e) {
              objData.description = e.target.innerText;
            }
            this.$store.dispatch("programmatic/updateCollection", objData);
          },
          autoHeight(e) {
            let textarea = e.target
            textarea.addEventListener("input", function (e) {
              this.style.height = "auto";
              this.style.height = this.scrollHeight + "px";
            });
          },
          updateProperty(property) {
            this.$store.dispatch("manageProperty/updateProperty", {
              id: property.id,
              title: property.title,
              module_id: property.module_id
            });
          },
          updateParameter(parameter) {
            this.$store.dispatch("manageProperty/updatePropertyCollectionItem", {
              id: parameter.id,
              title: parameter.title
            });
          }
        },
    }
</script>

<style scoped>
.property-body {
  padding: 35px 0;
  max-height: 907px;
  overflow-y: auto;
  overflow-x: hidden;
}
.prop-items {
  width: 150px;
}
.assoc-items {
  width: 250px;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
