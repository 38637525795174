<template>
  <div
      @click="setActiveData(item)"
      class="br-level-w br-level-height px-4 pt-2 pb-2 relative rounded"
      :style="{'background': getBgWithFlip(item.level, item)}"
      :class="{
                'i-border-1': activeData.id === item.id || appearance === 1,
                'shadow-two' : appearance === 0,
                'border-secondary-five' : appearance === 1 && activeData.id !== item.id,
                'border-primary-one' : activeData.id === item.id,

            }"
      @contextmenu.prevent="menuHandler($event, item)">
    <div class="flip-card">
      <div class="flip-card-inner">
        <not-flipped-screen v-if="!isFlipped(item)"
                            :item="item"
                            :index-title="indexTitle"
                            :expanded-array="expandedArray"
                            :module-properties="moduleProperties"
                            @changedDataFromCollaboration="changedDataFromCollaboration"
                            @regularUpdate="regularUpdate"
                            @collpaseItem="collpaseItem" />

        <div class="flip-card-back" v-if="isFlipped(item)">
          <flipped-parameter-screen v-if="flippedCount(item) === 1"
                                    :item="item"
                                    :index-title="indexTitle"
                                    :module-properties="moduleProperties"
                                    :flipped-array="flippedArray" />
          <flipped-image-screen v-if="flippedCount(item) === 2"
                                :item="item"
                                :index-title="indexTitle" />
        </div>
      </div>
    </div>

    <indicator :hasChild="item.child.length" :index="index" :parent="getParent" :previousChildCount="getPreviousChildCount(parentChild, index, item.level)" />
    <child-create-box v-if="activeData && activeData.id === item.id"
                      :item="item"
                      :current="item"
                      :l1="l1"
                      :l2="l2"
                      :l3="l3"
                      :l4="l4"
                      @newRootChild="newRootChild"
                      @newSubChild="newSubChild"
                      @updateParent="updateParent" />

    <div class="absolute expand-text-icon z-10 pt-1" v-show="activeData.id === item.id" @click="expandItem(item, 'text')">
      <img src="/images/icons/brainstormV2/exp-text.svg"
           alt="icon"
           class="cursor-pointer" />
    </div>
    <div class="absolute expand-icon z-10 pt-1" v-show="activeData.id === item.id" @click="expandItem(item, 'chart')">
      <img src="/images/icons/build/properties.svg"
           alt="icon"
           class="cursor-pointer" />
    </div>
    <div class="absolute property-icon rounded-sm cursor-pointer"
         :class="{'i-border-1 border-secondary-two': !isFlipped(item), 'shadow-two': isFlipped(item)}"
         :style="{'background': isFlipped(item) ? getFlipBg(item.level, item) : 'transparent'}"
         @click="flipCard(item)"
         v-if="activeBrainstormData && activeBrainstormData.module_id && activeData && activeData.id === item.id" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Indicator from "@/components/brainstormv2/canvas/Indicator";
import ChildCreateBox from "@/components/brainstormv2/canvas/ChildCreateBox";
import Property from '@/components/brainstormv2/canvas/ItemProperty';
import NotFlippedScreen from "@/components/brainstormv2/canvas/Module/Horizontal/NotFlippedScreen";
import FlippedImageScreen from "@/components/brainstormv2/canvas/Module/Horizontal/FlippedImageScreen";
import FlippedParameterScreen from "@/components/brainstormv2/canvas/Module/Horizontal/FlippedParameterScreen";

export default {
  name: "HorizontalItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
    },
    moduleProperties: {
      type: Array
    },
    indexTitle: {
      type: Boolean
    },
    appearance: {
      type: Number
    },
    defaultColors: {
      type: Array
    },
    activeData: {
      type: Object
    },
    flippedArray: {
      type: Array
    },
    expandedArray: {
      type: Array
    },
    parentChild: {
      type: Array
    },
    l1: {
      type: Object
    },
    l2: {
      type: Object
    },
    l3: {
      type: Object
    },
    l4: {
      type: Object
    }
  },
  components: {
    FlippedParameterScreen,
    FlippedImageScreen,
    NotFlippedScreen,
    Indicator,
    ChildCreateBox,
    Property
  },
  data() {
    return {
      imageObj: null,
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes"
    }),
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    getParent() {
      return this.item.level !== 1;
    }
  },
  methods: {
    setActiveData(item) {
      this.$emit("setActiveData", item);
    },
    getBgWithFlip(level, item) {
      if(this.isFlipped(item)) {
        return "#FFFFFF";
      } else {
        return this.appearance === 0 ? this.getBg(level, item) : 'transparent';
      }
    },
    isFlipped(item) {
      this.setFlippedData(item);
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      return !!(flippedItem && flippedItem.flip_count >= 1);
    },
    setFlippedData(item) {
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.choosed_property_id;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.choosed_property_param_id;
      }
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getFlipBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return "";
        }
      }
      return "";
    },
    menuHandler(e, item) {
      this.$emit("menuHandler", e, item);
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    removeItem(e, item) {
      this.$emit("removeItem", e, item);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e,  item);
    },
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id == item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id == item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await this.getPropertyValueWithParam(item);
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return parseFloat(valueObj.sample_value);
      }
      return 0;
    },
    getModulePropertyParams(item) {
      let params = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    getPropertyValue(item) {
      let collection = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(collection && collection.property_collections.length > 0) {
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let element = $(".chartcontent");
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (element.width() - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if(index === 0) {
            item.current_prop_param = this.getPropertyValueWithParam(item);
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    collpaseItem(item) {
      this.$emit("collpaseItem", item);
    },
    flippedCount(item) {
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(flippedItem && flippedItem.flip_count > 0) {
        return flippedItem.flip_count;
      }
      return 0;
    },
    getTempImage() {
      return require('@/assets/images/flip_image.jpg');
    },
    uploadFile(item) {
      this.imageObj = item;
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        let fileUrl = URL.createObjectURL(f);
        this.imageObj.temp_image = fileUrl;
      });
    },
    expandItem(item, partname) {
      this.$emit("expandItem", item, partname);
    },
    newRootChild() {
      this.$emit("newRootChild");
    },
    newSubChild(data) {
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$emit("updateParent", data);
    },
    flipCard(item) {
      this.$emit("flipCard", item);
    },
    getPreviousChildCount(data, currentIndex, level) {
      if(currentIndex > 0) {
        if(level === 2) {
          let count = 0;
          data[currentIndex - 1].child.forEach(item => {
            if(item.child.length > 1) {
              count += item.child.length;
            } else {
              count += 1;
            }
          });
          return count;
        } else {
          return data[currentIndex - 1].child.length;
        }
      }
      return 1;
    },
  }
}
</script>

<style scoped>
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
.flip-card {
  /* width: 100%; */
  /* perspective: 1000px; */
}
.flip-card-inner {
  transition: transform 0.6s;
  /* transform-style: preserve-3d; */
}
.flip-card-toggle .flip-card-inner {
  /* transform: rotateY(180deg); */
}
.flip-card-front, .flip-card-back {
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  /* -webkit-backface-visibility: hidden; */
  /* backface-visibility: hidden; */
}
.flip-card-back {
  /* color: white; */
  /* transform: rotateY(180deg); */
  background: #FFFFFF;
}
.prop-div-root {
  height: 116px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 10px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
