<template>
    <div class="property-body fs-14 text-secondary-one">
        <div class="mb-6">
          <img src="/images/build/demo_image_1.jpg" alt="image">
        </div>
        <div class="mb-10" v-if="activeModuleCollection && Object.keys(activeModuleCollection).length !== 0">
          <h2 class="fw-600 text-primary-one">{{ activeModuleCollection.title }}</h2>
          <p class="fs-12 break-words" v-if="activeModuleCollection && Object.keys(activeModuleCollection).length !== 0">
            {{ activeModuleCollection.description ? activeModuleCollection.description : 'No description available' }}
          </p>
        </div>
        <router-link :to="{path: '/build/'+$route.params.id+'/properties/'+activeModule+'/'+activeModuleCollection.id}" :event="activeModule && activeModuleCollection.id ? 'click' : ''" :title="!activeModuleCollection.id ? 'Please, select an item first.' : ''">
          <div class="flex justify-between cursor-pointer items-center pb-3 mb-8 i-border-b-1 border-secondary-two">
            <p class="fw-500 fs-12 text-secondary-two">
              Manage properties and Association
            </p>
            <img src="/images/icons/build/manage_property.svg" alt="icon" />
          </div>
        </router-link>
        <div class="p-0 bg-transparent relative">
            <div class="i-border-b-1 border-secondary-two i-mb-30" v-for="(dataset, index) in properties.filter(el => el.is_visible)" :key="index">
                <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
                  <span class="">{{ dataset.title }}</span>
                  <img :id="'down-arrow-'+index" class="p-2" src="/images/icons/build/chevron_down.svg" alt="image" @click="statusShow(index)">
                  <img :id="'up-arrow-'+index" class="p-2 hidden" src="/images/icons/build/chevron_up.svg" alt="image" @click="statusHide(index)">
                </div>
                <div class="hidden" :id="'value-cont-'+index">
                  <div class="flex justify-start items-center i-mb-25" v-for="(prop, pIndex) in dataset.property_collections.filter(el => el.is_visible)" :key="pIndex" @mouseover="showValue(index, pIndex)" @mouseleave="hideValue(index, pIndex)">
                      <p class="prop-items i-mr-15">{{ prop.title }}</p>
                      <property-sample v-if="!prop.option || prop.option == 1"
                                       :property="prop"
                                       :index="index"
                                       :p-index="pIndex"
                                       :item="activeModuleCollection"
                                       @updateParameterData="updateData" />
                      <input-property v-if="prop.option == 2"
                                      :property="prop"
                                      :index="index"
                                      :p-index="pIndex"
                                      :item="activeModuleCollection"
                                      @updateParameterData="updateData" />
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PropertySample from "../../properties/PropertySample";
    import InputProperty from "@/components/properties/InputProperty";
    export default {
        name: "Property",
      components: {InputProperty, PropertySample},
      props: ['viewer'],
        data() {
          return {
            // active : this.properties[0]
          }
        },
        computed: {
          ...mapGetters({
              properties: "build/modulePropertySet",
              activeModuleCollection: "build/activeModuleCollection",
              activeModule: "build/navigatorModule",
              activeBrainstormData: "brainstormv1/activeBrainstormData"
          }),
        },
        methods: {
          statusShow(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'block'
            up.style.display = 'block'
            down.style.display = 'none'
          },
          statusHide(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'none'
            up.style.display = 'none'
            down.style.display = 'block'
          },
          showValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'block'
          },
          hideValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'none'
          },
          updateData(obj) {
            let updateItem = this.activeModuleCollection.properties.find(p => p.property_collection_id == obj.property_collection_id);
            if(!updateItem) {
              updateItem = {
                module_collection_id: this.activeModuleCollection.id,
                module_id: this.activeModuleCollection.module_id,
                property_collection_id: obj.property_collection_id,
                sample_value: obj.sample_value
              };
              obj = updateItem;
              this.activeModuleCollection.properties.push(updateItem);
            }
            this.$store.dispatch("brainstormv1/updatePropertyItemFromBuild", obj)
            .then(response => {
                if (response) {
                  this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.activeBrainstormData.id})
                }
            })

            this.$emit("changedDataFromCollaboration", {
              load: true
            });
          },
        },
    }
</script>

<style scoped>
.property-body {
  padding: 35px 0;
  max-height: 907px;
  overflow-y: auto;
  overflow-x: hidden;
}
.prop-items {
  width: 150px;
}
.assoc-items {
  width: 250px;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
