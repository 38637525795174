<template>
  <div class="flex flex-col prop-div-root">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1 text-primary-one font-poppins" v-if="indexTitle">{{ item.index_code }}</h2>
      <h2 class="fs-14 fw-bold w-full ellipse text-primary-one font-poppins" :id="'flip_2_item_' + item.id">{{ item.title }}</h2>
    </div>
    <div class="mt-2 relative">
      <img :src="item.temp_image ? item.temp_image : getTempImage()" alt="image" class="flipped-image rounded" />
      <img src="/images/icons/property_image_icon.svg"
           alt="icon"
           @click="uploadFile(item)"
           class="absolute property-image-icon cursor-pointer" />
    </div>

    <input
        type="file"
        ref="file"
        style="display: none"
        accept="image/*"
        @change="addFile($event)"
    />
  </div>
</template>

<script>
export default {
  name: "FlippedImageScreen",
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    }
  },
  data() {
    return {
      imageObj: null
    }
  },
  methods: {
    getTempImage() {
      return require('@/assets/images/flip_image.jpg');
    },
    uploadFile(item) {
      this.imageObj = item;
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        let fileUrl = URL.createObjectURL(f);
        this.imageObj.temp_image = fileUrl;
      });
    },
  }
}
</script>

<style scoped>

</style>
