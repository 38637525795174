<template>
  <div v-if="getLevelData" :class="appearance === 0 ? 'text-white textarea-primary-three' : 'text-secondary-one textarea-secondary-one'">
    <!--level 1 loop-->
    <div class="flex font-poppins" v-for="(l1, l1Index) in getData(getLevelData)" :key="l1Index" :class="{'mt-8': l1Index > 0}">
      <horizontal-item :item="l1"
                       :index="l1Index"
                       :module-properties="getModuleProperties"
                       :index-title="indexTitle"
                       :appearance="appearance"
                       :default-colors="defaultColors"
                       :active-data="activeData"
                       :flipped-array="flippedArray"
                       :expanded-array="expandedArray"
                       :parent-child="getData(getLevelData)"
                       :l1="l1"
                       @setActiveData="setActiveData"
                       @menuHandler="menuHandler"
                       @changedDataFromCollaboration="changedDataFromCollaboration"
                       @removeItem="removeItem"
                       @regularUpdate="regularUpdate"
                       @collpaseItem="collpaseItem"
                       @expandItem="expandItem"
                       @flipCard="flipCard"
                       @newRootChild="newRootChild"
                       @newSubChild="newSubChild"
                       @updateParent="updateParent" />

      <div class="flex flex-col ml-8">
        <!--level 2 loop-->
        <div class="flex" v-for="(l2, l2Index) in getData(l1.child)" :key="l2Index" :class="{'mt-8': l2Index > 0}">
          <horizontal-item :item="l2"
                           :index="l2Index"
                           :module-properties="getModuleProperties"
                           :index-title="indexTitle"
                           :appearance="appearance"
                           :default-colors="defaultColors"
                           :active-data="activeData"
                           :flipped-array="flippedArray"
                           :expanded-array="expandedArray"
                           :parent-child="getData(l1.child)"
                           :l1="l1"
                           :l2="l2"
                           @setActiveData="setActiveData"
                           @menuHandler="menuHandler"
                           @changedDataFromCollaboration="changedDataFromCollaboration"
                           @removeItem="removeItem"
                           @regularUpdate="regularUpdate"
                           @collpaseItem="collpaseItem"
                           @expandItem="expandItem"
                           @flipCard="flipCard"
                           @newRootChild="newRootChild"
                           @newSubChild="newSubChild"
                           @updateParent="updateParent" />

          <div class="flex flex-col ml-8">
            <!--level 3 loop-->
            <div class="flex" v-for="(l3, l3Index) in getData(l2.child)" :key="l3Index" :class="{'mt-8': l3Index > 0}">
              <horizontal-item :item="l3"
                               :index="l3Index"
                               :module-properties="getModuleProperties"
                               :index-title="indexTitle"
                               :appearance="appearance"
                               :default-colors="defaultColors"
                               :active-data="activeData"
                               :flipped-array="flippedArray"
                               :expanded-array="expandedArray"
                               :parent-child="getData(l2.child)"
                               :l1="l1"
                               :l2="l2"
                               :l3="l3"
                               @setActiveData="setActiveData"
                               @menuHandler="menuHandler"
                               @changedDataFromCollaboration="changedDataFromCollaboration"
                               @removeItem="removeItem"
                               @regularUpdate="regularUpdate"
                               @collpaseItem="collpaseItem"
                               @expandItem="expandItem"
                               @flipCard="flipCard"
                               @newRootChild="newRootChild"
                               @newSubChild="newSubChild"
                               @updateParent="updateParent" />

              <div class="flex flex-col ml-8">
                <!--level 4 loop-->
                <div class="flex" v-for="(l4, l4Index) in getData(l3.child)" :key="l4Index" :class="{'mt-8': l4Index > 0}">
                  <horizontal-item :item="l4"
                                   :index="l4Index"
                                   :module-properties="getModuleProperties"
                                   :index-title="indexTitle"
                                   :appearance="appearance"
                                   :default-colors="defaultColors"
                                   :active-data="activeData"
                                   :flipped-array="flippedArray"
                                   :expanded-array="expandedArray"
                                   :parent-child="getData(l3.child)"
                                   :l1="l1"
                                   :l2="l2"
                                   :l3="l3"
                                   :l4="l4"
                                   @setActiveData="setActiveData"
                                   @menuHandler="menuHandler"
                                   @changedDataFromCollaboration="changedDataFromCollaboration"
                                   @removeItem="removeItem"
                                   @regularUpdate="regularUpdate"
                                   @collpaseItem="collpaseItem"
                                   @expandItem="expandItem"
                                   @flipCard="flipCard"
                                   @newRootChild="newRootChild"
                                   @newSubChild="newSubChild"
                                   @updateParent="updateParent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Indicator from "./Indicator";
import { mapGetters } from 'vuex';
import ChildCreateBox from "./ChildCreateBox";
import Property from './ItemProperty';
import $ from 'jquery';
import HorizontalItem from "@/components/brainstormv2/canvas/Module/Horizontal/HorizontalItem";

export default {
  name: "HrCanvas",
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
    customEmit: function (data) {
      // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  components: {HorizontalItem, ChildCreateBox, Indicator, Property},
  props: ['appearance', 'indexTitle', 'flippedArray', 'isolatedModules', 'contextModule'],
  data() {
    return {
      activeData: {},
      freeTitles: [
        ['Identification', 'Identifier', 'Area', 'Area Measure', "Measure"],
        ['Capability', 'Capability Group', 'Area', 'Area Measure', "Measure"],
        ['Enabler', 'Enabler Group', 'Area', 'Area Measure', "Measure"],
        ['Design', 'Design Group', 'Area', 'Area Measure', "Measure"],
        ['Programme', 'Programme Group', 'Area', 'Area Measure', "Measure"],
        ['Plan', 'Plan Group', 'Area', 'Area Measure', "Measure"],
        ['Solution', 'Solution Group', 'Area', 'Area Measure', "Measure"],
        ['Effect', 'Effect Group', 'Area', 'Area Measure', "Measure"],
        ['Outcome', 'Outcome Group', 'Area', 'Area Measure', "Measure"],
      ],
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
      imageObj: null,
      expandedArray: []
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes"
    }),
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    getLevelData() {
      if(this.activeBrainstormData) {
        return this.activeBrainstormData.collections;
      }
      return [];
    },
    getModuleProperties() {
      if(this.activeBrainstormData && this.activeBrainstormData.module_id) {
        return this.activeBrainstormData.module.properties;
      }
      return [];
    },
  },
  methods: {
    getPreviousChildCount(data, currentIndex, level) {
      if(currentIndex > 0) {
        if(level === 2) {
          let count = 0;
          data[currentIndex - 1].child.forEach(item => {
            if(item.child.length > 1) {
              count += item.child.length;
            } else {
              count += 1;
            }
          });
          return count;
        } else {
          return data[currentIndex - 1].child.length;
        }
      }
      return 1;
    },
    setActiveData(item) {
      this.activeData = item;
      this.$store.dispatch("build/getActiveModuleCollection", this.activeData)
    },
    newRootChild() {
      this.$emit("newRootChild");
    },
    newSubChild(data) {
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$Progress.start();
      data.brainstorm_id = this.$route.params.brainstorm_id;
      if(this.activeBrainstormData.module_id) {
        this.$store.dispatch("brainstormv1/buildParentUpdate", data).then(response => {
          this.$Progress.finish();
        });
      } else {
        this.$store.dispatch("brainstormv1/regularUpdate", data).then(response => {
          this.$Progress.finish();
        });
      }
    },
    regularUpdate(e, obj) {
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          id: obj.id
        };
        if(this.activeBrainstormData.module_id) {
          this.$store.dispatch("build/regularUpdate", item);
        } else {
          item.brainstorm_id = this.$route.params.brainstorm_id;
          this.$store.dispatch("brainstormv1/regularUpdate", item);
        }
      }
    },
    removeItem(e, item) {
      if (e.keyCode === 46) {
        this.$Progress.start();
        if(this.activeBrainstormData.module_id) {
          this.$store.dispatch("build/deleteItem", item).then(response => {
            this.$Progress.finish();
            this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id});
          });
        } else {
          this.$store.dispatch("brainstormv1/deleteItem", item).then(response => {
            this.$Progress.finish();
          });
        }

        this.$emit("changedDataFromCollaboration", {
          load: true
        });
      }
    },
    getL1Indexing(index, selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        if(settings) {
          let indexing = '';
          let collection = settings.collections[selfLevel];
          if(settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        return currentData + '.' + (index + 1);
      }
    },
    getIndexId(selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        if(settings) {
          if(settings.index_id) {
            let collection = settings.collections[selfLevel];
            return collection.index_id;
          }
        }
      }
      return '';
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getFlipBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return "";
        }
      }
      return "";
    },
    menuHandler(e, item) {
      let top = e.y
      let left = e.x
      this.$emit('menu', {top: top, left: left, item: item})
    },
    flipCard(item) {
      if(this.activeBrainstormData && this.activeBrainstormData.module && this.activeBrainstormData.module.properties && this.activeBrainstormData.module.properties.length > 0) {
        item.choosed_property_id = this.activeBrainstormData.module.properties[0].id;
        item.choosed_property_param_id = this.activeBrainstormData.module.properties[0].property_collections[0].id;
        let data = {
          choosed_property_id: item.choosed_property_id,
          choosed_property_param_id: item.choosed_property_param_id,
          id: item.id
        };
        this.$emit("toggleFlippedArray", data);
      }
    },
    getBgWithFlip(level, item) {
      if(this.isFlipped(item)) {
        return "#FFFFFF";
      } else {
        return this.appearance == 0 ? this.getBg(level, item) : 'transparent'
      }
    },
    getModulePropertyParams(item) {
      let params = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    getPropertyValue(item) {
      let collection = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(collection && collection.property_collections.length > 0) {
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let element = $(".chartcontent");
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (element.width() - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if(index === 0) {
            item.current_prop_param = this.getPropertyValueWithParam(item);
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await this.getPropertyValueWithParam(item);
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return parseFloat(valueObj.sample_value);
      }
      return 0;
    },
    isFlipped(item) {
      this.setFlippedData(item);
      // let index = this.flippedArray.findIndex(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      return !!(flippedItem && flippedItem.flip_count >= 1);

      // return index >= 0;
    },
    flippedCount(item) {
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(flippedItem && flippedItem.flip_count > 0) {
        return flippedItem.flip_count;
      }
      return 0;
    },
    setFlippedData(item) {
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.choosed_property_id;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.choosed_property_param_id;
      }
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
    getData(items) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.activeBrainstormData.module_id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    getTempImage() {
      return require('../../../assets/images/flip_image.jpg');
    },
    uploadFile(item) {
      this.imageObj = item;
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        let fileUrl = URL.createObjectURL(f);
        this.imageObj.temp_image = fileUrl;
      });
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", {
        id: `${initial}${item.id}`,
        flip_1: `flip_1_l${item.level}_${item.id}`,
        flip_2: `flip_2_l${item.level}_${item.id}`,
        value: value,
        title: item.title,
        description: item.description,
        load: false
      });
    },
    // expandItem(item, partname) {
    //   item.partname = partname
    //   this.expandedArray.push(item.id)
    //   if(this.activeBrainstormData && this.activeBrainstormData.module_id && this.activeBrainstormData.module && this.activeBrainstormData.module.properties && this.activeBrainstormData.module.properties.length > 0) {
    //     item.choosed_property_id = this.activeBrainstormData.module.properties[0].id;
    //     item.choosed_property_param_id = this.activeBrainstormData.module.properties[0].property_collections[0].id;
    //   }
    // },
    // collpaseItem(item) {
    //   var rmindex = this.expandedArray.findIndex(el => el == item.id);
    //   this.expandedArray.splice(rmindex, 1)
    // }
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id == item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id == item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    expandItem(item, partname) {
      if(this.activeBrainstormData && this.activeBrainstormData.module_id && this.activeBrainstormData.module && this.activeBrainstormData.properties && this.activeBrainstormData.module.properties.length > 0) {
        item.choosed_property_id = this.activeBrainstormData.module.properties[0].id;
        item.choosed_property_param_id = this.activeBrainstormData.module.properties[0].property_collections[0].id;
      }
      this.expandedArray.push({id: item.id, partname: partname, prop: item.choosed_property_id, param: item.choosed_property_param_id, value: item.current_prop_param})
    },
    collpaseItem(item) {
      var rmindex = this.expandedArray.findIndex(el => el.id == item.id);
      this.expandedArray.splice(rmindex, 1)
    }
  },
  mounted() {
    // this.sockets.subscribe('brainstorm_data_updated', function (res) {
    //   if(res) {
    //     if(!res.load) {
    //       $(`#${res.id}`).val(res.value);
    //       $(`#${res.flip_1}`).text(res.title);
    //       $(`#${res.flip_2}`).text(res.title);
    //     }
    //   }
    // });
  }
}
</script>

<style scoped>
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
.flip-card {
  /* width: 100%; */
  /* perspective: 1000px; */
}
.flip-card-inner {
  transition: transform 0.6s;
  /* transform-style: preserve-3d; */
}
.flip-card-toggle .flip-card-inner {
  /* transform: rotateY(180deg); */
}
.flip-card-front, .flip-card-back {
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  /* -webkit-backface-visibility: hidden; */
  /* backface-visibility: hidden; */
}
.flip-card-back {
  /* color: white; */
  /* transform: rotateY(180deg); */
  background: #FFFFFF;
}
.prop-div-root {
  height: 116px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 10px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>

