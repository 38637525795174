<template>
  <div class="expand-item">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1" v-if="indexTitle">{{ item.index_code }}</h2>
      <input type="text"
             class="bg-transparent fs-14 fw-bold w-full ellipse"
             v-model="item.title"
             :id="'item_input_' + item.id"
             @keyup="changedDataFromCollaboration('item_input_', item, item.title)"
             @change="regularUpdate($event, item)">
    </div>
    <textarea class="bg-transparent fs-12 w-full content-desc-height bld-content-area"

              v-model="item.description"
              :id="'item_text_' + item.id"
              @keyup="changedDataFromCollaboration('item_text_', item, item.description)"
              placeholder="Add descriptive text"
              @change="regularUpdate($event, item)"></textarea>
  </div>
</template>

<script>
export default {
  name: "TextFeature",
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    }
  },
  methods: {
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    }
  }
}
</script>

<style scoped>

</style>
