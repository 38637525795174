<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          placeholder="Search"
      />
    </div>

    <div class="mt-8 brainstorm-navigation overflow-y-auto">
      <!--level 1-->
      <div class="flex flex-col pl-10 pr-10" v-for="(l1, l1Index) in getData(getLevelsData)" :key="l1Index" :class="{'mt-8': l1Index > 0}">
        <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(l1)">
          <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer i-border-1 border-solid border-transparent"
               :class="{'border-primary-one': l1.id === activeModuleCollection.id}"
               :style="{'background': getBg(1, l1)}">
            <div class="flex items-center pr-left-content w-full bld-level-w">
              <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">{{ l1.index_code }} {{ l1.title }}</span>
            </div>
            <div
                class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l1)">
              <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l1.expanded}">
            </div>
          </div>
        </div>

        <!--level 2-->
        <div class="flex flex-col mt-8" v-for="(l2, l2Index) in getData(l1.child)" :key="l2Index" v-if="l1.expanded">
          <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(l2, [l1.id])">
            <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer i-border-1 border-solid border-transparent"
                 :class="{'border-primary-one': l2.id === activeModuleCollection.id}"
                 :style="{'background': getBg(2, l2)}">
              <div class="flex items-center pr-left-content w-full">
                <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                  {{ l2.index_code }} {{ l2.title }}
                </span>
              </div>
              <div
                  class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l2)">
                <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l2.expanded}">
              </div>
            </div>
          </div>

          <!--level 3-->
          <div class="flex flex-col mt-8" v-for="(l3, l3Index) in getData(l2.child)" :key="l3Index" v-if="l2.expanded">
            <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(l3, [l1.id, l2.id])">
              <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer i-border-1 border-solid border-transparent"
                   :class="{'border-primary-one': l3.id === activeModuleCollection.id}"
                   :style="{'background': getBg(3, l3)}">
                <div class="flex items-center pr-left-content w-full">
                  <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                    {{ l3.index_code }} {{ l3.title }}
                  </span>
                </div>
                <div
                    class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l3)">
                  <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l3.expanded}">
                </div>
              </div>
            </div>

            <!--level 4-->
            <div class="mt-8" v-for="(l4, l4Index) in getData(l3.child)" :key="l4Index" v-if="l3.expanded">
              <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(l4, [l1.id, l2.id, l3.id])">
                <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer i-border-1 border-solid border-transparent"
                     :class="{'border-primary-one': l4.id === activeModuleCollection.id}"
                     :style="{'background': getBg(4, l4)}">
                  <div class="flex items-center pr-left-content w-full">
                    <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                      {{ l4.index_code }} {{ l4.title }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ItemList",
  props: ['isolatedIds', 'isolate', 'isolatedItemId', 'isolatedModules'],
  data() {
    return {
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes",
      activeModuleCollection: "build/activeModuleCollection",
    }),
    getLevelsData() {
      return this.activeBrainstormData.collections;
    },
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
  },
  methods: {
    toggleModuleSelection(item) {
      item.expanded = !item.expanded;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getL1Indexing(index, selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        if(settings) {
          let indexing = '';
          let collection = settings.collections[selfLevel];
          if(settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        return currentData + '.' + (index + 1);
      }
      return '';
    },
    getIndexId(selfLevel) {
      if(this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        if(settings) {
          if(settings.index_id) {
            let collection = settings.collections[selfLevel];
            return collection.index_id;
          }
        }
      }
      return '';
    },
    isolation(item, parents = []) {
      item.parents = parents;
      this.$emit("toggleIsolation", item);
    },
    getData(items) {
      let itemIndex = items?.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.activeBrainstormData.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items?.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items?.filter(data => !data.root);
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds?.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
  }
}
</script>

<style scoped>

</style>
