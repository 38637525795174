<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50"
         @click="placeholder.search = ''"
         v-click-outside="hideSearchPlaceholder">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          :placeholder="placeholder.search"
          v-model="searchQuery"
      />
    </div>

    <div class="bg-primary-three shadow-one rounded mt-8 mb-8 relative flex items-center px-4 i-h-50 bld-level-w">
      <h2 class="font-poppins fs-14 fw-600 text-primary-one">{{ selectedTitle }}</h2>
      <div v-click-outside="hideDropdown">
        <div class="absolute bld-global-chevron w-10 i-h-50 cursor-pointer flex justify-center items-center"
             @click="toggleDropdown()">
          <img src="/images/icons/chevron_down.svg" alt="chevron" :class="{'rotate-180': dropdownStat}">
        </div>
        <div
            class="absolute bg-primary-three bld-level-w text-left px-5 py-3 rounded shadow-one bld-global-opt flex flex-col"
            v-if="dropdownStat">
          <div class="flex flex-col">
            <div class="flex justify-between items-center cursor-pointer"
                 @click="opted(optIndex)"
                 v-for="(opt, optIndex) in loadTypeArray"
                 :key="optIndex"
                 :class="{'pt-3': optIndex > 0}">
              <h3 class="fs-14 fw-600 text-primary-one font-poppins">{{ opt }} </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-navigation overflow-y-auto">
      <div class="pl-10 pr-10 pb-4">
        <div class="bg-primary-three bld-global-h bld-level-w rounded shadow-two relative cursor-pointer i-bg-img-fit flex flex-col i-border-1 border-transparent"
             v-for="(item, index) in getFilterData" :key="index"
             :class="{'mt-8': index > 0, 'border-primary-one': selectedBrainstorm === item.id}"
             :level="item.level"
             :id="item.id"
             @click="toggleBrainstormSelection(item.id)">
          <div class="flex justify-between i-pt-15 pb-2 i-ml-15 i-mr-15 i-border-b-1 border-secondary-five">
            <h2 class="font-poppins fs-14 text-primary-one fw-600 ellipses text-left">
              {{ item.title }}</h2>
          </div>
          <span class="font-poppins fs-12 text-primary-one fw-500 ellipses text-left i-ml-15 i-mr-15 mt-2">{{ getBrainstormType(item) }} Brainstorm</span>

          <div class="px-3 pb-4 pt-3 rounded w-full">
            <img src="/images/brainstorm_v2.jpg" alt="demo" class="ws-br-image w-full">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GlobalList",
  created() {
    this.$store.dispatch("brainstormv1/getBrainstorms", {
      project_id: this.$route.params.id
    })
  },
  data() {
    return {
      selectedTitle: 'Workspace Library',
      dropdownStat: false,
      loadType: 0,
      loadTypeArray: ['All Brainstorm', 'Linked Brainstorm', 'Free Brainstorm'],
      placeholder: {
        search: "Search"
      },
      searchQuery: null,
      selectedBrainstorm: parseInt(this.$route.params.brainstorm_id)
    }
  },
  computed: {
    ...mapGetters({
      brainstorms: "brainstormv1/brainstorms"
    }),
    getFilterData() {
      let data = this.brainstorms;
      if(this.loadType === 0) {
        data = this.brainstorms;
      } else if(this.loadType === 1) {
        data = this.brainstorms.filter(item => item.module_id);
      } else {
        data = this.brainstorms.filter(item => !item.module_id);
      }

      const self = this;
      if (self.searchQuery) {
        return data.filter((item) => {
          return self.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => {
                return item.title.toLowerCase().includes(v);
              });
        });
      }

      return data;
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownStat = !this.dropdownStat;
    },
    hideDropdown() {
      this.dropdownStat = false;
    },
    hideSearchPlaceholder() {
      this.placeholder.search = 'Search';
    },
    opted(index) {
      this.loadType = index;
      this.selectedTitle = this.loadTypeArray[index];
      this.hideDropdown();
    },
    toggleBrainstormSelection(id) {
      this.selectedBrainstorm = id;
      if(id !== parseInt(this.$route.params.brainstorm_id)) {
        this.$Progress.start();
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: id})
            .then(response => {
              if (response) {
                this.$Progress.finish();
              }
            });
        this.$router.push({ name: 'brainstorm.show', params: { id: this.$route.params.id, brainstorm_id: id }});
      }
    },
    getBrainstormType(item) {
      if(item.scenario_id) {
        return 'Scenario Linked';
      } else {
        return item.module_id ? 'Linked' : 'Free';
      }
    }
  }
}
</script>

<style scoped>
.ws-br-image {
  height: 120px;
}
</style>