<template>
  <div>
    <div class="flex relative bg-primary-three shadow-two rounded w-full i-h-50 items-center cursor-pointer" v-if="getModuleTitle" :class="{'i-mt-30': serial === 2}" @click="removeIsolation()">
      <p class="focus:outline-none font-poppins text-primary-one fw-600 i-pl-15">
        {{ getModuleTitle() }}
      </p>
      <div
          v-if="activeBrainstormData && activeBrainstormData.scenario_id"
          class="absolute module-expand w-10 i-h-50 items-center justify-center rounded-br rounded-tr cursor-pointer" @click="toggleModule()">
        <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': expandStat()}" />
      </div>
    </div>

    <div class="mt-8 concept-navigation overflow-y-auto" v-if="expandStat() || !activeBrainstormData.scenario_id">
      <!--level 1-->
      <div class="flex flex-col" v-for="(l1, l1Index) in getData(getLevelsData)" :key="l1Index" :class="{'mt-8': l1Index > 0}">
        <div class="relative flex flex-row shadow-one i-h-50 w-full rounded cursor-pointer"
             @dblclick="isolation(l1)">
          <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-transparent"
               :class="{'border-primary-one' : checkActive(l1)}"
               :style="{'background': getBg(1, l1)}">
            <div class="flex items-center pr-left-content w-full bld-level-w" @click="setActiveData(l1)">
              <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">{{ getIndexId(1) }}{{ getL1Indexing(l1Index, 1) }} {{ l1.title }}</span>
            </div>
            <div
                class="absolute pr-hover-div w-10 i-h-50 items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l1)">
              <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l1.expanded}">
            </div>
          </div>
        </div>

        <!--level 2-->
        <div class="flex flex-col mt-8" v-for="(l2, l2Index) in getData(l1.child)" :key="l2Index" v-if="l1.expanded">
          <div class="relative flex flex-row shadow-one i-h-50 w-full rounded cursor-pointer"
               @dblclick="isolation(l2, [l1.id])">
            <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-transparent"
                 :class="{'border-primary-one' : checkActive(l2)}"
                 :style="{'background': getBg(2, l2)}">
              <div class="flex items-center pr-left-content w-full" @click="setActiveData(l2)">
                <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                  {{ getIndexId(2) }}{{ getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2) }} {{ l2.title }}
                </span>
              </div>
              <div
                  class="absolute pr-hover-div w-10 i-h-50 items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l2)">
                <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l2.expanded}">
              </div>
            </div>
          </div>

          <!--level 3-->
          <div class="flex flex-col mt-8" v-for="(l3, l3Index) in getData(l2.child)" :key="l3Index" v-if="l2.expanded">
            <div class="relative flex flex-row shadow-one i-h-50 w-full rounded cursor-pointer"
                 @dblclick="isolation(l3, [l1.id, l2.id])">
              <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-transparent"
                   :class="{'border-primary-one' : checkActive(l3)}"
                   :style="{'background': getBg(3, l3)}">
                <div class="flex items-center pr-left-content w-full" @click="setActiveData(l3)">
                  <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                    {{ getIndexId(3) }}{{ getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3) }} {{ l3.title }}
                  </span>
                </div>
                <div
                    class="absolute pr-hover-div w-10 i-h-50 items-center justify-center rounded-br rounded-tr" @click="toggleModuleSelection(l3)">
                  <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l3.expanded}">
                </div>
              </div>
            </div>

            <!--level 4-->
            <div class="mt-8" v-for="(l4, l4Index) in getData(l3.child)" :key="l4Index" v-if="l3.expanded">
              <div class="relative flex flex-row shadow-one i-h-50 w-full rounded cursor-pointer"
                   @dblclick="isolation(l4, [l1.id, l2.id, l3.id])">
                <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-transparent"
                     :class="{'border-primary-one' : checkActive(l4)}"
                     :style="{'background': getBg(4, l4)}">
                  <div class="flex items-center pr-left-content w-full" @click="setActiveData(l4)">
                    <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                      {{ getIndexId(4) }}{{ getSubLevelIndexing(l4Index, getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3), 4) }} {{ l4.title }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ItemList",
  props: ['serial', 'isolatedModules'],
  data() {
    return {
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
      activeData: {},
      tempIds: []
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes",
      activeModuleCollection: "build/activeModuleCollection",
      // conceptModule: "concept/conceptModule",
      conceptModule: "concept/canvasFirstModule",
      canvasSecondModule: "concept/canvasSecondModule",
      conceptData: "concept/concept",
    }),
    getLevelsData() {
      if(this.activeBrainstormData) {
        if(this.activeBrainstormData.scenario_id) {
          if(this.serial === 1) {
            if(this.conceptModule) {
              return this.conceptModule.collections;
            }
          } else {
            if(this.canvasSecondModule) {
              return this.canvasSecondModule.collections;
            }
          }
          return [];
        } else {
          return this.activeBrainstormData.collections;
        }
      }
      return [];
    },
    moduleSettings() {
      if(this.activeBrainstormData) {
        if(this.activeBrainstormData.scenario_id) {
          let settings = JSON.parse(this.conceptModule.collection_settings);
          if(settings) {
            return settings;
          }
          return {};
        } else {
          let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
          if(settings) {
            return settings;
          }
          return {};
        }
      }
      return {};
    },
  },
  methods: {
    toggleModule() {
      if(this.serial === 1) {
        this.conceptModule.expanded = !this.conceptModule.expanded;
      } else {
        this.canvasSecondModule.expanded = !this.canvasSecondModule.expanded;
      }
    },
    expandStat() {
      if(this.activeBrainstormData.scenario_id) {
        return this.serial === 1 ? this.conceptModule.expanded : this.canvasSecondModule.expanded;
      } else {
        return this.activeBrainstormData.module.expanded;
      }
    },
    toggleModuleSelection(item) {
      item.expanded = !item.expanded;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id || this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getL1Indexing(index, selfLevel) {
      if(this.activeBrainstormData.module_id || this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        if(settings) {
          let indexing = '';
          let collection = settings.collections[selfLevel];
          if(settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      if(this.activeBrainstormData.module_id || this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        return currentData + '.' + (index + 1);
      }
      return '';
    },
    getIndexId(selfLevel) {
      if(this.activeBrainstormData.module_id || this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        if(settings) {
          if(settings.index_id) {
            let collection = settings.collections[selfLevel];
            return collection.index_id;
          }
        }
      }
      return '';
    },
    checkActive(item) {
      if (Object.keys(this.activeModuleCollection).length > 0 && this.activeModuleCollection.id == item.id) {
        return true
      }
      return false
    },
    setActiveData(item) {
      this.activeData = item;
      this.$store.dispatch("build/getActiveModuleCollection", this.activeData)
    },
    getModuleTitle() {
      if(this.activeBrainstormData) {
        if(this.activeBrainstormData.scenario_id) {
          if(this.serial === 2) {
            return this.canvasSecondModule ? this.canvasSecondModule.title: null;
          }
          return this.conceptModule ? this.conceptModule.title: null;
        } else {
          return this.activeBrainstormData ? this.activeBrainstormData.module.title: null;
        }
      }
      return null;
    },
    getModule() {
      if(this.activeBrainstormData) {
        if(this.activeBrainstormData.scenario_id) {
          if(this.serial === 2) {
            return this.canvasSecondModule ? this.canvasSecondModule: null;
          }
          return this.conceptModule ? this.conceptModule: null;
        } else {
          return this.activeBrainstormData ? this.activeBrainstormData.module: null;
        }
      }
      return null;
    },
    isolation(item, parents = []) {
      item.parents = parents;
      if(this.serial === 1) {
        this.$emit("toggleIsolation", item);
      } else {
        let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === item.module_id);
        if(moduleIndex >= 0) {
          let moduleIsolation = this.isolatedModules[moduleIndex];
          if(moduleIsolation.isolatedItemId !== item.id) {
            this.prepareIsolationData(item);
          }
          this.$store.dispatch("concept/removeTheItemIsolation", moduleIndex);
        } else {
          this.prepareIsolationData(item);
        }
      }
    },
    prepareIsolationData(item) {
      let isolateData = item.parents;
      let data = {
        module_id: item.module_id,
        isolate: true,
        isolatedIds: this.getChildIdForIsolation(item, isolateData),
        isolatedItemId: item.id,
      }
      this.$store.dispatch("concept/setCanvasTwoIsolation", data);
    },
    getChildIdForIsolation(item, isolateData) {
      isolateData.push(item.id);
      item.child.forEach(child => {
        isolateData = this.getChildIdForIsolation(child, isolateData);
      });
      return isolateData;
    },
    getData(items) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let module = this.getModule();
      let moduleInIsolation = this.isolatedModules.find(item => item && module && item.module_id === module.id);
      if(module && moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    removeIsolation() {
      let moduleData = this.getModule();
      this.$emit("removeIsolation", moduleData.id);
    }
  }
}
</script>

<style scoped>
.module-expand {
  right: -8px;
  top: 12px;
}
</style>
