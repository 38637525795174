<template>
  <div class="default-container" v-if="intel">
    <!-- styles -->
    <div class="i-mb-30 bg-primary-three" v-if="intel && intel.id">
      <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer" @click="toggleDropdown('h-style-set')">
        <span class="truncate">{{ intelCanvasStyles[activeStyle] }}</span>
        <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
        <div style="display: none;" id="h-style-set" class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two overflow-hidden">
          <h4 v-for="(item, index) in intelCanvasStyles" :key="index"
              class="py-2 px-5 hover:bg-secondary-four"
              @click="setCanvasStyle(index)">
            {{ item }}
          </h4>
        </div>
      </div>
    </div>

    <div class="flex justify-between" v-if="activeStyle === 2">
      <h2 class="fs-14 text-primary-one fw-bold mb-1">Canvas One</h2>
      <img src="/images/icons/chevron_down_black.svg" alt="icon"
           class="cursor-pointer"
           :class="{'rotate-180': !canvasToggler.first}"
           @click="canvasToggler.first = !canvasToggler.first" />
    </div>

    <div v-if="activeStyle !== 2 || (activeStyle === 2 && canvasToggler.first)">
      <!-- module-->
      <div class="i-mb-30" v-if="activeBrainstormData && (activeBrainstormData.scenario_id || activeBrainstormData.module_id) && (intel && intel.id)">
        <h2 class="text-primary-one i-mb-15">Module</h2>
        <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three" @click="toggleDropdown('h-module-set')">
          <span class="truncate">{{ intelModule && intelModule.title ? intelModule.title : 'Select module' }}</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
          <div style="display: none; max-height: 400px; width: 274px" id="h-module-set"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
            <h4 v-for="(item, index) in getModules" :key="index"
                class="py-2 px-5 hover:bg-secondary-four "
                @click="setModule(item)">
              {{ item.title }}
            </h4>
          </div>
        </div>
      </div>
      <!-- dataset-->
      <div class="i-mb-30" v-if="activeStyle == 1">
        <h2 class="text-primary-one i-mb-15">Dataset</h2>
        <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three" @click="toggleDropdown('h-assessment-set')">
          <span class="truncate">{{ intelAssessment && intelAssessment.title ? intelAssessment.title : 'Select assessment' }}</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
          <div style="display: none; max-height: 400px; width: 274px" id="h-assessment-set"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
            <h4 v-for="(item, index) in assessments" :key="index"
                class="py-2 px-5 hover:bg-secondary-four "
                @click="setAssessment(item)">
              {{ item.title }}
            </h4>
          </div>
        </div>
      </div>

      <!-- parameter selection -->
      <div class="flex-col i-mb-30 font-poppins" v-if="(activeBrainstormData.scenario_id || activeBrainstormData.module_id) && intelModule && intelModule.properties && intel && intel.id && activeStyle != 1">
        <h2 class="text-primary-one i-mb-15 e">Parameters</h2>
        <div v-for="(property, pIndex) in intelModule.properties" class="relative" v-if="intelModule && intelModule.properties && intelModule.properties.length > 0" :key="pIndex">
          <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
               @click="toggleDropdown(`h-prop-set-${property.id}`)">
            <span class="truncate">{{ property.title }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="icon" />
          </div>
          <div style="display: none; max-height: 400px; width: 274px" :id="'h-prop-set-' + property.id"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
            <div v-for="(param, pCIndex) in property.property_collections.filter(param => !param.option || param.option == 1)" :key="pCIndex"
                 class="py-2 px-5 hover:bg-secondary-four flex items-center cursor-pointer"
                 @click="toggleParams(param, pCIndex)">
              <div class="param-checkbox rounded-sm i-border-1 border-secondary-one mr-2" :class="{'bg-primary-one': paramInChart(param)}"></div>
              <p class="fs-14 opacity-75" :class="{'opacity-100 text-primary-one': paramInChart(param)}">{{ param.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col i-mb-30 font-poppins" v-if="(activeBrainstormData.scenario_id || activeBrainstormData.module_id) && intelModule && intelModule.properties && intel && intel.id && activeStyle == 1">
        <h2 class="text-primary-one i-mb-15 e">Parameters</h2>
        <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three" @click="toggleDropdown('h-paramerter-set')">
          <span class="truncate">{{ intelTimelineParamerter && intelTimelineParamerter.title ? intelTimelineParamerter.title : 'Select parameter' }}</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
          <div style="display: none; max-height: 400px; width: 274px" id="h-paramerter-set"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
            <h4 v-for="(item, index) in getParams" :key="index"
                class="py-2 px-5 hover:bg-secondary-four "
                @click="setTimelineParam(item)">
              {{ item.title }}
            </h4>
          </div>
        </div>
      </div>

      <!-- definition -->
      <div class="flex-col i-mb-30" v-if="intel && intel.id">
        <div class="flex justify-between items-center i-mb-15">
          <span>Definitions</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/plus_circle_outline.svg" alt="" @click="addNewDefinition(0)" />
        </div>
        <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm def-item bg-primary-three" v-for="(item, index) in getHrDefinitions" :key="index">
          <input type="text" class="bg-transparent" :value="item" @change="updateDefinition($event, index, 0)">
          <img class="i-ml-30 cursor-pointer invisible rm-icon" src="/images/icons/brainstormV2/close.svg" alt="icon" @click="removeDefinition(index, 0)" />
        </div>
      </div>
    </div>

    <div class="flex justify-between" :class="{'mt-5': activeStyle === 2}" v-if="activeStyle === 2">
      <h2 class="fs-14 text-primary-one fw-bold mb-1">Canvas Two</h2>
      <img src="/images/icons/chevron_down_black.svg" alt="icon"
           class="cursor-pointer"
           :class="{'rotate-180': !canvasToggler.second}"
           @click="canvasToggler.second = !canvasToggler.second" />
    </div>

    <div v-if="canvasToggler.second && activeStyle === 2">
      <!-- module-->
      <div class="i-mb-30" v-if="activeBrainstormData && (activeBrainstormData.scenario_id || activeBrainstormData.module_id) && (intel && intel.id)">
        <h2 class="text-primary-one i-mb-15">Module</h2>
        <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three" @click="toggleDropdown('h-module2-set')">
          <span class="truncate">{{ canvasTwoModule && canvasTwoModule.title ? canvasTwoModule.title : 'Select module' }}</span>
          <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="">
          <div style="display: none; max-height: 400px; width: 274px" id="h-module2-set"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two i-border-1 border-secondary-five overflow-y-auto">
            <h4 v-for="(item, index) in getModules" :key="index"
                class="py-2 px-5 hover:bg-secondary-four "
                @click="setCanvasTwoModule(item)">
              {{ item.title }}
            </h4>
          </div>
        </div>
      </div>

      <!-- parameter selection -->
      <div class="flex-col i-mb-30 font-poppins" v-if="(activeBrainstormData.scenario_id || activeBrainstormData.module_id) && canvasTwoModule && canvasTwoModule.properties && intel && intel.id">
        <h2 class="text-primary-one i-mb-15 e">Parameters</h2>
        <div v-for="(property, pIndex) in canvasTwoModule.properties" class="relative" v-if="canvasTwoModule && canvasTwoModule.properties && canvasTwoModule.properties.length > 0" :key="pIndex">
          <div class="flex justify-between items-center h-10 i-border-1 border-secondary-five i-mb-15 i-p-10 rounded-sm relative cursor-pointer bg-primary-three"
               @click="toggleDropdown(`h-prop2-set-${property.id}`)">
            <span class="truncate">{{ property.title }}</span>
            <img class="i-ml-30 cursor-pointer" src="/images/icons/brainstormV2/chevron_down.svg" alt="icon" />
          </div>
          <div style="display: none; max-height: 400px; width: 274px" :id="'h-prop2-set-' + property.id"
               class="dropdown-box flex-col bg-primary-three absolute w-full rounded shadow-two  i-border-1 border-secondary-five overflow-y-auto">
            <div v-for="(param, pCIndex) in property.property_collections.filter(param => !param.option || param.option == 1)" :key="pCIndex"
                 class="py-2 px-5 hover:bg-secondary-four flex items-center cursor-pointer"
                 @click="toggleCanvasTwoParams(param, pCIndex)">
              <div class="param-checkbox rounded-sm i-border-1 border-secondary-one mr-2" :class="{'bg-primary-one': canvasTwoParamInChart(param)}"></div>
              <p class="fs-14 opacity-75" :class="{'opacity-100 text-primary-one': canvasTwoParamInChart(param)}">{{ param.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DefaultIntel",
  data() {
    return {
      intelCanvasStyles: ['Barchart', 'Timeline', 'Side by side logic'],
      canvasToggler: {
        first: true,
        second: true,
        definition: true
      },
      // intelAssessment: {},
      intelTimelineParamerter: {}
    }
  },
  created() {
    if (this.intelModule && Object.keys(this.intelModule).length > 0) {
      this.$store.dispatch('intel/getAssessments', {scenario_id: this.activeBrainstormData.scenario_id, module_id: this.intelModule.id})
    }
  },
  computed: {
    ...mapGetters({
      activeModuleCollection: "build/activeModuleCollection",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      intelModule: "intel/intelModule",
      intel: "intel/intelItem",
      params: "intel/parameters",
      canvasTwo: "intel/canvasTwo",
      assessments: "intel/assessments",
      intelAssessment: "intel/assessment"
    }),
    activeStyle() {
      return this.intel && this.intel.style ? this.intel.style : 0;
    },
    getModules() {
      if(this.activeBrainstormData.scenario_id) {
        return this.activeBrainstormData.modules;
      } else {
        return this.setSingleModule();
      }
    },
    getHrDefinitions() {
      let data = [];
      if (Object.keys(this.intel).length > 0 && this.intel.definitions) {
        data = this.intel.definitions[0];
      }
      return data;
    },
    canvasTwoModule() {
      if(this.canvasTwo && this.canvasTwo.module && this.canvasTwo.module.id) {
        return this.canvasTwo.module;
      }
      return null;
    },
    getParams() {
      let data = []
      if (this.intelModule && Object.keys(this.intelAssessment).length > 0) {
        let interlParams = this.intelAssessment.property_collections.replace('[', '').replace(']', '').split(',').map(i=>Number(i))
        let prop = this.intelModule.properties.find(el => el.id == this.intelAssessment.property_id)
        if (prop) {
          prop.property_collections.forEach(pc => {
            if (interlParams.includes(pc.id)) {
              data.push(pc)
            }
          });
        }
      }
      return data
    }
  },
  watch: {
    intelModule: function (val) {
      if (val) {
        if (this.intelModule && Object.keys(this.intelModule).length > 0) {
          this.$store.dispatch('intel/getAssessments', {scenario_id: this.activeBrainstormData.scenario_id, module_id: this.intelModule.id})
        }
        this.$store.commit('intel/setAssessment', {})
        this.intelTimelineParamerter = {}
      }
    }
  },
  methods: {
    setSingleModule() {
      let data = [this.activeBrainstormData.module];
      this.$store.dispatch("intel/toggleModule", data);
      return data;
    },
    toggleDropdown(id) {
      let dropdowns = document.getElementsByClassName('dropdown-box')
      dropdowns.forEach(dr => {
        if (dr.id !== id) {
          dr.style.display = 'none'
        }
      });
      let el = document.getElementById(id)
      if (el) {
        let display = el.style.display
        if (display == 'none') {
          el.style.display = 'flex'
        }
        else if(display == 'flex') {
          el.style.display = 'none'
        }
      }
    },
    setCanvasStyle(index) {
      this.intel.style = index;
      let data = {
        style: index,
        id: this.intel.id,
        title: this.intel.title,
        project_id: this.$route.params.id,
        brainstorm_id: this.$route.params.brainstorm_id
      };
      this.$store.dispatch("intel/setIntelStyle", index);
      this.$store.dispatch("intel/updateIntel", data);
    },
    setModule(module) {
      this.$store.dispatch("intel/toggleModule", module);

      if(this.activeBrainstormData.scenario_id) {
        this.intel.scenario_settings.module_id = module.id;
        this.intel.scenario_settings.parameters = [];

        let data = {
          id: this.intel.id,
          title: this.intel.title,
          scenario_settings: this.intel.scenario_settings
        };
        this.$store.dispatch("intel/updateIntel", data)
        this.$store.dispatch('intel/getAssessments', {scenario_id: this.activeBrainstormData.scenario_id, module_id: module.id})
      }
    },
    setAssessment(item) {
      this.intelTimelineParamerter = {}
      // this.$store.dispatch('intel/getAssessmentData', item.id)
      this.$store.commit('intel/setAssessment', item)
    },
    setTimelineParam(item) {
      this.intelTimelineParamerter = item
      this.$Progress.start();
      this.$store.dispatch('intel/getAssessmentData', {assessment_id: this.intelAssessment.id, parameter_id: item.id})
      .then(res => function (res) {
        if (res) {
          this.$Progress.finish();
        }
      })
    },
    toggleParams(item, index) {
      this.$store.dispatch("intel/setParameters", {
        item: item,
        index: index,
        default: false
      });

      let data = null;
      if(this.activeBrainstormData.scenario_id) {
        this.intel.scenario_settings.parameters = this.params;
        data = {
          id: this.intel.id,
          title: this.intel.title,
          scenario_settings: this.intel.scenario_settings
        };
      } else {
        if(this.activeBrainstormData.module_id) {
          data = {
            id: this.intel.id,
            title: this.intel.title,
            parameters: this.params
          };
        }
      }

      this.$store.dispatch("intel/updateIntel", data);
    },
    setCanvasTwoModule(module) {
      this.$store.dispatch("intel/setCanvasTwoModule", module);
    },
    paramInChart(item) {
      let paramIndex = this.params.findIndex(data => parseInt(data) === parseInt(item.id));
      return paramIndex >= 0;
    },
    canvasTwoParamInChart(item) {
      let paramIndex = this.canvasTwo.parameters.findIndex(data => parseInt(data) === parseInt(item.id));
      return paramIndex >= 0;
    },
    toggleCanvasTwoParams(item, index) {
      this.$store.dispatch("intel/setCanvasTwoParameters", item);
    },
    addNewDefinition(defIndex) {
      if (this.intel.definitions) {
        let defArr = this.intel.definitions[defIndex];
        if (defArr.length === 5) {
          alert('You have reached the maximum limit!');
          return false;
        }
        this.intel.definitions[defIndex].push('New Definition');
      }
      else {
        this.intel.definitions = [['New Definition'], ['New Definition']];
      }
      this.$store.dispatch("intel/updateIntel", this.intel);
    },
    updateDefinition(e, index, defIndex) {
      let value = e.target.value;
      this.intel.definitions[defIndex][index] = value;
      this.$store.dispatch("intel/updateIntel", this.intel);
    },
    removeDefinition(index, defIndex) {
      let defArr = this.intel.definitions[defIndex]
      if (defArr.length === 2) {
        alert('You must keep at least two definitions!')
        return false
      }
      defArr.splice(index, 1)
      this.$store.dispatch("intel/updateIntel", this.concept)
    }
  }
}
</script>

<style scoped>
.default-container {
  height: 800px;
  overflow-y: scroll;
}
.dropdown-box {
  top: 40px;
  left: 0;
  z-index: 10;
}
.def-item:hover .rm-icon {
  visibility: visible;
}
.param-checkbox {
  width: 10px;
  height: 10px;
}
</style>
