<template>
  <div class="flex flex-col prop-div-root">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1 text-primary-one font-poppins" v-if="indexTitle">{{ item.index_code }}</h2>
      <h2 class="fs-14 fw-bold w-full ellipse text-primary-one font-poppins" :id="'flip_2_item_' + item.id">{{ item.title }}</h2>
    </div>
    <div class="mt-2 relative">
      <img :src="(item.file) ? item?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full object-cover" style="height:85px">
      <label v-if="canEdit(project.cans)" class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
        <input @input="(e)=>{onChange(e), updateImage(
            {file, id:item.id},
              ()=>item.file = {file_path: previewURL}
            )}"
            hidden type="file" accept="image/x-png,image/jpeg" />

        <button class="pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
            <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
          </svg>
        </button>
      </label>
      <!-- <img :src="item.temp_image ? item.temp_image : getTempImage()" alt="image" class="flipped-image rounded" />
      <img src="/images/icons/property_image_icon.svg"
           alt="icon"
           @click="uploadFile(item)"
           class="absolute property-image-icon cursor-pointer" /> -->
    </div>
    <!-- <input
        type="file"
        ref="file"
        style="display: none"
        accept="image/*"
        @change="addFile($event)"
    /> -->
  </div>
</template>

<script setup>
  import axios from 'axios'
  import { useFile } from '@/composable/useFile.js'
  import usePermission from '@/composable/usePermission.js'
  const { onChange, previewURL, file } = useFile()
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
export default {
  name: "FlippedImageScreen",
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    }
  },
  data() {
    return {
      imageObj: null
    }
  },
  computed: {
    ...mapGetters({
      project: "project/project"
    }),
  },
  methods: {
    getTempImage() {
      return require('../../../../assets/images/flip_image.jpg');
    },

    async updateImage({file, id}, callback) {
      let formData = new FormData()
      formData.append('id', id)
      formData.append('image', file)
      await axios.post('projects/scenarios/collections/image', formData)
      callback()
    },

    uploadFile(item) {
      this.imageObj = item;
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        let fileUrl = URL.createObjectURL(f);
        this.imageObj.temp_image = fileUrl;
      });
    },
  },
}
</script>

<style scoped>
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
</style>
