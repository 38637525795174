<template>
    <div class="custom-editor" :style="{ 'z-index': zindex }">
        <canvas :id="editorId"></canvas>
    </div>
</template>

<script>
import { fabric } from "fabric";
import Shape from "./assets/js/shape";
import Text from "./assets/js/text";
import Arrow from "./assets/js/arrow";
import CropImage from "./assets/js/crop";
import CanvasHistory from "./assets/js/canvasHistory";
export default {
    name: "Canvas",
    props: {
        loadingData: {
            type: Array,
            default: () => [],
        },
        canvasWidth: {
            type: [String, Number],
            required: true,
        },
        canvasHeight: {
            type: [String, Number],
            required: true,
        },
        backgroundColor: {
            type: String,
            default: "rgba(237, 237, 237, .75)",
            required: false,
        },
        opacity: {
            type: Number,
            default: .75,
            required: false,
        },
        backgroundImage: {
            required: false,
        },
        zindex: {
            type: Number,
            default: 1,
            required: false,
        },
        editorId: {
            type: String,
            default: "c",
            required: false,
        },
    },
    watch: {
        canvasWidth: function (val) {
            this.renderCanvas();
        },
        canvasHeight: function () {
            this.renderCanvas();
        },
        backgroundImage: function () {
            this.renderCanvas();
        },
        opacity: function () {
            this.renderCanvas();
        },
        loadingData:function(val) {
            this.canvas.clear();
            this.createObjects();
            this.renderCanvas();
        },
    },
    data() {
        return {
            canvas: null,
            pointerX: null,
            pointerY: null,
            createCircle: false,
            createRect: false,
            createTriangle: false,
            createArrow: false,
            createText: false,
            createTextBox: false,
            circle: null,
            currentActiveMethod: null,
            currentActiveTool: null,
            objects: [],
            width: null,
            height: null,
            params: {},
            color: null,
            strokeWidth: 2,
            fontSize: 20,
            croppedImage: false,
            history: [],
        };
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.editorId);

        // this.createTextbox();
        this.createObjects();
        this.renderCanvas();
    },
    methods: {
        renderCanvas() {
            this.canvas.setDimensions({
                width: this.canvasWidth,
                height: this.canvasHeight,
            });
            this.canvas.backgroundColor = this.backgroundColor;
            this.setBgImage(this.backgroundImage)
            let canvasProperties = {
                width: this.canvas.width,
                height: this.canvas.height,
            };
            let currentCanvas = {
                json: this.canvas.toJSON(),
                canvas: canvasProperties,
            };
            new CanvasHistory(this.canvas, currentCanvas);
        },
        setSelectMode() {
            this.currentActiveTool = "selectMode";
            this.drag();
            this.renderCanvas();
        },
        setBgImage(dataImg) {
            let self = this
            let canvas = self.canvas
            // Create a new instance of the Image class
            var img = new Image();

            // When the image loads, set it as background image
            img.onload = function() {
                var f_img = new fabric.Image(img, {opacity: self.opacity});

                canvas.setBackgroundImage(f_img);

                canvas.renderAll();
            };

            // Set the src of the image with the base64 string
            img.src = dataImg;
        },
        createTextbox() {
            let canvas = this.canvas
            var items;
            var title = "HEADLINE";
            var subtitle =
                "Short note";
                // Double-click event handler
            var objectDblClick = function(obj, handler) {
                return function () {
                    if (obj.clicked) handler(obj);
                    else {
                        obj.clicked = true;
                        setTimeout(function () {
                            obj.clicked = false;
                        }, 500);
                    }
                };
            };
            var ungroup = function(group) {
                items = group._objects;
                let canvas = this.canvas
                group._restoreObjectsState();
                canvas.remove(group);
                canvas.renderAll();
                for (var i = 0; i < items.length; i++) {
                    canvas.add(items[i]);
                }
                // if you have disabled render on addition
                canvas.renderAll();
            };
            var rect = new fabric.Rect({
                width: 279,
                height: 140,
                fill: "#233b45",
                top: 20,
                left: 20,
                selectable: true,
            });
            var titleBox = new fabric.IText(title, {
                width: 239,
                fontSize: 14,
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                fill: "#fff",
                top: 20,
                left: 40,
                originY: -1,
                selectable: true,
            });
            titleBox.on('editing:exited', function () {
                    for (var i = 0; i < items.length; i++) {
                    canvas.remove(items[i]);
                }
                var grp = new fabric.Group(items, {});
                canvas.add(grp);
                grp.on('mousedown', objectDblClick(grp, function (obj) {
                    // console.log(grp);
                    ungroup(grp);
                    canvas.setActiveObject(titleBox);
                    titleBox.enterEditing();
                    titleBox.selectAll();
                }));
            });
            var descBox = new fabric.IText(subtitle, {
                fontFamily: "'Roboto', sans-serif",
                fontSize: 12,
                fontWeight: 300,
                width: 239,
                fill: "#787878",
                top: 60,
                left: 40,
                selectable: true,
            });
            var textBox = new fabric.Group([rect, titleBox, descBox], {
                perPixelTargetFind: true
            });

            this.canvas.add(textBox).setActiveObject(textBox);
            
        },
        editableBOx() {
            let canvas = this.canvas
            // Double-click event handler
            var fabricDblClick = function (obj, handler) {
                return function () {
                    if (obj.clicked) handler(obj);
                    else {
                        obj.clicked = true;
                        setTimeout(function () {
                            obj.clicked = false;
                        }, 500);
                    }
                };
            };

            // ungroup objects in group
            var items;
            var ungroup = function (group) {
                items = group._objects;
                group._restoreObjectsState();
                canvas.remove(group);
                canvas.renderAll();
                for (var i = 0; i < items.length; i++) {
                    canvas.add(items[i]);
                }
                // if you have disabled render on addition
                canvas.renderAll();
            };
            // properties of group 
            var prop = {
                    transparentCorners : false,
                    cornerColor : 'transparent',
                    cornerSize : 8,
                    cornerStrokeColor : 'grey',
                    cornerBorderRadius : '1px',
                    selectable: true,
                }

            // Re-group when text editing finishes
            var dimensionText = new fabric.IText("HEADLINE", {
                fontSize: 14,
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                fill: "#fff",
                top: 20,
                left: 20,
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
            });
            dimensionText.on('editing:exited', function () {
                canvas.setActiveObject(descBox);
                descBox.enterEditing();
            });
            var descBox = new fabric.IText('Short note', {
                fontFamily: "'Roboto', sans-serif",
                fontSize: 12,
                fontWeight: 300,
                fill: "#787878",
                top: 50,
                left: 20,
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
            });
            descBox.on('editing:exited', function () {
                for (var i = 0; i < items.length; i++) {
                    canvas.remove(items[i]);
                }
                var grp = new fabric.Group(items, prop);
                // grp.setControlsVisibility({
                //     mt: false, 
                //     mb: false, 
                //     ml: false, 
                //     mr: false,
                // })
                canvas.add(grp).setActiveObject(grp);
                grp.on('mousedown', fabricDblClick(grp, function (obj) {
                    ungroup(grp);
                    canvas.setActiveObject(dimensionText);
                    dimensionText.enterEditing();
                    canvas.renderAll();
                }));
            });
            function addRuler() {
                var rect = new fabric.Rect({
                    width: 279,
                    height: 140,
                    fill: "#233b45",
                    selectable: false,
                    hasControls: false,
                });
                var dimension_group = new fabric.Group([rect, dimensionText, descBox], prop);
                // dimension_group.setControlsVisibility({
                //     mt: false, 
                //     mb: false, 
                //     ml: false, 
                //     mr: false,
                // })
                canvas.add(dimension_group).setActiveObject(dimension_group);
                dimension_group.on('mousedown', fabricDblClick(dimension_group, function (obj) {
                    ungroup(dimension_group);
                    canvas.setActiveObject(dimensionText);
                    dimensionText.enterEditing();
                    canvas.renderAll();
                }));
            }
            addRuler();
        },
        createObjects() {
            let canvas = this.canvas
            if (this.loadingData && this.loadingData.length > 0) {
                this.loadingData.forEach(element => {
                    let obj = {}
                    obj.transparentCorners = false,
                    obj.cornerColor = 'transparent'
                    obj.cornerSize = 8
                    obj.cornerStrokeColor = 'grey'
                    obj.cornerBorderRadius = '1px'
                    obj.width = element.width
                    obj.height = element.height
                    obj.fill = element.fill
                    obj.top = element.top
                    obj.left = element.left
                    obj.zoomX = element.zoomX
                    obj.zoomY = element.zoomY
                    obj.scaleX = element.scaleX
                    obj.scaleY = element.scaleY
                    obj.angle = element.angle

                    // if(element.type == 'rect') {
                    //     var rect = new fabric.Rect(obj);
                    //     this.canvas.add(rect);
                    // }
                    if(element.type == 'group') {
                        var fabricDblClick = function (obj, handler) {
                            return function () {
                                if (obj.clicked) handler(obj);
                                else {
                                    obj.clicked = true;
                                    setTimeout(function () {
                                        obj.clicked = false;
                                    }, 500);
                                }
                            };
                        };

                        // ungroup objects in group
                        var items;
                        var ungroup = function (group) {
                            items = group._objects;
                            group._restoreObjectsState();
                            canvas.remove(group);
                            canvas.renderAll();
                            for (var i = 0; i < items.length; i++) {
                                canvas.add(items[i]);
                            }
                            // if you have disabled render on addition
                            canvas.renderAll();
                        };
                        // properties of group 
                        var prop = {
                                transparentCorners : false,
                                cornerColor : 'transparent',
                                cornerSize : 8,
                                cornerStrokeColor : 'grey',
                                cornerBorderRadius : '1px',
                                selectable: true,
                            }
                        var rectObj = element.items[0]
                        var titleObj = element.items[1]
                        var descObj = element.items[2]
                        var rect = new fabric.Rect(rectObj);
                        var title = new fabric.IText(titleObj.text, titleObj);
                        title.on('editing:exited', function () {
                            canvas.setActiveObject(desc);
                            desc.enterEditing();
                        });
                        var desc = new fabric.IText(descObj.text, descObj);
                        desc.on('editing:exited', function () {
                            for (var i = 0; i < items.length; i++) {
                                canvas.remove(items[i]);
                            }
                            var grp = new fabric.Group(items, prop);
                            // grp.setControlsVisibility({
                            //     mt: false, 
                            //     mb: false, 
                            //     ml: false, 
                            //     mr: false,
                            // })
                            canvas.add(grp).setActiveObject(grp);
                            grp.on('mousedown', fabricDblClick(grp, function (obj) {
                                ungroup(grp);
                                canvas.setActiveObject(title);
                                title.enterEditing();
                                canvas.renderAll();
                            }));
                        });
                        var grp = new fabric.Group([rect, title, desc], obj)
                        // grp.setControlsVisibility({
                        //     mt: false, 
                        //     mb: false, 
                        //     ml: false, 
                        //     mr: false,
                        // });
                        canvas.add(grp);
                        grp.on('mousedown', fabricDblClick(grp, function (obj) {
                            ungroup(grp);
                            canvas.setActiveObject(title);
                            title.enterEditing();
                            canvas.renderAll();
                        }));
                    }
                    else if(element.type == 'i-text') {
                        obj.fontSize = element.fontSize
                        obj.fontFamily = element.fontFamily
                        var text = new fabric.IText(element.placeholder, obj);
                        this.canvas.add(text);
                    }
                    else if (element.type == 'lineArrow') {
                        let points = [element.x1, element.y1, element.x2, element.y2];
                        obj.stroke = element.stroke
                        obj.strokeWidth = element.strokeWidth
                        obj.heads = element.heads
                        obj.originX = 'center'
                        obj.originY = 'center'
                        var arrow = new fabric.LineArrow(points, obj);
                        this.canvas.add(arrow);
                    }
                    else if (element.type == 'path') {
                        obj.stroke = element.stroke
                        obj.strokeLineCap = element.strokeLineCap
                        obj.strokeLineJoin = element.strokeLineJoin
                        obj.strokeMiterLimit = element.strokeMiterLimit
                        obj.strokeWidth = element.strokeWidth
                        var path = new fabric.Path(element.path, obj);
                        this.canvas.add(path);
                    }
                    
                });
            }
        },
        newText(params) {
            var text = "Add Text";
            var textObj = {
                id: "canvas_text_" + params.id,
                width: params.width,
                height: params.height,
                fontSize: 16,
                fontFamily: "Avenir Next LT Pro",
                fontWeight: 600,
                fill: "#222",
                // originX: -0.07,
                // originY: -1,
                selectable: true}
            
            var textBox = new fabric.Textbox(text, textObj);

            this.canvas.add(textBox);
            this.objects.push(textObj)
        },
        createTransbox() {
            var rect = new fabric.Rect({
                width: this.canvasWidth,
                height: this.canvasHeight,
                fill: "#fff",
                opacity: ".2",
                selectable: false,
                id: "trect",
            });
            this.canvas.add(rect);
        },
        getObjectsById(objectId) {
            let objects = this.canvas.getObjects();
            let findedObject = [];
            objects.map((object) => {
                if (object.id && object.id == objectId) {
                    findedObject.push(object);
                }
            });
            return findedObject;
        },
        getObjects() {
            let objects = this.canvas.getObjects();
            return objects;
        },
        changeColor(colorProperty) {
            this.color = colorProperty;
            this.changeFillColor(colorProperty)
            // this.set(this.currentActiveTool);
        },
        setBackgroundImage(imageUrl) {
            let img = new Image();
            this.toDataUrl(imageUrl, (dataUri) => {
                img.src = dataUri;
                let inst = this;
                img.onload = function () {
                    let image = new fabric.Image(img);
                    image.scaleToWidth(inst.canvasWidth);
                    image.scaleToHeight(inst.canvasHeight);
                    inst.canvas.setBackgroundImage(
                        image,
                        inst.canvas.renderAll.bind(inst.canvas)
                    );
                    let canvasProperties = {
                        width: inst.canvas.width,
                        height: inst.canvas.height,
                    };
                    let currentCanvas = {
                        json: inst.canvas.toJSON(),
                        canvas: canvasProperties,
                    };
                    new CanvasHistory(inst.canvas, currentCanvas);
                    inst.canvas.renderAll();
                };
            });
        },
        toDataUrl(url, callback) {
            let xhr = new XMLHttpRequest();
            xhr.onload = function () {
                let reader = new FileReader();
                reader.onloadend = () => {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        clear() {
            this.canvas.clear();
            this.cancelCroppingImage();
        },
        set(type, params) {
            switch (type) {
                case "text":
                    this.currentActiveTool = type;
                    this.params = {
                        fill: params && params.fill ? params.fill : this.color,
                        fontFamily:
                            params && params.fontFamily
                                ? params.fontFamily
                                : "Arial",
                        fontSize:
                            params && params.fontSize
                                ? params.fontSize
                                : this.fontSize,
                        fontStyle:
                            params && params.fontStyle
                                ? params.fontStyle
                                : this.fontStyle,
                        fontWeight:
                            params && params.fontWeight
                                ? params.fontWeight
                                : this.fontWeight,
                        placeholder:
                            params && params.placeholder
                                ? params.placeholder
                                : "Add Text",
                        id: params && params.id ? params.id : "",
                    };
                    this.addText(this.params);
                    break;
                case "textbox":
                    this.currentActiveTool = 'selectMode';
                    this.editableBOx()
                    this.drag()
                    break;
                case "circle":
                    this.cancelCroppingImage();
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        disableCircleEditing:
                            params && params.disableCircleEditing
                                ? params.disableCircleEditing
                                : false,
                        top: params && params.top ? params.top : 0,
                        left: params && params.left ? params.left : 0,
                        radius: params && params.radius ? params.radius : 20,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        id: params && params.id ? params.id : "",
                    };
                    this.customCircle(type, this.params);
                    break;
                case "rect":
                    this.cancelCroppingImage();
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        angle: params && params.angle ? params.angle : 0,
                        width: params && params.width ? params.width : null,
                        height: params && params.height ? params.height : null,
                        top: params && params.top ? params.top : 0,
                        left: params && params.left ? params.left : 0,
                        opacity: params && params.opacity ? params.opacity : 1,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        borderRadius:
                            params && params.borderRadius
                                ? params.borderRadius
                                : 0,
                        id: params && params.id ? params.id : "",
                    };
                    this.customRect(type, this.params);
                    break;
                case "triangle":
                    this.cancelCroppingImage();
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        angle: params && params.angle ? params.angle : 0,
                        width: params && params.width ? params.width : null,
                        height: params && params.height ? params.height : null,
                        top: params && params.top ? params.top : 0,
                        left: params && params.left ? params.left : 0,
                        opacity: params && params.opacity ? params.opacity : 1,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        borderRadius:
                            params && params.borderRadius
                                ? params.borderRadius
                                : 0,
                        id: params && params.id ? params.id : "",
                    };
                    this.customTriangle(type, this.params);
                    break;
                case "comment":
                    this.cancelCroppingImage();
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        angle: params && params.angle ? params.angle : 0,
                        width: params && params.width ? params.width : null,
                        height: params && params.height ? params.height : null,
                        top: params && params.top ? params.top : 0,
                        left: params && params.left ? params.left : 0,
                        opacity: params && params.opacity ? params.opacity : 1,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        borderRadius:
                            params && params.borderRadius
                                ? params.borderRadius
                                : 0,
                        id: params && params.id ? params.id : "",
                    };
                    this.customRect(type, this.params);
                    break;
                case "line":
                    this.cancelCroppingImage();
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        angle: params && params.angle ? params.angle : 0,
                        width: params && params.width ? params.width : null,
                        height: params && params.height ? params.height : null,
                        top: params && params.top ? params.top : 0,
                        left: params && params.left ? params.left : 0,
                        opacity: params && params.opacity ? params.opacity : 1,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        id: params && params.id ? params.id : "",
                    };
                    this.customRect(type, this.params);
                    break;
                case "selectMode":
                    this.currentActiveTool = type;
                    this.drag();
                    break;

                case "arrow":
                    this.currentActiveTool = type;
                    this.params = {
                        fill:
                            params && params.fill ? params.fill : "transparent",
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeDashArray:
                            params && params.strokeDashArray
                                ? params.strokeDashArray
                                : false,
                        id: params && params.id ? params.id : "",
                    };
                    this.drawArrow(this.params);
                    break;
                case "freeDrawing":
                    this.currentActiveTool = type;
                    this.params = {
                        stroke:
                            params && params.stroke
                                ? params.stroke
                                : this.color,
                        strokeWidth:
                            params && params.strokeWidth
                                ? params.strokeWidth
                                : this.strokeWidth,
                        drawingMode:
                            params && params.drawingMode
                                ? params.drawingMode
                                : true,
                        id: params && params.id ? params.id : "",
                    };
                    this.drawing(this.params);
                    break;
                case "crop":
                    this.currentActiveTool = type;
                    this.params = {
                        width: params && params.width ? params.width : 200,
                        height: params && params.height ? params.height : 200,
                        overlayColor:
                            params && params.overlayColor
                                ? params.overlayColor
                                : "#000",
                        overlayOpacity:
                            params && params.overlayOpacity
                                ? params.overlayOpacity
                                : 0.7,
                        transparentCorner:
                            params && params.transparentCorner
                                ? params.transparentCorner
                                : false,
                        hasRotatingPoint:
                            params && params.hasRotatingPoint
                                ? params.hasRotatingPoint
                                : false,
                        hasControls:
                            params && params.hasControls
                                ? params.hasControls
                                : true,
                        cornerSize:
                            params && params.cornerSize
                                ? params.cornerSize
                                : 10,
                        borderColor:
                            params && params.borderColor
                                ? params.borderColor
                                : "#000",
                        cornerColor:
                            params && params.cornerColor
                                ? params.cornerColor
                                : "#000",
                        cornerStyle:
                            params && params.cornerStyle
                                ? params.cornerStyle
                                : "circle",
                        strokeColor:
                            params && params.strokeColor
                                ? params.strokeColor
                                : "#000",
                        lockUniScaling:
                            params && params.lockUniScaling
                                ? params.lockUniScaling
                                : true,
                        noScaleCache:
                            params && params.noScaleCache
                                ? params.noScaleCache
                                : false,
                        strokeUniform:
                            params && params.strokeUniform
                                ? params.strokeUniform
                                : true,
                    };
                    this.currentActiveMethod = this.cropImage;
                    this.drag();
                    this.croppedImage = true;
                    new CropImage(this.canvas, true, false, false, this.params);
                    break;
                case "eraser": {
                    this.canvas.off("mouse:down");
                    this.currentActiveTool = type;
                    let inst = this;
                    this.canvas.isDrawingMode = false;
                    inst.selectable = true;
                    this.canvas.on("mouse:down", function () {
                        if (inst.canvas.getActiveObject()) {
                            inst.canvas.remove(inst.canvas.getActiveObject());
                            let canvasProperties = {
                                width: inst.canvas.width,
                                height: inst.canvas.height,
                            };
                            let currentCanvas = {
                                json: inst.canvas.toJSON(),
                                canvas: canvasProperties,
                            };
                            new CanvasHistory(inst.canvas, currentCanvas);
                        }
                    });
                    break;
                }
                default:
            }
        },
        saveImage() {
            this.cancelCroppingImage();
            return this.canvas.toDataURL("image/jpeg", 1);
        },
        uploadImage(e) {
            this.cancelCroppingImage();
            let inst = this;
            let reader = new FileReader();
            reader.onload = function (event) {
                let imgObj = new Image();
                imgObj.src = event.target.result;
                imgObj.onload = function () {
                    let image = new fabric.Image(imgObj);
                    if (
                        inst.canvas.width <= image.width ||
                        inst.canvas.height <= image.height
                    ) {
                        let canvasAspect =
                            inst.canvas.width / inst.canvas.height;
                        let imgAspect = image.width / image.height;
                        let top, left, scaleFactor;
                        if (canvasAspect >= imgAspect) {
                            scaleFactor = inst.canvas.height / image.height;
                            top = 0;
                            left =
                                -(
                                    image.width * scaleFactor -
                                    inst.canvas.width
                                ) / 2;
                        } else {
                            scaleFactor = inst.canvas.width / image.width;
                            left = 0;
                            top =
                                -(
                                    image.height * scaleFactor -
                                    inst.canvas.height
                                ) / 2;
                        }
                        inst.canvas.setBackgroundImage(
                            image,
                            inst.canvas.renderAll.bind(inst.canvas),
                            {
                                top: top,
                                left: left,
                                scaleX: scaleFactor,
                                scaleY: scaleFactor,
                            }
                        );
                        let canvasProperties = {
                            width: inst.canvas.width,
                            height: inst.canvas.height,
                        };
                        let currentCanvas = {
                            json: inst.canvas.toJSON(),
                            croppedImage: inst.canvas.toDataURL(),
                            canvas: canvasProperties,
                        };
                        new CanvasHistory(inst.canvas, currentCanvas);
                        inst.canvas.renderAll();
                    } else {
                        let center = inst.canvas.getCenter();
                        inst.canvas.setBackgroundImage(
                            image,
                            inst.canvas.renderAll.bind(inst.canvas),
                            {
                                top: center.top,
                                left: center.left,
                                originX: "center",
                                originY: "center",
                            }
                        );
                        let canvasProperties = {
                            width: inst.canvas.width,
                            height: inst.canvas.height,
                        };
                        let currentCanvas = {
                            json: inst.canvas.toJSON(),
                            croppedImage: inst.canvas.toDataURL(),
                            canvas: canvasProperties,
                        };
                        new CanvasHistory(inst.canvas, currentCanvas);
                        inst.canvas.renderAll();
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        customCircle(type, params) {
            this.createArrow = false;
            new Arrow(this.canvas, false);
            this.currentActiveMethod = this.customCircle;
            this.createRect = false;
            this.canvas.isDrawingMode = false;
            if (!params.disableCircleEditing) {
                this.createCircle = true;
                new Shape(this.canvas, this.createCircle, type, params);
            } else {
                this.drawCircle(params);
            }
        },
        customRect(type, params) {
            this.createArrow = false;
            new Arrow(this.canvas, false);
            this.currentActiveMethod = this.customRect;
            this.canvas.isDrawingMode = false;
            this.createCircle = false;
            if (params.width && params.height) {
                this.drawRect(params);
            } else {
                this.createRect = true;
                new Shape(this.canvas, this.createRect, type, params);
            }
        },
        customTriangle(type, params) {
            this.createArrow = false;
            new Arrow(this.canvas, false);
            this.currentActiveMethod = this.customTriangle;
            this.canvas.isDrawingMode = false;
            this.createCircle = false;
            if (params.width && params.height) {
                this.drawTriangle(params);
            } else {
                this.createTriangle = true;
                new Shape(this.canvas, this.createTriangle, type, params);
            }
        },
        drawArrow(params) {
            this.currentActiveMethod = this.drawArrow;
            this.drag();
            this.createArrow = true;
            new Arrow(this.canvas, this.createArrow, params);
        },
        cancelCroppingImage() {
            this.croppedImage = false;
            new CropImage(this.canvas, false, false, true);
        },
        applyCropping() {
            new CropImage(this.canvas, true, true);
            this.cancelCroppingImage();
        },
        drag() {
            this.currentActiveMethod = this.drag;
            this.canvas.isDrawingMode = false;
            this.canvas.forEachObject((object) => {
                object.selectable = true;
                object.evented = true;
            });
            if (this.createArrow) {
                this.createArrow = false;
                new Arrow(this.canvas, false);
            }
            if (this.createRect || this.createCircle) {
                this.createRect = false;
                this.createCircle = false;
                new Shape(this.canvas, false);
            }
            if (this.createText) {
                this.createText = false;
                new Text(this.canvas, false);
            }
            this.cancelCroppingImage();
        },
        addText(params) {
            this.currentActiveMethod = this.addText;
            this.drag();
            this.createText = true;
            new Text(this.canvas, this.createText, params);
        },
        undo() {
            if (this.canvas.getActiveObject()) {
                this.canvas.discardActiveObject().renderAll();
            }
            this.drag();
            this.history = new CanvasHistory();
            if (this.history.length) {
                this.objects.push(this.history.pop());
                if (this.history[this.history.length - 1]) {
                    if (this.history[this.history.length - 1].canvas) {
                        let lastCanvasProperties = this.history[
                            this.history.length - 1
                        ].canvas;
                        if (
                            lastCanvasProperties.width != this.canvas.width ||
                            lastCanvasProperties.height != this.canvas.height
                        ) {
                            this.canvas.setDimensions({
                                width: lastCanvasProperties.width,
                                height: lastCanvasProperties.height,
                            });
                            JSON.parse(
                                JSON.stringify(
                                    this.history[this.history.length - 1]
                                )
                            );
                            this.canvas.loadFromJSON(
                                this.history[this.history.length - 1].json
                            );
                        } else {
                            // let canvasObjects = this.history[
                            //     this.history.length - 1
                            // ].json.objects;
                            if (this.canvas._objects.length > 0) {
                                this.objects.push(this.canvas._objects.pop());
                            }
                        }
                    }

                    if (
                        this.history[this.history.length - 1].croppedImage &&
                        this.history[this.history.length - 1].imagePosition
                    ) {
                        let inst = this;
                        fabric.Image.fromURL(
                            this.history[this.history.length - 1].croppedImage,
                            function (img) {
                                img.set({
                                    top: -inst.history[inst.history.length - 1]
                                        .imagePosition.top,
                                    left: -inst.history[inst.history.length - 1]
                                        .imagePosition.left,
                                });
                                inst.canvas.setBackgroundImage(
                                    img,
                                    inst.canvas.renderAll.bind(inst.canvas)
                                );
                            }
                        );
                    } else {
                        this.setBackgroundImage(
                            this.history[this.history.length - 1].croppedImage
                        );
                    }
                    this.canvas.renderAll();
                }
            }
        },
        redo() {
            this.drag();
            if (this.objects.length > 0) {
                if (this.objects[this.objects.length - 1]) {
                    if (
                        this.objects[this.objects.length - 1].canvas &&
                        !this.objects[this.objects.length - 1].type
                    ) {
                        let lastCanvasProperties = this.objects[
                            this.objects.length - 1
                        ].canvas;
                        if (
                            lastCanvasProperties.width != this.canvas.width ||
                            lastCanvasProperties.height != this.canvas.height
                        ) {
                            this.canvas.setDimensions({
                                width: lastCanvasProperties.width,
                                height: lastCanvasProperties.height,
                            });
                        }
                        JSON.parse(
                            JSON.stringify(
                                this.objects[this.objects.length - 1]
                            )
                        );
                        this.canvas.loadFromJSON(
                            this.objects[this.objects.length - 1].json
                        );
                    } else if (this.objects[this.objects.length - 1].type) {
                        this.canvas.add(this.objects.pop());
                    }
                    if (
                        this.objects[this.objects.length - 1].imagePosition &&
                        this.objects[this.objects.length - 1].croppedImage
                    ) {
                        // let currentProperties;
                        // currentProperties = this.objects[
                        //     this.objects.length - 1
                        // ].imagePosition;
                        let inst = this;
                        fabric.Image.fromURL(
                            this.objects[this.objects.length - 1].croppedImage,
                            function (img) {
                                inst.canvas.setBackgroundImage(
                                    img,
                                    inst.canvas.renderAll.bind(inst.canvas)
                                );
                            }
                        );
                    }
                }
                new CanvasHistory(false, false, this.objects.pop());
            }
        },
        drawing(params) {
            if (this.canvas.__eventListeners) {
                this.canvas.__eventListeners["object:added"] = null;
            }
            this.currentActiveMethod = this.drawing;
            this.drag();
            this.canvas.isDrawingMode = params.drawingMode;
            this.canvas.freeDrawingBrush.color = params.stroke;
            this.canvas.freeDrawingBrush.width = params.strokeWidth;
            this.canvas.freeDrawingBrush.shadow = new fabric.Shadow({
                blur: 0,
                affectStroke: true,
                color: params.stroke,
                id: params.id ? params.id : "",
            });
            let inst = this;
            this.canvas.on("object:added", function () {
                if (inst.canvas.isDrawingMode) {
                    let canvasProperties = {
                        width: inst.canvas.width,
                        height: inst.canvas.height,
                    };
                    let currentCanvas = {
                        json: inst.canvas.toJSON(),
                        canvas: canvasProperties,
                    };
                    new CanvasHistory(inst.canvas, currentCanvas);
                }
            });
            this.canvas.renderAll();
        },
        drawRect(params) {
            this.drag();
            this.canvas.discardActiveObject();
            if (!this.canvas.getActiveObject()) {
                this.rectangle = new fabric.Rect({
                    width: params.width,
                    height: params.height,
                    strokeWidth: params.strokeWidth,
                    stroke: params.stroke,
                    fill: params.fill,
                    opacity: params.opacity,
                    left: params.left,
                    top: params.top,
                    noScaleCache: params.noScaleCache,
                });
                this.canvas.add(this.rectangle)
            }
        },
        drawTriangle(params) {
            this.drag();
            this.canvas.discardActiveObject();
            if (!this.canvas.getActiveObject()) {
                this.triangle = new fabric.Triangle({
                    width: params.width,
                    height: params.height,
                    strokeWidth: params.strokeWidth,
                    stroke: params.stroke,
                    fill: params.fill,
                    opacity: params.opacity,
                    left: params.left,
                    top: params.top,
                    noScaleCache: params.noScaleCache,
                });
                this.canvas.add(this.triangle);
            }
        },
        drawCircle(params) {
            this.drag();
            this.canvas.discardActiveObject();
            this.circle = new fabric.Circle({
                left: params.left,
                top: params.top,
                radius: params.radius,
                strokeWidth: params.strokeWidth,
                stroke: params.stroke,
                fill: params.fill,
                borderColor: "yellow",
                noScaleCache: params.noScaleCache,
            });
            this.canvas.add(this.circle);

            this.canvas.renderAll();
        },
        getCanvasZoom() {
            this.canvas.on("mouse:wheel", function (opt) {
                var delta = opt.e.deltaY;
                var pointer = this.getPointer(opt.e);
                var zoom = this.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 5) zoom = 5;
                if (zoom < 0.3) zoom = 0.3;
                this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
            });
            this.canvas.on("mouse:down", function (opt) {
                // console.log(opt.e);
                var evt = opt.e;
                if (evt.altKey === true) {
                    this.isDragging = true;
                    this.selection = false;
                    this.lastPosX = evt.clientX;
                    this.lastPosY = evt.clientY;
                }
            });
            this.canvas.on("mouse:move", function (opt) {
                if (this.isDragging) {
                    var e = opt.e;
                    var vpt = this.viewportTransform;
                    vpt[4] += e.clientX - this.lastPosX;
                    vpt[5] += e.clientY - this.lastPosY;
                    this.requestRenderAll();
                    this.lastPosX = e.clientX;
                    this.lastPosY = e.clientY;
                }
            });
            this.canvas.on("mouse:up", function (opt) {
                // on mouse up we want to recalculate new interaction
                // for all objects, so we call setViewportTransform
                this.setViewportTransform(this.viewportTransform);
                this.isDragging = false;
                this.selection = true;
            });

            let inst = this;
            document.onkeydown = (e) => {
                // console.log(e);
                if (e.which === 46 || e.keycode === 46) {
                    inst.canvas.getActiveObjects().forEach((obj) => {
                        inst.canvas.remove(obj)
                    });
                }
                inst.canvas.renderAll()
            };
        },
        changeFillColor(value) {
            
            let activeObject = this.canvas.getActiveObject()
            
            if (activeObject) {
                let type = activeObject.get('type');
                if (type == 'rect' || type == 'ellipse' || type == 'triangle' || type == 'i-text') {
                    activeObject.set({fill: value})
                }
                else if (type == 'path'  || type == 'lineArrow') {
                    activeObject.set({stroke: value})
                }
                else if (type == 'group') {
                    activeObject._objects[0].set({fill: value})
                }
            }
            this.canvas.renderAll()
        },
        changeStrokeColor(value) {
            let activeObject = this.canvas.getActiveObject()
            if (activeObject) {
                let type = activeObject.get('type');
                if (type == 'rect' || type == 'ellipse' || type == 'triangle') {
                    activeObject.set({stroke: value})
                }
            }
            this.canvas.renderAll()
        },
        changeStrokeWidth(value) {
            let activeObject = this.canvas.getActiveObject()
            if (activeObject) {
                let type = activeObject.get('type');
                // if (type == 'rect' || type == 'ellipse') {
                    activeObject.set({strokeWidth: value})
                // }
            }
            this.canvas.renderAll()
        },
        cloneActive() {
            let canvas = this.canvas
            let activeObject = canvas.getActiveObject()

            if (activeObject) {
                activeObject.clone(function(clone) {
                    canvas.add(clone.set({
                        left: activeObject.left + activeObject.width + 10,
                        fill: activeObject.fill,
                        stroke: activeObject.stroke,
                        strokeWidth: activeObject.strokeWidth,
                        cornerColor: 'grey',
                        cornerSize: 8,
                    }))
                })
            }
            canvas.renderAll()
        }
    },
};
</script>
<style>
.upper-canvas {
    z-index: 1;
}
</style>
