<template>
  <div class="flip-card-front">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1" v-if="indexTitle">{{ item.index_code }}</h2>
      <input type="text"
             class="bg-transparent fs-14 fw-bold w-full ellipse"
             v-model="item.title"
             :id="'item_input_' + item.id"
             @keyup="changedDataFromCollaboration('item_input_', item, item.title)"
             v-on:keyup.delete="removeItem($event, item)"
             @change="regularUpdate($event, item)">
    </div>
    <textarea class="bg-transparent fs-12 w-full content-desc-height bld-content-area"
              v-model="item.description"
              placeholder="Add descriptive text"
              :id="'item_text_' + item.id"
              @keyup="changedDataFromCollaboration('item_text_', item, item.description)"
              @change="regularUpdate($event, item)"></textarea>
    <!-- expand feature -->
    <div class="absolute expanded-div rounded z-20 shadow-one bg-primary-three" v-show="expandedArray.find(el => el.id === item.id)">
      <div class="px-4 py-3 flex justify-start text-secondary-one relative">
        <text-feature v-if="getExpandStatus(item) === 'text'"
                      :item="item"
                      :index-title="indexTitle"
                      @changedDataFromCollaboration="changedDataFromCollaboration"
                      @regularUpdate="regularUpdate" />
        <chart-feature :item="item"
                       :index-title="indexTitle"
                       :module-properties="moduleProperties"
                       v-else-if="getExpandStatus(item) === 'chart'"
                       @changedDataFromCollaboration="changedDataFromCollaboration"
                       @regularUpdate="regularUpdate" />
      </div>
      <div class="absolute expanded-icon" @click="collpaseItem(item)">
        <img src="/images/icons/brainstormV2/cross.svg"
             alt="icon"
             class="cursor-pointer" />
      </div>
    </div>
    <!-- expand feature  -->
  </div>
</template>

<script>
import TextFeature from "@/components/brainstormv2/canvas/Module/Horizontal/TextFeature";
import ChartFeature from "@/components/brainstormv2/canvas/Module/Horizontal/ChartFeature";
export default {
  name: "NotFlippedScreen",
  components: {ChartFeature, TextFeature},
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    },
    expandedArray: {
      type: Array
    },
    moduleProperties: {
      type: Array
    },
  },
  methods: {
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id === item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id === item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    },
    collpaseItem(item) {
      this.$emit("collpaseItem", item);
    },
    removeItem(e, item) {
      this.$emit("removeItem", e, item)
    }
  }
}
</script>

<style scoped>
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
.flip-card {
  /* width: 100%; */
  /* perspective: 1000px; */
}
.flip-card-inner {
  transition: transform 0.6s;
  /* transform-style: preserve-3d; */
}
.flip-card-toggle .flip-card-inner {
  /* transform: rotateY(180deg); */
}
.flip-card-front, .flip-card-back {
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  /* -webkit-backface-visibility: hidden; */
  /* backface-visibility: hidden; */
}
.flip-card-back {
  /* color: white; */
  /* transform: rotateY(180deg); */
  background: #FFFFFF;
}
.prop-div-root {
  height: 116px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 10px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
