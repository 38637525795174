<template>
    <div class="property-body fs-10 text-secondary-one">
        <div class="p-0 bg-transparent relative">
            <!-- <div v-for="(dataset, index) in properties.filter(el => el.is_visible && el.id == selectedPropSet)" :key="index"> -->
                <!-- <div class="" :id="'value-cont-'+activeModuleCollection.id+'-'+index"> -->
                  <div class="flex justify-start items-center mb-3" v-for="(prop, pindex) in properties" :key="pindex" @mouseover="showValue(pindex)" @mouseleave="hideValue(pindex)">
                      <p class="prop-items i-mr-15">{{ prop.title }}</p>
                      <property-sample v-if="!prop.option || prop.option == 1"
                                       :property="prop"
                                       index="1"
                                       :p-index="pindex"
                                       :item="activeModuleCollection"
                                       @updateParameterData="updateData" />
                  </div>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PropertySample from "@/components/properties/PropertySample";
    export default {
      name: "ItemProperty",
      components: {PropertySample},
      props: ['selectedPropSet', 'activeModuleCollection', 'properties'],
        data() {
          return {
            // active : this.properties[0]
          }
        },
        computed: {
          ...mapGetters({
              // properties: "build/modulePropertySet",
              // activeModuleCollection: "programmatic/activeModuleCollection",
              activeModule: "build/navigatorModule",
              activeBrainstormData: "brainstormv1/activeBrainstormData"
          })
        },
        methods: {
          showValue(pindex) {
            let value = document.getElementById('value-hover-'+this.activeModuleCollection.id+'-'+pindex)
            value.style.display = 'block'
          },
          hideValue(pindex) {
            let value = document.getElementById('value-hover-'+this.activeModuleCollection.id+'-'+pindex)
            value.style.display = 'none'
          },
          updateData(obj) {
            let updateItem = this.activeModuleCollection.properties.find(p => p.property_collection_id == obj.id);
            if(updateItem) {
              updateItem.sample_value = parseFloat(updateItem.sample_value)
            }
            this.$store.dispatch("brainstormv1/updatePropertyItemFromBuild", updateItem)
            .then(response => {
                if (response) {
                  this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.activeBrainstormData.id})
                }
            })
            this.$emit("changedDataFromCollaboration", {
              load: true
            });
          },
        },
    }
</script>

<style scoped>
.property-body {
  padding-top: 10px;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}
.prop-items {
  width: 150px;
}
.assoc-items {
  width: 250px;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
