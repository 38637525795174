<template>
  <div class="bar-chart relative">
    <div class="absolute chart-content-div content-z" :class="{'chart-content-side-by-side': activeStyle === 2}">
      <div class="flex chart-content-div i-border-l-1 i-border-t-1"
            :class="{'chart-content-side-by-side': activeStyle === 2}"
            :style="{'border-color': intelLineColor}">
        <div class="flex chart-content-div i-border-r-1"
             :class="{'chart-content-side-by-side': activeStyle === 2}"
             v-for="(item, index) in chartData" :key="index"
             :style="{'width': eachItemWidth(index) + 'px', 'border-color': intelLineColor}">
          <div class="chart-content-div-height relative flex items-end justify-between cursor-default"
               v-for="(parameter, pIndex) in item.parameters"
               :key="pIndex"
               @mouseover="setMouseOverPoint($event, item, index)"
               @mouseleave="setMouseLeavePoint()"
               :style="{'margin-left': pIndex === 0 ? getMarginWidth(index) / 2 + 'px' : getInnerContentGap(index) + 'px'}">
            <div class="content-from-bottom shadow-two root-param relative bar-chart-animation"
                 :style="{'background': parameter.bg, 'width': getParamWidth(index) + 'px', 'height': getHeight(parameter.sample_value) + 'px'}"
                 @click="loadSubLevelData(index)"></div>
            <div class="absolute rounded content-from-bottom overlay-param opacity-50" v-if="item.overlay_parameters && item.overlay_parameters.length > 0"
                 :style="{'background': getOverlayParameter(item, parameter) ? getOverlayParameter(item, parameter).o_bg : '',
                 'width': getParamWidth(index) + 'px',
                 'height': getOverlayParameter(item, parameter) ? getHeight(getOverlayParameter(item, parameter).sample_value) + 'px' : ''}"
                 @click="loadSubLevelData(index)"></div>
          </div>
          <div class="absolute item-title">
            <h4 class="text-secondary-one fs-12 text-center ellipse" :style="{'width': eachItemWidth(index) + 'px'}" :title="item.index_code + ' ' + item.title">
              {{ item.index_code }} <br>
              {{ item.title }}
            </h4>
          </div>

          <div class="fixed tooltip-box bg-primary-three shadow-two rounded p-2" v-if="hoveredItem && hoveredItem.id === item.id && hoveredItem.level === item.level"
               :style="{'left': getLeftPos() + 'px', 'top': coordinate.y + 'px'}">
            <h4 class="text-primary-one fs-14 fw-600">{{ hoveredItem.index_code + ' ' + hoveredItem.title }}</h4>
            <div v-for="(param, hPIndex) in hoveredItem.parameters" :key="hPIndex">
              <div class="flex items-center mt-2">
                <div class="hover-item-box rounded-sm" :style="{'background': param.bg}"></div>
                <h4 class="fs-12 text-primary-one ml-2">{{ param.parameter_title }}</h4>
                <h4 class="fs-12 text-primary-one ml-2 fw-bold">{{ param.sample_value ? param.sample_value.toFixed(2) : 0.0 }}</h4>
              </div>
              <div v-if="hoveredItem.overlay_parameters && hoveredItem.overlay_parameters.length > 0 && hoveredItem.overlay_parameters[hPIndex]" class="flex items-center mt-2">
                <div class="hover-item-box rounded-sm opacity-50" :style="{'background': hoveredItem.overlay_parameters[hPIndex].o_bg}"></div>
                <h4 class="fs-12 text-primary-one ml-2">{{ hoveredItem.overlay_parameters[hPIndex].parameter_title }}</h4>
                <h4 class="fs-12 text-primary-one ml-2 fw-bold">{{ hoveredItem.overlay_parameters[hPIndex].sample_value ? hoveredItem.overlay_parameters[hPIndex].sample_value.toFixed(2) : 0.0 }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="ticks" :style="{'background': intelChartColor}" :class="{'chart-content-side-by-side': activeStyle === 2}">
      <div class="tick tick-percentage fs-14 i-border-b-1" :class="{'chart-content-side-by-side': activeStyle === 2}" :style="{'border-color': intelLineColor}"><p>10</p></div>
      <div class="tick tick-percentage fs-14 i-border-b-1" :class="{'chart-content-side-by-side': activeStyle === 2}" :style="{'border-color': intelLineColor}"><p>8</p></div>
      <div class="tick tick-percentage fs-14 i-border-b-1" :class="{'chart-content-side-by-side': activeStyle === 2}" :style="{'border-color': intelLineColor}"><p>6</p></div>
      <div class="tick tick-percentage fs-14 i-border-b-1" :class="{'chart-content-side-by-side': activeStyle === 2}" :style="{'border-color': intelLineColor}"><p>4</p></div>
      <div class="tick tick-percentage fs-14 i-border-b-1" :class="{'chart-content-side-by-side': activeStyle === 2}" :style="{'border-color': intelLineColor}"><p>2</p></div>
      <div class="tick fs-14"><p>0</p></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomBarChart",
  props: ['parameters', 'chartData', 'hoveredItem', 'parametersSelectedWithOverlay', 'intelChartColor', 'intelLineColor'],
  data() {
    return {
      margin: 12,
      innerContentGap: 10,
      firstGroupPercent: 30,
      coordinate: {
        x: 0,
        y: 0,
        inLeft: false
      }
    }
  },
  computed: {
    ...mapGetters({
      intelItem: "intel/intelItem",
    }),
    activeStyle() {
      return this.intelItem && this.intelItem.style ? this.intelItem.style : 0;
    },
  },
  methods: {
    eachItemWidth(index) {
      let contentWidth = this.activeStyle === 2 ? 680 : 1385;
      if(index === 0) {
        let groupWidth = contentWidth / this.chartData.length;
        groupWidth += groupWidth * this.firstGroupPercent / 100;
        return groupWidth;
      } else {
        let firstGroupWidth = contentWidth / this.chartData.length;
        firstGroupWidth += firstGroupWidth * this.firstGroupPercent / 100;
        contentWidth = contentWidth - firstGroupWidth;
        return contentWidth / (this.chartData.length - 1);
      }
    },
    getMarginWidth(index) {
      let fullWidth = this.eachItemWidth(index);
      return (fullWidth * (this.margin * 2)) / 100;
    },
    getInnerContentGap(index) {
      let fullWidth = this.eachItemWidth(index);
      let afterMargin = this.getMarginWidth(index);
      let afterInnerContent = ((fullWidth - afterMargin) * (this.innerContentGap * (this.parameters.length - 1))) / 100;
      return afterInnerContent / this.parameters.length;
    },
    getParamWidth(index) {
      let fullWidth = this.eachItemWidth(index);
      let afterMargin = this.getMarginWidth(index);
      let afterInnerContent = ((fullWidth - afterMargin) * (this.innerContentGap * (this.parameters.length - 1))) / 100;

      return (fullWidth - (afterMargin + afterInnerContent)) / this.parameters.length;
    },
    getHeight(value) {
      let height = 740;
      let barSlice = height / 10;
      return barSlice * value;
    },
    getOverlayParameter(item, parameter) {
      return item.overlay_parameters.find(data => parseInt(data.ref_param) === parseInt(parameter.property_collection_id));
    },
    setMouseOverPoint(event, item, index) {
      this.$emit("setMouseOverPoint", item);
      this.coordinate = {
        x: event.clientX + 10,
        y: event.clientY,
        inLeft: this.chartData.length / 2 > (index + 1)
      };
    },
    setMouseLeavePoint() {
      this.coordinate = {
        x: 0,
        y: 0,
        inLeft: false
      };
      this.$emit("setMouseOverPoint", null);
    },
    getLeftPos() {
      // if(this.coordinate.inLeft) {
      //   return this.coordinate.x - this.getParamWidth();
      // }
      return this.coordinate.x;
    },
    loadSubLevelData(index) {
      this.$emit("loadSubLevelData", index);
    }
  }
}
</script>

<style scoped>
#ticks {
  position: relative;
  /*top: -740px;*/
  top: 30px;
  left: 2px;
  /*width: 1420px;*/
  width: 1385px;
  height: 742px;
  z-index: 1;
  /*margin-bottom: -300px;*/
  font-size: 10px;
  margin-left: 12px;
  border-top: 1px solid #E2E2E2;
  border-right: 1px solid #E2E2E2;
  border-left: 1px solid #E2E2E2;
}

#ticks .tick {
  position: relative;
  /*width: 1420px;*/
  width: 1385px;
}

#ticks .tick p {
  position: absolute;
  left: -2em;
  top: -0.8em;
  margin: 0 0 0 0.5em;
}
.tick-percentage {
  height: 20%;
}
.content-z {
  z-index: 999;
}
.chart-content-div {
  left: 14px;
  /*width: 1420px;*/
  width: 1385px;
  top: 30px;
  height: 740px;
}
.chart-content-div-height {
  height: 740px;
}
.content-from-bottom {
  bottom: 0;
  border-radius: 4px 4px 0 0;
}
.item-title {
  bottom: -50px;
}
.root-param {
  z-index: 1;
}
.overlay-param {
  z-index: 2;
}
.tooltip-box {
  z-index: 10;
}
.hover-item-box {
  width: 15px;
  height: 15px;
}
.chart-content-side-by-side {
  width: 680px !important;
}
.bar-chart-animation {
  animation-name: bar;
  animation-duration: 1s;
}
/*@keyframes bar {*/
/*  0%   { height: 0%; }*/
/*  50%  { height: 50%; }*/
/*  100% { height: 100%; }*/
/*}*/
</style>
