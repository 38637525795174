<template>
    <div style="z-index: 99999" @click="setActiveSticky(data)" @dragstart="onDragStart" @drag="onDrag" @dragend="onDrgEnd" :id="'sticky-' + id"
        class="panzoom-exclude relative cursor-move sticky_item rounded border border-white mb-8 font-poppins p-4"
        :class="[custom_class, (data.coordination && data.coordination.x) != null ? '_absolute' : '']" :data-id="data.id" draggable="true"
        ref="draggable_note" :style="{
            backgroundColor: color,
            left: getCoordination('x'),
            top: getCoordination('y'),
        }">
        <svg v-if="canDelete(project.cans)" @click="$emit('deleteNote', data.id)" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="close_icon cursor-pointer absolute right-2 top-2 opacity-0"
            style="transform: scale(0.7)">
            <path data-v-22e31d76=""
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="#3D3D3D"></path>
        </svg>
        <input
            @input="updateNote()"
            class="fs-14 text-primary-one fw-700 bg-transparent w-full block"
            spellcheck="false"
            placeholder="Title..."
            :disabled="!canEdit(project.cans)"
            v-model="data.title" />
        <textarea
            @input="updateNote()"
            class="fs-12 text-secondary-six fw-400 block w-full bg-transparent"
            rows="6"
            spellcheck="false"
            placeholder="Description..."
            :disabled="!canEdit(project.cans)"
            v-model="data.description"></textarea>
    </div>
</template>


<script>
import usePermission from '@/composable/usePermission.js'
import { useSticky } from "./stickyController";
import { mapGetters, mapActions } from "vuex";
import store from '@/store'
export default {
    setup() {
        const { updateSticky } = useSticky();
        const { canEdit, canCreate, canDelete } = usePermission()
        return {
            updateSticky,
            canEdit,
            canCreate,
            canDelete
        };
    },

    data() {
        return {
            draggable_note: null,
            mousePrevPos: { x: null, y: null },
            mousePos: { x: null, y: null },
            elementPos: { x: 0, y: 0 },
            timeoutId: null,
            time: 1000, //ms
        };
    },

    props: {
        index: {
            type: Number,
        },
        id: {
            type: Number,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        color: {
            type: String,
        },
        draggable: {
            type: Boolean,
        },
        coordination: {
            type: Object,
            default: () => {
                return { x: 0, y: 0 };
            },
        },
        custom_class: {
            type: String,
        },
        data: Object,
    },

    computed: {
        ...mapGetters({
            project: "project/project"
        }),
    },

    methods: {
        setActiveSticky(data) {
            store.commit('stickyNote/setActiveStickyNote', data)
        },

        onDragStart(e) {
            if(e.altKey) {
                e.preventDefault()
                e.returnValue = false
                return
            }

            e.dataTransfer.setData("data-id", this.id);
            e.dataTransfer.setData("data-type", 'sticky');

            let dropZon = document.querySelector(".main_dropzone");

            if (dropZon) {
                if (e.target.closest('.sticky_wrapper'))
                {
                    this.mousePrevPos.x = parseInt(dropZon.getBoundingClientRect().left) + parseInt(e.x - e.target.getBoundingClientRect().left) - dropZon.scrollLeft;
                    this.mousePrevPos.y = e.y-(e.target.offsetTop-dropZon.offsetTop)+e.target.parentElement.scrollTop-dropZon.scrollTop;
                } else {
                    this.mousePrevPos.x = e.x;
                    this.mousePrevPos.y = e.y;
                }
            }
        },

        onDrag(e) {
            e.target.style.visibility = "hidden";
        },

        onDrgEnd(e) {
            e.target.style.visibility = "visible";
            let topElem = document.elementFromPoint(e.clientX, e.clientY);
            let dropZon = topElem.closest(".main_dropzone");
            let stickyDropZone = topElem.closest(".sticky-drop-zone");

            if (dropZon) {
                this.elementPos.x = +e.target.style.left.replaceAll(/px/gi, "");
                this.elementPos.y = +e.target.style.top.replaceAll(/px/gi, "");

                this.mousePos.x = +e.x;
                this.mousePos.y = +e.y;

                let mouseDistance =  {
                    x: e.x - this.mousePrevPos.x,
                    y: e.y - this.mousePrevPos.y
                }

                if(!this.data.coordination) {
                    this.data.coordination = {}
                }

                this.data.coordination.y = +mouseDistance.y + this.elementPos.y //+ window.pageYOffset - (e.target.offsetHeight + 36)
                this.data.coordination.x = +mouseDistance.x + this.elementPos.x //+ dropZon.scrollLeft
            }

            if (stickyDropZone) {
                this.data.coordination.x = null;
                this.data.coordination.y = null;
            }

            this.updateNote();
        },

        updateNote() {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.updateSticky(this.id, this.data);
            }, this.time);
        },

        getCoordination(coordinate) {
            if(this.data.coordination) {
                return this.data.coordination[coordinate] + 'px'
            }
            return null
        }
    },

};
</script>

<style>
.sticky_item {
    transition: 0s;
    width: 275px;
    transition: unset !important;
}
.sticky_item:focus-within{
    border-color: #000;
}

.sticky_item:hover .close_icon {
    opacity: 1;
}

._absolute {
    position: absolute !important;
}
</style>
