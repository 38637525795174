<template>
  <div class="absolute level-boxes">
    <div class="flex" >
      <div
        class="level-box rounded-sm cursor-pointer demo-c-1 shadow-two"
        :class="!canCreate(project.cans) && 'pointer-events-none'"
        @click="newRootChild(1)"></div>
      <div
        class="level-box rounded-sm cursor-pointer i-ml-10 demo-c-2 shadow-two"
        :class="!canCreate(project.cans) && 'pointer-events-none'"
        @click="newSubChild(l1, 2)"></div>
      <div class="level-box rounded-sm cursor-pointer i-ml-10 demo-c-3 shadow-two"
        :class="{'cursor-not-allowed pointer-events-none opacity-75': current.level < 2}"
        @click="newSubChild(l2, 3)"
        :title="current.level < 2 ? 'Can\'t create level 3 data without level 2' : ''"></div>
      <div class="level-box rounded-sm cursor-pointer i-ml-10 demo-c-4 shadow-two"
        :class="{'pointer-events-none cursor-not-allowed opacity-75': current.level < 3}"
        @click="newSubChild(l3, 4)"
        :title="current.level < 3 ? 'Can\'t create level 4 data without level 3' : ''"></div>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChildCreateBox",
  props: ['l1', 'l2', 'l3', 'current', 'module'],
  data() {
    return {
      freeTitles: [
        ['Identification', 'Identifier', 'Area', 'Area Measure', "Measure"],
        ['Capability', 'Capability Group', 'Area', 'Area Measure', "Measure"],
        ['Enabler', 'Enabler Group', 'Area', 'Area Measure', "Measure"],
        ['Design', 'Design Group', 'Area', 'Area Measure', "Measure"],
        ['Programme', 'Programme Group', 'Area', 'Area Measure', "Measure"],
        ['Plan', 'Plan Group', 'Area', 'Area Measure', "Measure"],
        ['Solution', 'Solution Group', 'Area', 'Area Measure', "Measure"],
        ['Effect', 'Effect Group', 'Area', 'Area Measure', "Measure"],
        ['Outcome', 'Outcome Group', 'Area', 'Area Measure', "Measure"],
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      project: "project/project"
    }),
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings.collections;
        }
        return [];
      }
      return [];
    },
  },
  methods: {
    newRootChild(level) {
      if(level >= this.current.level) {
        this.$emit("newRootChild");
      } else {
        let data = {
          id: this.current.id,
          level: level,
          parent_id: null,
        };

        if(this.activeBrainstormData.scenario_id) {
          data.order = this.module.collections[this.module.collections.length - 1].order + 1
        } else {
          data.order = this.activeBrainstormData.collections[this.activeBrainstormData.collections.length - 1].order + 1
        }

        this.$emit("updateParent", data);
      }
    },
    newSubChild(item, level) {
      if(level >= this.current.level) {
        let data = {
          parent_id: item.id,
          brainstorm_id: this.$route.params.brainstorm_id,
          level: level,
          title: this.moduleSettings.length > 0 ? this.moduleSettings[level].title : this.freeTitles[0][level],
          order: item.child.length > 0 ? item.child[item.child.length - 1].order + 1 : 1
        }
        this.$emit("newSubChild", data);
      } else {
        let data = {
          id: this.current.id,
          level: level,
          parent_id: this.getParentId(level),
          order: this.getOrder(level)
        };
        this.$emit("updateParent", data);
      }
    },
    getParentId(level) {
      if(level === 1) {
        return null;
      } else if(level === 2) {
        return this.l1.id;
      } else if(level === 3) {
        return this.l2.id;
      }
    },
    getOrder(level) {
      if(level === 1) {
        if(this.activeBrainstormData.scenario_id) {
          return this.module.collections[this.module.collections.length - 1].order + 1
        } else {
          return this.activeBrainstormData.collections[this.activeBrainstormData.collections.length - 1].order + 1
        }

      } else if(level === 2) {
        return this.l1.child[this.l1.child.length - 1].order + 1;
      } else if(level === 3) {
        return this.l2.child[this.l2.child.length - 1].order + 1;
      }
    }
  }
}
</script>

<style scoped>
.level-boxes {
  left: 0;
  bottom: -22px;
}
.level-box {
  width: 25px;
  height: 12px;
}
.demo-c-1 {
  background: #2B80AD;
}
.demo-c-2 {
  background: #3D6A83;
}
.demo-c-3 {
  background: #6A7881;
}
.demo-c-4 {
  background: #818487;
}
</style>
