<template>
  <v-chart :options="chartOptions"></v-chart>
</template>

<script>
import ECharts from "vue-echarts";
import {mapGetters} from "vuex";

export default {
  name: "ELineChart",
  components: { "v-chart": ECharts },
  props: ['width', 'height', 'chartData'],
  created: {
    //
  },
  watch: {
    chartData: function(val) {
      this.getOption()
    }
  },
  data() {
    return {
      chartOptions: null
    }
  },
  methods: {
    generateData() {
      function randomData() {
        let obj = {}
        now = new Date(+now + oneDay);
        value = Math.random() * 10;
        obj =  {
          name: now.toString(),
          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
            Math.round(value)
          ]
        };
        return obj;
      }
      let data = [];
      let now = new Date(2021, 6, 3);
      let oneDay = 24 * 3600 * 1000;
      let value = Math.random() * 10;
      for (var i = 0; i < 100; i++) {
        data.push(randomData());
      }

      return data
    },
    getOption() {
      this.chartOptions = {
        // title: {
        //   text: 'Dynamic Data & Time Axis'
        // },
        grid: {
          left: 35,
          top: 35,
          right: 0,
          bottom: 20,
          show:true,
          borderColor: '#E2E2E2'
        },
        tooltip: {
          trigger: 'axis',
          // formatter: function (params) {
          //   params = params[0];
          //   var date = new Date(params.name);
          //   return (
          //     date.getDate() +
          //     '/' +
          //     (date.getMonth() + 1) +
          //     '/' +
          //     date.getFullYear() +
          //     ' : ' +
          //     params.value[1]
          //   );
          // },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          type: 'time',
          splitLine: {
            show: true
          },
          splitNumber: 12,
          // minInterval: 10
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0],
          splitLine: {
            show: true
          }
        },
        // series: [
        //   {
        //     name: 'Avg Value',
        //     type: 'line',
        //     showSymbol: false,
        //     data: this.chartData[0]
        //   }
        // ]
        series: this.chartData
    }
  },},
  mounted() {
    this.getOption();
  }
}
</script>

<style scoped>
  .echarts {
    width: 1420px;
    height: 720px;
  }
  .echarts div {
    width: 1420px;
    height: 720px;
  }
  .echarts canvas {
    width: 1420px;
    height: 720px;
  }
</style>