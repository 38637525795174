<template>
  <div class="i-pl-15 i-pr-15 border-transparent mb-8 relative" :style="{'width': getWidth(item)+'px', 'background': appearance === 0 ?  getBg(item.level, item) : 'transparent'}"
       :class="{
                  'i-border-1 border-primary-one': checkSelected(item.id),
                  'i-border-b-1 border-primary-one' : item.appearance === 2,
                  'shadow-two' : item.appearance === 0,
                  'rounded i-border-1' : item.appearance !== 2,
                  'border-secondary-five' : (item.appearance === 1 || item.appearance === 2) && !checkSelected(item.id),
                  'border-primary-one rounded' : checkSelected(item.id),
                  'mr-8' : index + 1 !== getLevelData.length || item.level === 1,
                  'i-mr-15' : index + 1 === getLevelData.length,
                  'i-mb-15' : item.child.length === 0 && item.level === 1,
                  'level1 item-no-des': item.level === 1,
                  'item-des single': item.level !== 1,
                  'text-secondary-one textarea-secondary-one' : item.appearance && item.appearance !== 0,
              }"
       @click="setActiveData(item)"
       @contextmenu.prevent="menuHandler($event, item)">
    <div class="flex items-center" :class="{'item-no-des': item.level === 1, 'pt-2': item.level !== 1}">
      <h2 class="fs-14 fw-bold mr-1"
          v-if="indexTitle">
        {{ item.index_code }}
      </h2>
      <input type="text" class="bg-transparent fw-700 w-full"
             v-model="item.title"
             :id="`l${item.level}_input_${item.id}`"
             @keyup="changedDataFromCollaboration(`l${item.level}_input_${item.id}`, item, item.title)"
             v-on:keyup.delete="removeItem($event, item)"
             @change="regularUpdate($event, item)"/>
    </div>
    <!-- expand feature -->
    <div class="absolute expanded-div rounded z-20 shadow-one bg-primary-three" v-show="expandedArray.find(el => el.id === item.id)">
      <div class="px-4 py-3 flex justify-start text-secondary-one relative">
        <text-feature v-if="getExpandStatus(item) === 'text'"
                      :item="item"
                      :index-title="indexTitle"
                      @changedDataFromCollaboration="changedDataFromCollaboration"
                      @regularUpdate="regularUpdate" />
        <chart-feature v-else-if="getExpandStatus(item) === 'chart'"
                       :item="item"
                       :index-title="indexTitle"
                       :module-properties="moduleProperties"
                       @changedDataFromCollaboration="changedDataFromCollaboration"
                       @regularUpdate="regularUpdate" />
        <div class="absolute expanded-icon" @click="collpaseItem(item)">
          <img src="/images/icons/brainstormV2/cross.svg"
               alt="icon"
               class="cursor-pointer" />
        </div>
      </div>
    </div>
    <!-- expand feature  -->

    <div class="fs-12" v-if="item.level !== 1">
      <textarea class="w-full bg-transparent bld-content-area content-desc-height"
                placeholder="Add descriptive text"
                v-model="item.description"
                :id="`l${item.level}_text_${item.id}`"
                @keyup="changedDataFromCollaboration(`l${item.level}_text_`, item, item.description)"
                @change="regularUpdate($event, item)"></textarea>
    </div>

    <div>
      <child-create-box v-if="activeData && activeData.id === item.id"
                        :l1="l1"
                        :l2="l2"
                        :l3="l3"
                        :l4="l4"
                        :current="item"
                        @newRootChild="newRootChild"
                        @newSubChild="newSubChild"
                        @updateParent="updateParent" />
      <div class="absolute expand-text-icon z-10" v-show="activeData.id === item.id" @click="expandItem(item, 'text')">
        <img src="/images/icons/brainstormV2/exp-text.svg"
             alt="icon"
             class="cursor-pointer" />
      </div>
      <div class="absolute expand-icon z-10 pt-1" v-show="activeData.id === item.id" @click="expandItem(item, 'chart')">
        <img src="/images/icons/build/properties.svg"
             alt="icon"
             class="cursor-pointer" />
    </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Indicator from "@/components/brainstormv2/canvas/Indicator";
import ChildCreateBox from "@/components/brainstormv2/canvas/ChildCreateBox";
import Property from '@/components/brainstormv2/canvas/ItemProperty';
import FlippedParameterScreen from "@/components/brainstormv2/canvas/Module/Horizontal/FlippedParameterScreen";
import FlippedImageScreen from "@/components/brainstormv2/canvas/Module/Horizontal/FlippedImageScreen";
import NotFlippedScreen from "@/components/brainstormv2/canvas/Module/Horizontal/NotFlippedScreen";
import TextFeature from "@/components/brainstormv2/canvas/Module/Horizontal/TextFeature";
import ChartFeature from "@/components/brainstormv2/canvas/Module/Horizontal/ChartFeature";

export default {
  name: "VerticalItem",
  components: {
    ChartFeature,
    TextFeature,
    FlippedParameterScreen,
    FlippedImageScreen,
    NotFlippedScreen,
    Indicator,
    ChildCreateBox,
    Property
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
    },
    moduleProperties: {
      type: Array
    },
    indexTitle: {
      type: Boolean
    },
    appearance: {
      type: Number
    },
    defaultColors: {
      type: Array
    },
    activeData: {
      type: Object
    },
    flippedArray: {
      type: Array
    },
    expandedArray: {
      type: Array
    },
    isolatedModules: {
      type: Array
    },
    l1: {
      type: Object
    },
    l2: {
      type: Object
    },
    l3: {
      type: Object
    },
    l4: {
      type: Object
    },
    parent: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      colorCodes: "global/colorCodes"
    }),
    getLevelData() {
      if(this.item.level === 1) {
        if(this.activeBrainstormData) {
          return this.activeBrainstormData.collections;
        }
      } else {
        return this.parent.child;
      }
      return [];
    },
  },
  methods: {
    getWidth(item) {
      let child = this.getData(item.child);
      let pos = this.checkPosition(child)
      let count = child.length
      let width = 0
      let margin = 32

      if (pos === 'v') {
        return width += 265
      }

      if (count > 0) {
        child.forEach(el => {
          width += this.getWidth(el)
        });
        width += margin*(count-1)
      }
      else {
        width += 265
      }
      return width
    },
    checkPosition(child) {
      let pos = 'v'
      child.forEach(el => {
        if (el.child.length > 0) {
          pos = 'h'
        }
      });
      return pos
    },
    getData(items) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    checkSelected(id) {
      if (this.activeData.id === id) {
        return true;
      }
      return false;
    },
    setActiveData(item) {
      this.$emit("setActiveData", item);
    },
    menuHandler(e, item) {
      this.$emit("menuHandler", e, item);
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    removeItem(e, item) {
      this.$emit("removeItem", e, item);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e,  item);
    },
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id === item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id === item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await this.getPropertyValueWithParam(item);
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return parseFloat(valueObj.sample_value);
      }
      return 0;
    },
    getModulePropertyParams(item) {
      let params = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    getPropertyValue(item) {
      let collection = this.activeBrainstormData.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(collection && collection.property_collections.length > 0) {
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let element = $(".chartcontent");
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (element.width() - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if(index === 0) {
            item.current_prop_param = this.getPropertyValueWithParam(item);
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    collpaseItem(item) {
      this.$emit("collpaseItem", item);
    },
    expandItem(item, partname) {
      this.$emit("expandItem", item, partname);
    },
    newRootChild() {
      this.$emit("newRootChild");
    },
    newSubChild(data) {
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$emit("updateParent", data);
    },
  }
}
</script>

<style scoped>
.default-width {
  width: 265px;
}
.item-des {
  height: 132px;
}
.item-no-des {
  height: 50px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.content-desc-height {
  height: 90px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  left: 250px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  left: 212px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 15px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item .bld-content-area {
  height: 250px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
</style>
