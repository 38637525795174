<template>
  <div v-if="!isolate || (isolate && isInIsolate(item))"
    @click="setActiveData(item)"
    class="br-level-w br-level-height px-4 pt-2 pb-2 relative rounded"
    :style="{'background': getBgWithFlip(item.level, item)}"
    :class="{
      'i-border-1': activeData.id === item.id || item.appearance == 1,
      'text-white textarea-primary-three shadow-two' : item.appearance == 0,
      'text-secondary-one textarea-secondary-one' : item.appearance != 0,
      'border-secondary-five' : item.appearance == 1 && activeData.id !== item.id,
      'border-primary-one' : activeData.id === item.id,

    }"
  >
    <div class="flip-card">
      <div class="flip-card-inner">
        <not-flipped-screen
          :item="item"
          :module="module"
          :index-title="indexTitle"
          :expanded-array="expandedArray"
          :module-properties="moduleProperties"
          v-if="!isFlipped(item)"
          @changedDataFromCollaboration="changedDataFromCollaboration"
          @regularUpdate="(e, item) => { canEdit(project.cans) && regularUpdate(e, item) }"
          @collpaseItem="collpaseItem"
          @deleteLevelItem="deleteLevelItem(item.id)"
          :menuHandler="menuHandler"
          @addNew="addNew"
      />
        <div class="flip-card-back" v-if="isFlipped(item)">
          <flipped-parameter-screen v-if="flippedCount(item) === 1"
            :item="item"
            :module="module"
            :index-title="indexTitle"
            :module-properties="moduleProperties"
            :flipped-array="flippedArray"
          />
          <flipped-image-screen
            v-if="flippedCount(item) === 2"
            :item="item"
            :index-title="indexTitle"
          />
        </div>
      </div>
    </div>
    <indicator :hasChild="item.child.length" :parent="getParent" :index="index" :previousChildCount="getPreviousChildCount(parentChild, index, item.level)" />
    <child-create-box
      v-if="activeData && activeData.id === item.id"
      :l1="l1"
      :l2="l2"
      :l3="l3"
      :l4="l4"
      :item="item"
      :current="item"
      :module="module"
      @newRootChild="newRootChild"
      @newSubChild="newSubChild"
      @updateParent="updateParent"
    />
      <!-- :class="{'pointer-events-none': !canCreate(project.cans)}" -->
<!--    @newRootChild="canCreate(project.cans)&&newRootChild"-->
<!--    @newSubChild="canCreate(project.cans)&&newSubChild"-->
<!--    @updateParent="canEdit(project.cans)&&updateParent"-->

    <div class="absolute context-icon z-10 pt-1" v-show="activeData.id === item.id" @click="menuHandler($event, item)">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
        <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
      </svg>
    </div>
    <div class="absolute delete-icon z-10 pt-1" v-show="activeData.id === item.id" @click="canDelete(project.cans) && deleteLevelItem(item.id)" >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
      </svg>
    </div>
    <div class="absolute expand-text-icon z-10 pt-1" v-show="activeData.id === item.id" @click="expandItem(item, 'text')">
      <img
        src="@/assets/images/icons/brainstormV2/exp-text.svg"
        alt="icon"
        class="cursor-pointer"
      />
    </div>
    <div class="absolute expand-icon z-10 pt-1" v-show="activeData.id === item.id" @click="expandItem(item, 'chart')">
      <img
        src="@/assets/images/icons/build/properties.svg"
        alt="icon"
        class="cursor-pointer"
      />
    </div>

    <div
      class="absolute property-icon rounded-sm cursor-pointer"
      :class="{'i-border-1 border-secondary-two': !isFlipped(item), 'shadow-two': isFlipped(item)}"
      :style="{'background': isFlipped(item) ? getFlipBg(1, item) : 'transparent'}"
      @click="flipCard(item)"
      v-if="activeBrainstormData && activeBrainstormData.scenario_id && activeData && activeData.id === item.id"
    />
  </div>
</template>

<script setup>
  // import usePermission from '@/composable/usePermission.js'
    import usePermission from '@/components/teamwork/brainstorm/usePermission.js'
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Indicator from "@/components/brainstormv2/canvas/Indicator";
import ChildCreateBox from "@/components/brainstormv2/canvas/ChildCreateBox";
import Property from '@/components/brainstormv2/canvas/ScenarioItemProperty';
import NotFlippedScreen from "@/components/brainstormv2/canvas/scenario/NotFlippedScreen";
import FlippedParameterScreen from "@/components/brainstormv2/canvas/scenario/FlippedParameterScreen";
import FlippedImageScreen from "@/components/brainstormv2/canvas/scenario/FlippedImageScreen";

export default {
  name: "HorizontalItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
    },
    module: {
      type: Object,
      required: true
    },
    flippedArray: {
      type: Array
    },
    isolatedModules: {
      type: Array
    },
    contextModule: {
      type: Number
    },
    indexTitle: {
      type: Boolean
    },
    description: {
      type: Boolean
    },
    appearance: {
      type: Number
    },
    defaultColors: {
      type: Array
    },
    expandedArray: {
      type: Array
    },
    moduleProperties: {
      type: Array
    },
    parentChild: {
      type: Array
    },
    l1: {
      type: Object
    },
    l2: {
      type: Object
    },
    l3: {
      type: Object
    },
    l4: {
      type: Object
    },
    deleteLevelItem: {
      type: Function
    }
  },
  components: {FlippedImageScreen, FlippedParameterScreen, NotFlippedScreen, ChildCreateBox, Indicator, Property},
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      activeData: "programmatic/activeScenarioCollection",
      colorCodes: "global/colorCodes",
      activeModule: "build/navigatorModule",
      project: "project/project",
    }),
    moduleSettings() {
      if(this.activeBrainstormData.scenario_id && this.module) {
        let settings = JSON.parse(this.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    isolate() {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      return !!(moduleInIsolation && moduleInIsolation.isolate);
    },
    getParent() {
      return this.item.level !== 1;
    }
  },
  methods: {
    addNew(){
      this.$emit('addNew', this.item)
    },
    isInIsolate(item) {
      if(parseInt(this.module.id) === parseInt(this.contextModule)) {
        let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
        if(moduleInIsolation && moduleInIsolation.isolate) {
          let isIndexed = this.isolatedModules.findIndex(id => parseInt(item.id) === parseInt(id));
          return isIndexed >= 0;
        }
        return true;
      } else {
        return true;
      }
    },
    setActiveData(item) {
      if(this.activeBrainstormData.scenario_id) {
        this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item)
        if (this.activeModule !== this.module.id) {
          this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", this.module.id)
        }
      } else {
        this.$store.dispatch("build/getActiveModuleCollection", item);
      }
    },
    getBgWithFlip(level, item) {
      if(this.isFlipped(item)) {
        return "#FFFFFF";
      }
      else if (item.appearance == 0) {
        return this.getBg(level, item)
      }
      else {
        return 'transparent'
      }
    },
    isFlipped(item) {
      this.setFlippedData(item);
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      return !!(flippedItem && flippedItem.flip_count >= 1);
    },
    setFlippedData(item) {
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.choosed_property_id;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.choosed_property_param_id;
      }
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getFlipBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return "";
        }
      }
      return "";
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    menuHandler(e, item) {
      this.$emit("menuHandler", e, item);
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    removeItem(e, item) {
      this.$emit("removeItem", e, item);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    },
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id == item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id == item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    getModulePropertyParams(item) {
      let params = this.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(params && params.property_collections.length > 0) {
        return params.property_collections.filter(param => !param.option || param.option == 1);
      }
      return [];
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    setCollectionPropertyParam(item) {
      item.current_prop_param = parseFloat(this.getPropertyValueWithParam(item));//item
      let data = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(value => parseInt(value.property_collection_id) === parseInt(item.choosed_property_param_id));
      if(valueObj) {
        return valueObj.sample_value;
      }
      return 0;
    },
    getPropertyValue(item) {
      let collection = this.module.properties.find(prop => parseInt(prop.id) === parseInt(item.choosed_property_id));
      if(collection && collection.property_collections.length > 0) {
        let element = $(".chartcontent");
        let parameters = this.getSliderProperties(item, collection.property_collections);
        let values = parameters.filter(value => collection.property_collections.find(prop_collection => parseInt(prop_collection.id) === parseInt(value.property_collection_id)));
        let width = (element.width() - (values.length * 7)) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if(value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if(index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          if(index === 0) {
            item.current_prop_param = parseFloat(this.getPropertyValueWithParam(item));
          }
        });
        item.average = sum / values.length
        return values
      }
      return [];
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties.filter(data => !data.option || data.option == 1).map(param => param.id);
      return item.properties.filter(data => sliderParams.includes(data.property_collection_id));
    },
    flippedCount(item) {
      let flippedItem = this.flippedArray.find(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      if(flippedItem && flippedItem.flip_count > 0) {
        return flippedItem.flip_count;
      }
      return 0;
    },
    collpaseItem(item) {
      var rmindex = this.expandedArray.findIndex(el => el.id == item.id);
      this.expandedArray.splice(rmindex, 1)
    },
    getTempImage() {
      return require('../../../../assets/images/flip_image.jpg');
    },
    getPreviousChildCount(data, currentIndex, level) {
      if(currentIndex > 0) {
        if(level === 2) {
          let count = 0;
          data[currentIndex - 1].child.forEach(item => {
            if(item.child.length > 1) {
              count += item.child.length;
            } else {
              count += 1;
            }
          });
          return count;
        } else {
          return data[currentIndex - 1].child.length;
        }
      }
      return 1;
    },
    newRootChild() {
      this.$emit("newRootChild");
    },
    newSubChild(data) {
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$emit("updateParent", data);
    },
    expandItem(item, partname) {
      if(this.activeBrainstormData && this.activeBrainstormData.scenario_id && this.module && this.module.properties && this.module.properties.length > 0) {
        item.choosed_property_id = this.module.properties[0].id;
        if(!item.choosed_property_param_id) {
          item.choosed_property_param_id = this.module.properties[0].property_collections[0].id;
        }
      }
      this.expandedArray.push({id: item.id, partname: partname, prop: item.choosed_property_id, param: item.choosed_property_param_id, value: item.current_prop_param})
    },
    flipCard(item) {
      this.$emit("flipCard", item);
    },
  },
  mounted() {
    // this.sockets.subscribe('brainstorm_data_updated', function (res) {
    //   if(res) {
    //     if(!res.load) {
    //       $(`#${res.id}`).val(res.value);
    //       $(`#${res.flip_1}`).text(res.title);
    //       $(`#${res.flip_2}`).text(res.title);
    //     }
    //   }
    // });
  }
}
</script>

<style scoped>
.context-icon{
  bottom: -25px;
  right: 105px;
  cursor: pointer;
}
.delete-icon{
  bottom: -25px;
  right: 85px;
  cursor: pointer;
}
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
/* .flip-card {
  width: 100%;
  perspective: 1000px;
}
.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card-toggle .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} */
.flip-card-back {
  /* color: white;
  transform: rotateY(180deg); */
  background: #FFFFFF;
}
.prop-div-root {
  height: 116px;
}
.prop-div-exp-root {
  height: 100px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.vertical-lr {
  /* writing-mode: vertical-lr; */
}
.rotate-bar-text {
  /* writing-mode: tb-rl; */
  /* transform: rotate(
      180deg
  ); */
  transform: rotate(270deg);
  /* transform-origin: 21% 30%; */
  white-space: nowrap;
}
.box-width {
  width: 50px;
}
.i-ml-14 {
  margin-left: 14px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -18px;
  top: -10px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 15px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
