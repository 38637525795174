<template>
  <div class="panzoom-exclude flip-card-front relative">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1" v-if="indexTitle">{{ item.index_code }}</h2>
      <input type="text"
             class="bg-transparent fs-14 fw-bold w-full ellipse panzoom-exclude"
             v-model="item.title"
             :id="'item_input_' + item.id"
             v-on:keyup.delete="removeItem($event, item)"
             @input="regularUpdate($event, item)"
             :disabled="!canEdit(project.cans)">
    </div>
    <textarea class="bg-transparent fs-12 w-full content-desc-height bld-content-area panzoom-exclude"
              v-model="item.description"
              :id="'item_text_' + item.id"
              placeholder="Add descriptive text"
              @input="regularUpdate($event, item)"
              :disabled="!canEdit(project.cans)"></textarea>
    <!-- expand feature -->
    <div class="absolute expanded-div rounded z-20 shadow-one bg-primary-three overflow-y-auto" v-show="expandedArray.find(el => el.id == item.id)">
      <div class="px-4 py-3 flex justify-start text-secondary-one relative">
        <text-feature :item="item"
                      :index-title="indexTitle"
                      v-if="getExpandStatus(item) === 'text'"
                      @changedDataFromCollaboration="changedDataFromCollaboration"
                      @regularUpdate="regularUpdate" />
        <chart-feature :item="item"
                       :module-properties="moduleProperties"
                       :module="module"
                       @changedDataFromCollaboration="changedDataFromCollaboration"
                       @regularUpdate="regularUpdate"
                       v-else-if="getExpandStatus(item) === 'chart'" />
      </div>
    </div>


    <div v-if="getExpandStatus(item) === 'text' || getExpandStatus(item) === 'chart'" class="option_buttons absolute z-20 flex gap-2" >
      <button @click="menuHandler($event, item)" class="contextOpener">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
          <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
        </svg>
      </button>
      <button @click="$emit('deleteLevelItem')">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
        </svg>
      </button>
      <button @click="$emit('addNew')">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
        </svg>
      </button>
    </div>

    <div v-if="getExpandStatus(item) === 'text' || getExpandStatus(item) === 'chart'" class="absolute expanded-icon z-20" @click="collpaseItem(item)">
      <img src="/images/icons/brainstormV2/cross.svg"
           alt="icon"
           class="cursor-pointer" />
    </div>
    <!-- expand feature  -->
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import TextFeature from "@/components/brainstormv2/canvas/scenario/TextFeature";
import ChartFeature from "@/components/brainstormv2/canvas/scenario/ChartFeature";
import {mapGetters} from "vuex";
export default {
  name: "NotFlippedScreen",
  components: {
    ChartFeature, TextFeature,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    menuHandler: {
      type: Function,
      required: true
    },
    module: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    },
    expandedArray: {
      type: Array
    },
    moduleProperties: {
      type: Array
    },
  },
  computed: {
    ...mapGetters({
      project: "project/project"
    })
  },
  methods: {
    getExpandStatus(item) {
      this.setExpandedData(item)
      let status = ''
      let expand = this.expandedArray.find(el => el.id == item.id)
      if (expand) {
        status = expand.partname
      }
      return status
    },
    setExpandedData(item) {
      let data = this.expandedArray.find(el => el.id == item.id)
      if(data) {
        item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
      }
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    },
    collpaseItem(item) {
      this.$emit("collpaseItem", item);
    },
    removeItem(e, item) {
      this.$emit("removeItem", e, item)
    }
  }
}
</script>

<style scoped>
.option_buttons{
  position: absolute;
  left: 15px;
  top: 265px;
}
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
.flip-card-back {
  /* color: white;
  transform: rotateY(180deg); */
  background: #FFFFFF;
}
.prop-div-root {
  height: 116px;
}
.prop-div-exp-root {
  height: 100px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
.vertical-lr {
  /* writing-mode: vertical-lr; */
}
.rotate-bar-text {
  /* writing-mode: tb-rl; */
  /* transform: rotate(
      180deg
  ); */
  transform: rotate(270deg);
  /* transform-origin: 21% 30%; */
  white-space: nowrap;
}
.box-width {
  width: 50px;
}
.i-ml-14 {
  margin-left: 14px;
}
.expanded-div {
  height: 296px;
  width: 265px;
  left: -17px;
  top: -9px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  /*right: 0px;*/
  /*bottom: 2px;*/
  left: -5px;
  top: 265px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
