<template>
  <div>
    <div class="flex justify-between fs-14 text-secondary-one concept-body mt-10 mb-46">
      <span class="text-primary-one fw-600">Diagrammatics</span>
      <div class="flex">
        <img class="ml-5 cursor-pointer" :class="{'opacity-60' : link !== 1}"
             src="/images/icons/brainstormV2/items.svg" alt="icon"
             @click="toggleItem" />
        <img class="ml-5 cursor-pointer" :class="{'opacity-60' : link !== 2}"
             src="/images/icons/brainstormV2/properties.svg" alt="icon" @click="toggleProperties" />
        <img class="ml-5 cursor-pointer" src="/images/icons/brainstormV2/save_as.svg" alt="" @click="loadingModal = true" />
      </div>
    </div>

    <default-intel v-if="link === 0" />
    <div class="concept-item-navigation overflow-y-auto" v-if="link === 1">
      <intel-list :intelModule="intelModule" :serial="1" @intelChartIsolation="intelChartIsolation" @setIntelGroup="setIntelGroup" @removeIntelIsolation="removeIntelIsolation" />
      <intel-list :intelModule="getIntelCanvasTwoModule" :serial="2" @intelChartIsolation="intelChartIsolation" @setIntelGroup="setIntelGroup" @removeIntelIsolation="removeIntelIsolation" v-if="activeStyle === 2" class="mt-8" />
    </div>

    <intel-modal :dModal="loadingModal" @toggleModalMode="toggleLoadingModalMode" />
  </div>
</template>

<script>
import IntelModal from "./IntelModal";
import DefaultIntel from "./DefaultIntel";
import IntelList from "./IntelList";
import {mapGetters} from "vuex";
export default {
  name: "ParentLeft",
  props: ['isolatedModules'],
  components: {IntelList, DefaultIntel, IntelModal},
  created() {
    this.loadIntel();
  },
  data() {
    return {
      link: 0,
      loadingModal: false
    }
  },
  computed: {
    ...mapGetters({
      intelModule: "intel/intelModule",
      canvasTwo: "intel/canvasTwo",
      intelItem: "intel/intelItem",
    }),
    activeStyle() {
      return this.intelItem && this.intelItem.style ? this.intelItem.style : 0;
    },
    getIntelCanvasTwoModule() {
      if(this.canvasTwo && this.canvasTwo.module && this.canvasTwo.module.id) {
        return this.canvasTwo.module;
      }
      return null;
    },
  },
  methods: {
    toggleItem() {
      this.link = this.link !== 1 ? 1 : 0;
    },
    toggleProperties() {
      this.link = this.link !== 2 ? 2 : 0;
    },
    toggleLoadingModalMode(value) {
      this.loadingModal = value;
    },
    loadIntel() {
      this.$store.dispatch("intel/loadIntelLists", {
        project_id: this.$route.params.id
      });
    },
    intelChartIsolation(data, itemId, serial) {
      this.$emit("intelChartIsolation", data, itemId, serial);
    },
    setIntelGroup(data, serial) {
      this.$emit("setIntelGroup", data, serial);
    },
    removeIntelIsolation(data, serial) {
      this.$emit("removeIntelIsolation", data, serial);
    }
  }
}
</script>

<style scoped>
.mb-46 {
  margin-bottom: 46px;
}
.concept-item-navigation {
  height: 785px;
  width: 275px;
  overflow-x: hidden;
}
</style>