<template>
  <modal name="intel-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'" style="z-index: 9999999">
    <div class="p-10">
      <div class="flex i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': !is_creating}"
            @click="is_creating = false, showList = true">
          All Intel</h3>
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': is_creating}"
            @click="is_creating = true, showList = false">
          Create new</h3>

      </div>
      <div class="i-mt-30">
        <div class="" :class="{'d-none': showList}">
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text"
                     class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                     :placeholder="placeholder.title" @click="placeholder.title = ''"
                     v-click-outside="setTitlePlaceholder"
                     v-model="intelObj.title">
            </div>
            <h2 class="font-poppins fs-14 absolute open-list-btn cursor-pointer text-primary-one" @click="showList = true" v-if="!is_creating">Open List</h2>
          </div>
        </div>
        <div class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" v-if="showList && !is_creating" style="height: 200px">
          <div v-for="(list, index) in intelLists" :key="index"
               class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 ws-update-item"
               :class="{'rounded i-border-1 border-secondary-two': choosedItemId === list.id}"
               @click="loadIntel(list.id)">
            {{ list.title }}
          </div>
        </div>
        <div class="flex i-mt-30">
          <button v-if="is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                  :class="{'pointer-events-none cursor-not-allowed': !activeBrainstormData.module_id && !activeBrainstormData.scenario_id}"
                  :disabled="loading || !intelObj.title || (!activeBrainstormData.module_id && !activeBrainstormData.scenario_id)" @click="save()">
            Create
          </button>
          <button v-if="!is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                  :disabled="loading || !intelObj.title || showList || !choosedItemId" @click="updateTheSet()">
            Open
          </button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
                  @click="closeModal()">Cancel
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "IntelModal",
  props: ['dModal', 'rootItem'],
  data() {
    return {
      is_creating: false,
      placeholder: {
        title: 'Type here',
        description: 'Type here'
      },
      intelObj: {
        title: '',
        description: ''
      },
      showList: true,
      choosedItemId: ''
    }
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    rootItem: function(val) {
      if(val) {
        this.propObj.title = val.title;
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      intelLists: "intel/intelLists",
      intel: "intel/intelItem",
    })
  },
  methods: {
    setTitlePlaceholder() {
      this.placeholder.title = 'Type here';
    },
    setDescriptionPlaceholder() {
      this.placeholder.description = 'Type here';
    },
    openModal() {
      this.getAllSavedProperties();
      this.$modal.show('intel-modal');
    },
    closeModal() {
      this.$modal.hide('intel-modal');
      this.showList = true;
      this.is_creating = false;
      this.choosedItemId = '';
      this.intelObj = {
        title: ''
      };
      this.$emit('toggleModalMode', false);
    },
    getAllSavedProperties() {
      this.$store.dispatch("manageProperty/getSavedProperties");
    },
    save() {
      const self = this;
      self.$store.state.loading = true;
      self.$Progress.start();
      let definitions = [
        ['Low', 'High'],
        ['Low', 'High']
      ];
      let colors = [
        '#E2E2E2',
        ["#F9F9F9"]
      ];
      let scenario_settings = {
        module_id: null,
        parameters: []
      };

      let data = {
        title: self.intelObj.title,
        brainstorm_id: self.$route.params.brainstorm_id,
        project_id: self.$route.params.id,
        module_id: self.activeBrainstormData.module_id ? self.activeBrainstormData.module_id : '',
        definitions: definitions,
        colors: colors,
        scenario_settings: JSON.stringify(scenario_settings),
        style: 0
      };

      self.$store.dispatch("intel/storeIntel", data).then(response => {
        self.$store.state.loading = true;
        self.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          self.loadIntel(response.data.data.id);
          self.closeModal();
        }
      });
    },
    updateTheSet() {
      const self = this;
    },
    loadIntel(id) {
      this.$Progress.start();
      this.$store.dispatch("intel/getIntel", {
        intel_id: id
      }).then(response => {
        this.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          if(this.activeBrainstormData.scenario_id && response.data.data.scenario_settings && response.data.data.scenario_settings.module_id) {
            let moduleId = response.data.data.scenario_settings.module_id;
            let module = this.activeBrainstormData.modules.find(item => parseInt(item.id) === moduleId);
            if(module) {
              this.$store.dispatch("intel/toggleModule", module);
            }

            let items = response.data.data.scenario_settings.parameters;
            this.$store.dispatch("intel/setParameters", {
              items: items,
              default: true
            });
          } else {
            if(this.activeBrainstormData.module_id) {
              this.$store.dispatch("intel/toggleModule", this.activeBrainstormData.module);
            }
          }

          this.closeModal();
        }
      });
    }
  }
}
</script>

<style scoped>
.open-list-btn {
  top: -28px;
}
</style>
