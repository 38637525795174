import { useSticky } from '@/components/teamwork/brainstorm/sticky/stickyController.js'
import { getFlattenCollections } from '@/store/modules/global/services';
import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'

import axios from 'axios';
import store from '@/store'
import { ref } from 'vue'

//holds the level of dragged level
const level = ref(null)
const collections = ref([])

export default function useLevel(brainstormId) {
    const { shareContentApi } = brainstormContentSharing();
    const { deleteSticky, stickyConvertToData } = useSticky()

    const setLevel = (data) => {
        level.value = data
    }

    const getWidth = (item) => {
        let children = getAllChildren(item)
        if (children.length == 1) return 265
        let fourthLevel = children.find(child => child.level == 4)
        if (fourthLevel) return 340
        let thirdLevel = children.find(child => child.level == 3)
        if (thirdLevel) return 318
        let secondLevel = children.find(child => child.level == 2)
        if (secondLevel) return 302
    }

    const getShortedChildren = (parent) => {
        let children = getAllChildren(parent)
        children = shortChildren(children)
        return children.filter(child => child.id != parent.id)
    }

    const shortChildren = (children) => {
        return children.sort((a, b) => {
            if (a.level && b.level) {
                return a.level - b.level
            }
            return -1
        })
    }

    const getAllChildren = (parent) => {
        if (parent.child.length === 0) return [parent];
        let allChildElements = [];
        for (let i = 0; i < parent.child.length; i++) {
            let children = getAllChildren(parent.child[i]);
            if (children) allChildElements.push(...children);
        }
        allChildElements.push(parent);
        return allChildElements;
    }

    const getMarginBottom = (item) => {
        let children = getAllChildren(item)
        if (children.length == 1) return 32
        return (children.length - 1) * 45 + 32
    }

    const getLeft = (item) => {
        if (item.level == 4) return '36px'
    }

    const getTop = (index, siblings) => {
        // console.log(index, 'innn')
        if (index < 1) return 40 + index * 45 + 'px'
        let previousSiblingChild = 0
        for (let i = 1; i <= index; i++) {
            let ch = getAllChildren(siblings[i - 1]).length
            previousSiblingChild += ch
        }
        return 40 + previousSiblingChild * 44 + 'px'
    }

    const onDragStart = (e) => {
        let topElem = document.elementFromPoint(e.clientX, e.clientY);
        let draggable = topElem.closest(".drag-handler");
        if (!draggable) return e.preventDefault()

        const levelId = e.target.getAttribute('data-id')
        const level = getParent(levelId) // actually its getParent return a level by id
        e.dataTransfer.setData("data-title", level.title);
        e.dataTransfer.setData("data-description", level.description);
        e.dataTransfer.setData("data-type", 'level');
        e.dataTransfer.setData("data-id", levelId);
        e.dataTransfer.setData("data-index", e.target.getAttribute('data-index'));
        e.dataTransfer.setData("data-parent-id", e.target.getAttribute('data-parent-id'));
        e.dataTransfer.setData("level-data-type", 'level');
        setLevel(e.target.getAttribute('level'))
        //e.target.style.visibility = "hidden";
    }

    const onDragend = (e) => {
        e.target.style.visibility = "visible";
    }

    const onDrag = (e) => {
        e.target.style.visibility = "hidden";
    }

    const onDragover = (event) => {
        event.preventDefault();
    }

    const setNewCreatedLevel = (data, parentId) => {
        let parent = getParent(parentId)
        // console.log(parent, 'parent')
        data.child = []
        parent.child.push(data)
    }

    const onDragenter = (event) => {
        const dropZone = event.target
        const dragElementTypes = event.dataTransfer.types
        // if(dragElementTypes.includes('level-data-type')) {
        //     handleLevelDragenter(event)
        //     return
        // }
        //console.log(level.value, '>level>>')
        const level = parseInt(dropZone.getAttribute('level'))
        if (level == 4) return
        event.target.classList.add('border-primary-one')
    }

    const onDragleave = (event) => {
        event.target.classList.remove('border-primary-one')
    }

    const setCollections = (data) => {
        // console.log(data, 'on set collectios')
        collections.value = data
    }

    const removeLevel = (id) => {
        const flattenCollections = getFlattenCollections(collections.value);
        const level = flattenCollections.find(el => el.id == id)
        if (!level) return
        if (level.parent_id) {
            const parent = getParent(level.parent_id)
            const index = parent.child.indexOf(level)

            let removed = parent.child.splice(index, 1)
        } else {
            const index = collections.value.indexOf(level)
            collections.value.splice(index, 1)
        }
    }

    const convertScenarioData = async (data, scenarioId) => {
        //todo
        try {
            await axios.post(`projects/scenarios/${scenarioId}/reorder`, data);
            fetchBrainstorm();
            shareContentApi();
        } catch (err) {
            console.log(err);
        }  
    }

    const convertModuleData = async (data) => {
        delete data.project_id
        //todo
        // const parent = getParent(data.old_parent)
        // const level = parent.child[data.old_index]
        // const newParent = getParent(data.parent_id)
        // newParent.child.push(level)
        // parent.child.splice(data.old_index, 1)
        // console.log(level, 'parent')
        // console.log(data)
        try {
            await axios.post('modules/collections/reorder-item', data)
        } catch (err) {
            console.log(err, '>>>>>>>>>')
        }

        fetchBrainstorm()
    }

    const deleteLevel = (id, projectId, scenarioId) => {

        if (confirm("Are you sure want to delete this?")) {
            // this.$Progress.start();
        return store.dispatch('programmatic/deleteLevelData', {
                project_id: projectId,
                scenario_id: scenarioId,
                level_id: id
            }).then(response => {
                removeLevel(id)
                store.commit("programmatic/setActiveBrainstormScenarioCollection", {})
            });
        }
    }

    const getLevelData = (items, isolatedModules, moduleId) => {
        let itemIndex = items.findIndex(data => data.root);
        if (itemIndex >= 0) {
            items.splice(itemIndex, 1);
            return
        }

        let moduleInIsolation = isolatedModules.find(item => item && item.module_id === moduleId);
        if (moduleInIsolation && moduleInIsolation.isolate) {
            return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
        }
        return items.filter(data => !data.root)
    }

    function getParent(parentId) {
        let flattenCollections = getFlattenCollections(collections.value);
        return flattenCollections.find(el => el.id == parentId)
    }

    function handleLevelDragenter(event) {
        const draggedItemLevel = level.value
        const dropItemLevel = event.target.getAttribute('level')
        if (draggedItemLevel - 1 == dropItemLevel) {
            event.target.style.borderColor = 'black'
        }
    }

    function check() {
        collections.value.splice(0, 1)
        //console.log(collections.value, 'collections')
        // console.log(activeBrainstormData, 'activeBrainstormData')
        // console.log(brain.value, 'brain')
    }

    function fetchBrainstorm() {
        store.dispatch("brainstormv1/getActiveBrainstormData", { brainstorm_id: brainstormId })
            .then(response => {
                if (response) {
                    let data = response.data.data
                    if (data.module_id || data.modules) {
                        let module_id = data.module_id ? data.module_id : data.modules[0].id
                        store.dispatch("build/toggleLeftNavigatorModuleSelection", module_id)
                            .then(response => {
                                if (response) {
                                    //this.$Progress.finish();
                                }
                            })
                    }
                }
            });
    }

    return {
        getWidth,
        getLeft,
        getMarginBottom,
        getAllChildren,
        onDragover,
        onDrag,
        onDragenter,
        onDragStart,
        onDragleave,
        getShortedChildren,
        check,
        collections,
        setCollections,
        deleteSticky,
        stickyConvertToData,
        setNewCreatedLevel,
        setLevel,
        level,
        onDragend,
        convertScenarioData,
        convertModuleData,
        removeLevel,
        getLevelData,
        getTop,
        deleteLevel
    }
}
