<template>
  <div class="flex relative" @click="closeMenuHandler($event)">
    <div
      class="
        bg-primary-three
        shadow-one
        text-primary-one
        rounded
        flex flex-col
        justify-between
        text-center
        py-5
        box-width
        cursor-pointer
      "
      :class="{ 'mr-8': expansion }"
      @click="toggleBarExpansion()"
    >
      <img
        src="@/assets/images/icons/scenario_level_bar.svg"
        alt="icon"
        class="px-2"
      />
      <div class="rotate-bar-text i-ml-14 pb-3" v-if="expansion">
        <h4 class="fs-14 font-poppins">{{ getIndexTitle(0) }}</h4>
      </div>
    </div>
    <div v-if="getLevelData && expansion">
      <!--level 1 loop-->
      <div
        class="flex font-poppins"
        v-for="(l1, l1Index) in getData(getLevelData)"
        :key="l1Index"
        :class="{ 'mt-8': l1Index > 0 }"
      >
        <horizontal-item
          :item="l1"
          :index="l1Index"
          :module="module"
          :flipped-array="flippedArray"
          :isolated-modules="isolatedModules"
          :context-module="contextModule"
          :index-title="indexTitle"
          :description="description"
          :appearance="appearance"
          :default-colors="defaultColors"
          :expanded-array="expandedArray"
          :module-properties="getModuleProperties"
          :parent-child="getData(getLevelData)"
          :l1="l1"
          @changedDataFromCollaboration="changedDataFromCollaboration"
          @removeItem="removeItem"
          :deleteLevelItem="deleteLevelItem"
          @regularUpdate="regularUpdate"
          @flipCard="flipCard"
          @newRootChild="newRootChild"
          @newSubChild="newSubChild"
          @updateParent="updateParent"
          @menuHandler="menuHandler"
          @addNew="addItem"
        />

        <div class="flex flex-col ml-8">
          <!--level 2 loop-->
          <div
            class="flex"
            v-for="(l2, l2Index) in getData(l1.child)"
            :key="l2Index"
            :class="{ 'mt-8': l2Index > 0 }"
          >
            <horizontal-item
              :item="l2"
              :index="l2Index"
              :module="module"
              :flipped-array="flippedArray"
              :isolated-modules="isolatedModules"
              :context-module="contextModule"
              :index-title="indexTitle"
              :description="description"
              :appearance="appearance"
              :default-colors="defaultColors"
              :expanded-array="expandedArray"
              :module-properties="getModuleProperties"
              :parent-child="getData(l1.child)"
              :l1="l1"
              :l2="l2"
              @changedDataFromCollaboration="changedDataFromCollaboration"
              @removeItem="removeItem"
              :deleteLevelItem="deleteLevelItem"
              @regularUpdate="regularUpdate"
              @flipCard="flipCard"
              @newRootChild="newRootChild"
              @newSubChild="newSubChild"
              @updateParent="updateParent"
              @menuHandler="menuHandler"
              @addNew="addItem"
            />

            <div class="flex flex-col ml-8">
              <!--level 3 loop-->
              <div
                class="flex"
                v-for="(l3, l3Index) in getData(l2.child)"
                :key="l3Index"
                :class="{ 'mt-8': l3Index > 0 }"
              >
                <horizontal-item
                  :item="l3"
                  :index="l3Index"
                  :module="module"
                  :flipped-array="flippedArray"
                  :isolated-modules="isolatedModules"
                  :context-module="contextModule"
                  :index-title="indexTitle"
                  :description="description"
                  :appearance="appearance"
                  :default-colors="defaultColors"
                  :expanded-array="expandedArray"
                  :module-properties="getModuleProperties"
                  :parent-child="getData(l2.child)"
                  :l1="l1"
                  :l2="l2"
                  :l3="l3"
                  @changedDataFromCollaboration="changedDataFromCollaboration"
                  @removeItem="removeItem"
                  :deleteLevelItem="deleteLevelItem"
                  @regularUpdate="regularUpdate"
                  @flipCard="flipCard"
                  @newRootChild="newRootChild"
                  @newSubChild="newSubChild"
                  @updateParent="updateParent"
                  @menuHandler="menuHandler"
                  @addNew="addItem"
                />

                <div class="flex flex-col ml-8">
                  <!--level 4 loop-->
                  <div
                    class="flex"
                    v-for="(l4, l4Index) in getData(l3.child)"
                    :key="l4Index"
                    :class="{ 'mt-8': l4Index > 0 }"
                  >
                    <horizontal-item
                      :item="l4"
                      :index="l4Index"
                      :module="module"
                      :flipped-array="flippedArray"
                      :isolated-modules="isolatedModules"
                      :context-module="contextModule"
                      :index-title="indexTitle"
                      :description="description"
                      :appearance="appearance"
                      :default-colors="defaultColors"
                      :expanded-array="expandedArray"
                      :module-properties="getModuleProperties"
                      :parent-child="getData(l3.child)"
                      :l1="l1"
                      :l2="l2"
                      :l3="l3"
                      :l4="l4"
                      @changedDataFromCollaboration="changedDataFromCollaboration"
                      @removeItem="removeItem"
                      :deleteLevelItem="deleteLevelItem"
                      @regularUpdate="regularUpdate"
                      @flipCard="flipCard"
                      @newRootChild="newRootChild"
                      @newSubChild="newSubChild"
                      @updateParent="updateParent"
                      @menuHandler="menuHandler"
                      @addNew="addItem"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <context-menu
      :item="contextmenuItem"
      :menu="menu"
      :id="'context-menu-' + module.id"
      v-show="contextmenu"
      class="absolute"
      @appearance="applyAppearnce"
      @appearanceSelf="applyAppearanceSelf"
      @indexTitle="applyIndexTitle"
      @background="applyBackground"
      @description="applyDescription"
      @isolate="applyIsolation"
      @addNew="addNew"
      :deleteLevelItem="deleteLevelItem"
    />
  </div>
</template>

<script>
import {brainstormContentSharing} from "@/composable/socket/brainstormContentSharing";
const { shareContentUpdating, shareContentApi, socket } = brainstormContentSharing();
import {cloneDeep, uniqBy} from "lodash";
import Indicator from "./Indicator";
import { mapGetters } from "vuex";
import ChildCreateBox from "./ChildCreateBox";
import ContextMenu from "./CntxtMenu";
import Property from "./ScenarioItemProperty";
import $ from "jquery";
import HorizontalItem from "@/components/brainstormv2/canvas/scenario/HorizontalItem";
import store from "@/store";

export default {
  name: "ScenarioHrCanvas",
  components: {
    HorizontalItem,
    ChildCreateBox,
    Indicator,
    ContextMenu,
    Property,
  },
  props: ["module", "flippedArray", "isolatedModules", "contextModule"],
  data() {
    return {
      flatten: [],
      expansion: true,
      freeTitles: [
        ["Identification", "Identifier", "Area", "Area Measure", "Measure"],
        ["Capability", "Capability Group", "Area", "Area Measure", "Measure"],
        ["Enabler", "Enabler Group", "Area", "Area Measure", "Measure"],
        ["Design", "Design Group", "Area", "Area Measure", "Measure"],
        ["Programme", "Programme Group", "Area", "Area Measure", "Measure"],
        ["Plan", "Plan Group", "Area", "Area Measure", "Measure"],
        ["Solution", "Solution Group", "Area", "Area Measure", "Measure"],
        ["Effect", "Effect Group", "Area", "Area Measure", "Measure"],
        ["Outcome", "Outcome Group", "Area", "Area Measure", "Measure"],
      ],
      defaultColors: ["#2B80AD", "#3D6A83", "#6A7881", "#818487"],
      imageObj: null,
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      expandedArray: [],
      menu: {
        description: true,
      },
    };
  },
  watch: {
    contextModule: function (val) {
      // console.log(val);
    },
    module: function(val) {
      this.setFlattenArray(module.collections);
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      activeData: "programmatic/activeScenarioCollection",
      colorCodes: "global/colorCodes",
      activeModule: "build/navigatorModule",
    }),
    moduleSettings() {
      if (this.activeBrainstormData.scenario_id && this.module) {
        let settings = JSON.parse(this.module.collection_settings);
        if (settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    getLevelData() {
      if (this.module) {
        return this.module.collections;
      }
      return [];
    },
    getModuleProperties() {
      if (
        this.activeBrainstormData &&
        this.activeBrainstormData.scenario_id &&
        this.module
      ) {
        return this.module.properties;
      }
      return [];
    },
    isolate() {
      let moduleInIsolation = this.isolatedModules.find(
        (item) => item && item.module_id === this.module.id
      );
      return !!(moduleInIsolation && moduleInIsolation.isolate);
    },
  },
  methods: {
    setFlattenArray(items) {
      this.flatten = this.getFlattenArray(items);
    },
    getFlattenArray(items) {
      let flatten = (arr) => {
        let data = [];
        if(!arr) {
          return
        }
        arr.forEach(recursiveFlatten);
        function recursiveFlatten(item) {
          data.push(item);
          if (item.child && item.child.length > 0) {
            item.child.forEach((child) => {
              recursiveFlatten(child);
            });
          }
        }
        return data;
      }
      return uniqBy(flatten(items), 'id')
    },
    addItem(item){
      let data = cloneDeep(item);
      data.project_id = this.$route.params.id;
      data.title = this.moduleSettings.collections[data.level].title;
      this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
        if(response && response.data && response.data.data && response.data.data.id) {
          this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
            this.$Progress.finish();
          });
          this.$emit("pushToIsolation", {
            module_id: this.module_id,
            id: response.data.data.id,
            parent_id: response.data.data.parent_id,
          });
        }
      });
    },
    getPreviousChildCount(data, currentIndex, level) {
      if (currentIndex > 0) {
        if (level === 2) {
          let count = 0;
          data[currentIndex - 1].child.forEach((item) => {
            if (item.child.length > 1) {
              count += item.child.length;
            } else {
              count += 1;
            }
          });
          return count;
        } else {
          return data[currentIndex - 1].child.length;
        }
      }
      return 1;
    },
    setActiveData(item) {
      if (this.activeBrainstormData.scenario_id) {
        this.$store.commit(
          "programmatic/setActiveBrainstormScenarioCollection",
          item
        );
        if (this.activeModule !== this.module.id) {
          this.$store.dispatch(
            "build/toggleLeftNavigatorModuleSelection",
            this.module.id
          );
        }
      } else {
        this.$store.dispatch("build/getActiveModuleCollection", item);
      }
    },
    newRootChild() {
      let l1Items = this.getLevelData;
      let moduleId = this.module.id;
      let order = 1;
      if (l1Items.length > 0) {
        order = l1Items[l1Items.length - 1].order;
      }
      this.$emit("newRootChild", moduleId, order);
    },
    newSubChild(data) {
      data.module_id = this.module.id;
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$Progress.start();
      data.brainstorm_id = this.$route.params.brainstorm_id;
      if (this.activeBrainstormData.scenario_id) {
        this.$store
          .dispatch("brainstormv1/scenarioParentUpdate", data)
          .then((response) => {
            this.$Progress.finish();
          });
      } else {
        if (this.activeBrainstormData.module_id) {
          this.$store
            .dispatch("brainstormv1/buildParentUpdate", data)
            .then((response) => {
              this.$Progress.finish();
            });
        } else {
          this.$store
            .dispatch("brainstormv1/regularUpdate", data)
            .then((response) => {
              this.$Progress.finish();
            });
        }
      }
    },
    regularUpdate(e, obj) {
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          scenario_id: this.activeBrainstormData.scenario_id,
          id: obj.id,
        };
        if (this.activeBrainstormData.scenario_id) {
          this.$store.dispatch("programmatic/updateCollection", item);
        }

        shareContentUpdating({
          title: item.title,
          description: item.description,
          id: item.id
        }, `room_${this.activeBrainstormData.id}`);
      }
    },
    removeItem(e, item) {
      if (e.keyCode === 46) {
        this.$Progress.start();
        if (this.activeBrainstormData.scenario_id) {
          this.$store
            .dispatch("programmatic/deleteLevelData", {
              project_id: this.$route.params.id,
              scenario_id: item.scenario_id,
              level_id: item.id,
            })
            .then((response) => {
              shareContentApi(this.activeBrainstormData.id);
              this.$Progress.finish();
              this.$store.dispatch("brainstormv1/getActiveBrainstormData", {
                brainstorm_id: this.$route.params.brainstorm_id,
              });
            });

          this.$emit("changedDataFromCollaboration", {
            load: true,
          });
        }
      }
    },
    deleteLevelItem(levelId=false) {
      if (confirm("are you sure want to delete this?")) {
        this.$Progress.start();
        if (this.activeBrainstormData.scenario_id) {
          this.$store
              .dispatch("programmatic/deleteLevelData", {
                project_id: this.$route.params.id,
                scenario_id: this.activeBrainstormData.scenario_id,
                level_id: (levelId) ? levelId : this.contextmenuItem.id,
              })
              .then((response) => {
                shareContentApi(this.activeBrainstormData.id);

                this.$Progress.finish();
                this.contextmenu = false;
                this.$store.dispatch("brainstormv1/getActiveBrainstormData", {
                  brainstorm_id: this.$route.params.brainstorm_id,
                });
              });

          this.$emit("changedDataFromCollaboration", {
            load: true,
          });
        }
      }
    },
    getL1Indexing(index, selfLevel) {
      if (this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        if (settings) {
          let indexing = "";
          let collection = settings.collections[selfLevel];
          if (settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
      }
      return "";
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      if (this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        return currentData + "." + (index + 1);
      }
    },
    getIndexId(selfLevel) {
      if (this.activeBrainstormData.scenario_id) {
        let settings = this.moduleSettings;
        if (settings) {
          if (settings.index_id) {
            let collection = settings.collections[selfLevel];
            return collection.index_id;
          }
        }
      }
      return "";
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice(indexLevel * 9, indexLevel * 9 + 9);
    },
    getBg(level, item) {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        if (this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if (settings) {
            let collection = settings.collections[level];
            if (collection) {
              if (collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getFlipBg(level, item) {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        if (this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if (settings) {
            let collection = settings.collections[level];
            if (collection) {
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return "";
        }
      }
      return "";
    },
    flipCard(item) {
      if (
        this.activeBrainstormData &&
        this.activeBrainstormData.scenario_id &&
        this.module &&
        this.module.properties &&
        this.module.properties.length > 0
      ) {
        item.choosed_property_id = this.module.properties[0].id;
        item.choosed_property_param_id =
          this.module.properties[0].property_collections[0].id;
        let data = {
          choosed_property_id: item.choosed_property_id,
          choosed_property_param_id: item.choosed_property_param_id,
          id: item.id,
        };
        this.$emit("toggleFlippedArray", data);
      }
    },
    getBgWithFlip(level, item) {
      if (this.isFlipped(item)) {
        return "#FFFFFF";
      } else if (item.appearance == 0) {
        return this.getBg(level, item);
      } else {
        return "transparent";
      }
    },
    isFlipped(item) {
      this.setFlippedData(item);
      // let index = this.flippedArray.findIndex(loopItem => parseInt(loopItem.id) === parseInt(item.id));
      // return index >= 0;
      let flippedItem = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      return !!(flippedItem && flippedItem.flip_count >= 1);
    },
    flippedCount(item) {
      let flippedItem = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      if (flippedItem && flippedItem.flip_count > 0) {
        return flippedItem.flip_count;
      }
      return 0;
    },
    setFlippedData(item) {
      let data = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      if (data) {
        item.choosed_property_id = item.choosed_property_id
          ? item.choosed_property_id
          : data.choosed_property_id;
        item.choosed_property_param_id = item.choosed_property_param_id
          ? item.choosed_property_param_id
          : data.choosed_property_param_id;
      }
    },
    async setCollectionPropertyParam(item) {
      item.current_prop_param = await parseFloat(
        this.getPropertyValueWithParam(item)
      );
      let data = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      if (data) {
        data.choosed_property_param_id = item.choosed_property_param_id;
      }
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(
        (value) =>
          parseInt(value.property_collection_id) ===
          parseInt(item.choosed_property_param_id)
      );
      if (valueObj) {
        return valueObj.sample_value;
      }
      return 0;
    },
    getModulePropertyParams(item) {
      let params = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(item.choosed_property_id)
      );
      if (params && params.property_collections.length > 0) {
        return params.property_collections.filter(
          (param) => !param.option || param.option == 1
        );
      }
      return [];
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties
        .filter((data) => !data.option || data.option == 1)
        .map((param) => param.id);
      return item.properties.filter((data) =>
        sliderParams.includes(data.property_collection_id)
      );
    },
    getPropertyValue(item) {
      let collection = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(item.choosed_property_id)
      );
      if (collection && collection.property_collections.length > 0) {
        let element = $(".chartcontent");
        let parameters = this.getSliderProperties(
          item,
          collection.property_collections
        );
        let values = parameters.filter((value) =>
          collection.property_collections.find(
            (prop_collection) =>
              parseInt(prop_collection.id) ===
              parseInt(value.property_collection_id)
          )
        );
        let width = (element.width() - values.length * 7) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if (value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if (index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          // value.title = collection.property_collections.find(propc => parseInt(propc.id) === parseInt(value.property_collection_id)).suffix;
          if (index === 0) {
            item.current_prop_param = parseFloat(
              this.getPropertyValueWithParam(item)
            );
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    isInIsolate(item) {
      if (parseInt(this.module.id) === parseInt(this.contextModule)) {
        let moduleInIsolation = this.isolatedModules.find(
          (item) => item && item.module_id === this.module.id
        );
        if (moduleInIsolation && moduleInIsolation.isolate) {
          let isIndexed = this.isolatedModules.findIndex(
            (id) => parseInt(item.id) === parseInt(id)
          );
          return isIndexed >= 0;
        }
        return true;
      } else {
        return true;
      }
    },
    getData(items) {
      let itemIndex = items.findIndex((data) => data.root);
      if (itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(
        (item) => item && item.module_id === this.module.id
      );
      if (moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(
          (data) =>
            moduleInIsolation.isolatedIds.includes(data.id) && !data.root
        );
      }
      return items.filter((data) => !data.root);
    },
    getTempImage() {
      return require("../../../assets/images/flip_image.jpg");
    },
    uploadFile(item) {
      this.imageObj = item;
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        let fileUrl = URL.createObjectURL(f);
        this.imageObj.temp_image = fileUrl;
      });
    },
    getIndexTitle(parentCodeIndex) {
      let moduleSettings = this.moduleSettings;
      if (moduleSettings && moduleSettings.collections) {
        if (moduleSettings.index_number) {
          return parseInt(
            moduleSettings.collections[parentCodeIndex].index_number
          );
        }
        return "";
      } else {
        return "";
      }
    },
    toggleBarExpansion() {
      this.expansion = !this.expansion;
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", {
        id: `${initial}${item.id}`,
        flip_1: `flip_1_l${item.level}_${item.id}`,
        flip_2: `flip_2_l${item.level}_${item.id}`,
        value: value,
        title: item.title,
        description: item.description,
        load: false,
      });
    },
    // context menu
    menuHandler(e, item) {
      this.contextmenu = true;
      this.contextmenuItem = item;
      let top = e.clientY - this.$el.getBoundingClientRect().y;
      let left = e.clientX - this.$el.getBoundingClientRect().x;
      let context = document.getElementById("context-menu-" + item.module_id);
      context.style.left = left + "px";
      context.style.top = top + "px";
    },
    closeMenuHandler(e) {
      let module_id = this.module.id;
      var context = document.getElementById("context-menu-" + module_id);
      if (context && !context.contains(e.target) && !e.target.closest('.context-icon') && !e.target.closest('.contextOpener')) {
        this.contextmenu = false;
      }
    },
    applyAppearnce(value) {
      this.appearance = value;
      let data = this.getLevelData;
      this.setAppearanceToAll(data, value);
      this.contextmenu = false;
    },
    setAppearanceToAll(data, value) {
      data.forEach((el) => {
        el.appearance = value;
        if (el.child.length > 0) {
          this.setAppearanceToAll(el.child, value);
        }
      });
    },
    applyAppearanceSelf(value) {
      this.contextmenuItem.appearance = value;
      this.contextmenu = false;
    },
    applyIndexTitle(value) {
      this.indexTitle = value;
      this.contextmenu = false;
    },
    applyDescription(value) {
      this.description = value;
      this.contextmenu = false;
    },
    applyIsolation(value) {
      // this.isolate = value;
      // this.contextmenu = false;
      // this.contextModule = this.contextmenuItem.module_id;
      // if(this.isolate) {
      //   this.getIds(this.contextmenuItem);
      // } else {
      //   this.isolatedIds = [];
      // }
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = { ...this.contextmenuItem };
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.activeBrainstormData.module_id) {
        this.$store.dispatch("build/regularUpdate", obj);
      }
      if (this.activeBrainstormData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false;
    },
    getExpandStatus(item) {
      this.setExpandedData(item);
      let status = "";
      let expand = this.expandedArray.find((el) => el.id == item.id);
      if (expand) {
        status = expand.partname;
      }
      return status;
    },
    setExpandedData(item) {
      let data = this.expandedArray.find((el) => el.id == item.id);
      if (data) {
        item.choosed_property_id = item.choosed_property_id
          ? item.choosed_property_id
          : data.prop;
        item.choosed_property_param_id = item.choosed_property_param_id
          ? item.choosed_property_param_id
          : data.param;
      }
    },
    expandItem(item, partname) {
      if (
        this.activeBrainstormData &&
        this.activeBrainstormData.scenario_id &&
        this.module &&
        this.module.properties &&
        this.module.properties.length > 0
      ) {
        item.choosed_property_id = this.module.properties[0].id;
        item.choosed_property_param_id =
          this.module.properties[0].property_collections[0].id;
      }
      this.expandedArray.push({
        id: item.id,
        partname: partname,
        prop: item.choosed_property_id,
        param: item.choosed_property_param_id,
        value: item.current_prop_param,
      });
    },
    collpaseItem(item) {
      var rmindex = this.expandedArray.findIndex((el) => el.id == item.id);
      this.expandedArray.splice(rmindex, 1);
    },
    addNew(item) {
      let parent = this.contextmenuItem;
      let chcount = parent.child.length;
      let order = 1;
      if (chcount > 0) {
        order = chcount + 1;
      }
      let obj = {};
      obj.parent_id = parent.id;
      obj.title = this.moduleSettings.collections[parent.level + 1].title;
      obj.level = parent.level + 1;
      obj.item_order = order;
      obj.order = order;
      obj.module_id = parent.module_id;
      obj.scenario_id = parent.scenario_id;
      this.$Progress.start();
      this.$emit("newSubChild", obj);
      this.contextmenu = false;
    },
  },
  mounted() {
    if(this.module) {
      this.setFlattenArray(this.module.collections);
    }
    socket.value.on("brainstorm_content_updating", (data) => {
      let item = this.flatten.find(levelData => levelData.id == data.id);
      if(item) {
        item.title = data.title;
        item.description = data.description;
      }
    });

    socket.value.on('brainstorm_api_updating', (data) => {
      let brainstormId = data.brainstormId || this.$route.params.brainstorm_id;
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: brainstormId});
    });
  },
};
</script>

<style scoped>
.br-level-height {
  height: 132px;
}
.content-desc-height {
  height: 90px;
}
.br-level-w {
  width: 265px;
}
.textarea-primary-three textarea::placeholder {
  color: #c9c9c9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.property-icon {
  width: 13px;
  height: 13px;
  bottom: -24px;
  right: 0;
}
/* .flip-card {
  width: 100%;
  perspective: 1000px;
}
.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card-toggle .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} */
.flip-card-back {
  /* color: white;
  transform: rotateY(180deg); */
  background: #ffffff;
}
.prop-div-root {
  height: 116px;
}
.prop-div-exp-root {
  height: 100px;
}
.brainstorm-prop-selector {
  border: none;
  box-shadow: none;
  outline: none;
  margin-left: -4px;
  width: fit-content;
}
.brainstorm-prop-selector:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.brainstorm-prop-selector:active {
  border: none;
  box-shadow: none;
  outline: none;
}
.w-50 {
  width: 50%;
}
.chartcontent {
  height: 60px;
  width: 116px;
}
.max-prop {
  top: -20px;
  left: -8px;
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
.flipped-image {
  width: 231px;
  height: 81px;
}
.property-image-icon {
  left: 10px;
  bottom: 10px;
}
.vertical-lr {
  /* writing-mode: vertical-lr; */
}
.rotate-bar-text {
  /* writing-mode: tb-rl; */
  /* transform: rotate(
      180deg
  ); */
  transform: rotate(270deg);
  /* transform-origin: 21% 30%; */
  white-space: nowrap;
}
.box-width {
  width: 50px;
}
.i-ml-14 {
  margin-left: 14px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -18px;
  top: -10px;
}
.expand-icon {
  right: 24px;
  bottom: -26px;
  opacity: 0.75;
  width: 17px;
}
.expand-text-icon {
  right: 52px;
  bottom: -23px;
  opacity: 0.75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 15px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px;
}
.expand-item:nth-child(2) {
  width: 270px;
}
.expand-item .bld-content-area {
  height: 250px;
}
</style>
