<template>
    <div class="p-10" style="width: 600px;">
        <div class="flex i-border-b-1 border-secondary-four">
            <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': !is_creating}"
                @click="is_creating = false, showList = true">
                All Concepts</h3>
            <h3 v-if="canCreate(project.cans)" class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': is_creating}"
                @click="is_creating = true, showList = false">
                Create new</h3>
        </div>
        <div class="i-mt-30">
            <div class="" :class="{'d-none': showList}">
                <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
                <div class="flex justify-between items-center mt-2 relative">
                    <div class="modal-collaborator flex-3 pr-3 relative">
                        <input type="text"
                            class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                            :placeholder="placeholder.title" @click="placeholder.title = ''"
                            v-click-outside="setTitlePlaceholder"
                            v-model="conceptObj.title">
                    </div>
                    <h2 class="font-poppins fs-14 absolute open-list-btn cursor-pointer text-primary-one" @click="showList = true" v-if="!is_creating">Open List</h2>
                </div>
            </div>
            <div class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" v-if="showList && !is_creating" style="height: 200px">
                <div v-for="(list, index) in concepts" :key="index"
                        class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 ws-update-item"
                        :class="{'rounded i-border-1 border-secondary-two': choosedItemId === list.id}"
                        @click="loadConcept(list.id)">
                    {{ list.title }}
                </div>
            </div>
            <div class="flex i-mt-30">
                <button v-if="is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                        :class="{'pointer-events-none cursor-not-allowed': !activeBrainstormData.module_id && !activeBrainstormData.scenario_id}"
                        :disabled="loading || !conceptObj.title || (!activeBrainstormData.module_id && !activeBrainstormData.scenario_id)" @click="save()">
                    Create
                </button>
                <button v-if="!is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                        :disabled="loading || !conceptObj.title || showList || !choosedItemId" @click="updateTheSet()">
                    Open
                </button>
                <button 
                    class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'

  const { canRead, canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "LoadingModal",
        props: ['dModal', 'rootItem'],
        data() {
            return {
                is_creating: false,
                placeholder: {
                    title: 'Type here',
                    description: 'Type here'
                },
                conceptObj: {
                    title: '',
                    description: ''
                },
                showList: true,
                choosedItemId: ''
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            },
            rootItem: function(val) {
                if(val) {
                    this.propObj.title = val.title;
                }
            }
        },
        computed: {
            ...mapGetters({
                loading: "GET_LOADING_STATE",
                activeBrainstormData: "brainstormv1/activeBrainstormData",
                concepts: "concept/concepts",
                project: "project/project",
            })
        },
        methods: {
            setTitlePlaceholder() {
                this.placeholder.title = 'Type here';
            },
            setDescriptionPlaceholder() {
                this.placeholder.description = 'Type here';
            },
            openModal() {
                this.getAllSavedProperties();
                this.$modal.show('loading-modal');
            },
            closeModal() {
                this.$modal.hide('loading-modal');
                this.showList = true;
                this.is_creating = false;
                this.choosedItemId = '';
                this.conceptObj = {
                  title: ''
                };
                this.$emit('toggleModalMode', false);
            },
            getAllSavedProperties() {
                this.$store.dispatch("manageProperty/getSavedProperties");
            },
            save() {
                const self = this;
                self.$store.state.loading = true;
                self.$Progress.start();
                let definitions = [
                    ['Low', 'High'],
                    ['Low', 'High']
                ];
                let colors = ["#73B98B", "#C97F7F", "#BCC292", "#2F6875"];
                let hints_text = ["Add text", "Add text", "Add text", "Add text"];
                let scenario_settings = {
                  module_id: null,
                  property_h_id: null,
                  property_v_id: null,
                  property_h_p_id: null,
                  property_h_v_id: null
                };

                let data = {
                  title: self.conceptObj.title,
                  brainstorm_id: self.$route.params.brainstorm_id,
                  project_id: self.$route.params.id,
                  module_id: self.activeBrainstormData.module_id ? self.activeBrainstormData.module_id : '',
                  definitions: definitions,
                  colors: colors,
                  hints_text: hints_text,
                  hints_text_c_2: hints_text,
                  scenario_settings: JSON.stringify(scenario_settings),
                  style: 0
                };

                this.$store.dispatch("concept/storeConcept", data).then(response => {
                  self.$store.state.loading = true;
                  self.$Progress.finish();
                  if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
                    // self.$store.dispatch("concept/getConcept", response.data.data.id)
                    // self.closeModal();
                    self.$emit('close')
                  }
                });
            },
            updateTheSet() {
                const self = this;
                self.$store.state.loading = true;
                // let data = {
                //     updatable_id: self.choosedItemId,
                //     title: self.propObj.title,
                //     is_default: self.rootItem.is_default,
                //     is_visible: self.rootItem.is_visible
                // };
                // let properties = self.rootItem.property_collections.filter(item => item.type === 1);
                // self.rootItem.property_collections.forEach(item => {
                //     if(item.type === 2) {
                //         properties.push(item);
                //     }
                // });
                // data.properties = properties;
                // self.$store.dispatch("manageProperty/updateTheSet", data).then(response => {
                //     if(response) {
                        self.$emit('close')
                //     }
                    self.$store.state.loading = false;
                // });
            },
            loadConcept(id) {
              this.$Progress.start();
              this.$store.dispatch("concept/getConcept", id).then(response => {
                this.$Progress.finish();

                if(response.data.data && response.data.data.module_id) {
                  let module = this.activeBrainstormData.module;
                  this.$store.dispatch("concept/setConceptModule", module);
                }

                if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
                    this.$emit('close')
                }
              });
            }
        }
    }
</script>

<style scoped>
.open-list-btn {
    top: -28px;
}
</style>
