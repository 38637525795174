<template>
    <div>
        <div
            @dragover.prevent @drop="onDrop"
            class="rounded sticky_container sticky-drop-zone space-y-5"
        >
            <input type="text"
                   :placeholder="searchField.placeholder"
                   v-model="searchField.text"
                   @click="searchField.placeholder = ''"
                   v-click-outside="outsideSearchInput"
                   class="i-h-50 w-full border border-secondary-two px-4 text-secondary-one placeholder-secondary-two bg-transparent rounded fs-16 i-mt-30">
            <button v-if="canCreate(project.cans)" @click="addNote()" class="grid place-content-center sticky_btn rounded border border-white cursor-pointer w-full">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6216 7.488H7.77359V12.456H5.22959V7.488H0.381594V5.184H5.22959V0.215999H7.77359V5.184H12.6216V7.488Z" fill="#1C1C1D"/>
                </svg>
            </button>
            <div class="sticky_wrapper" ref="sticky_wrapper">
                <template v-for="note in notes">
                    <StickyNote
                        v-if="hasCoordination(note)"
                        :key="note.id"
                        @deleteNote="id=>deleteNote(id)"
                        :id="note.id"
                        :title="note.title + '-' + note.selected"
                        :description="note.description"
                        :color="(note.settings && note.settings.bg_color) ? note.settings.bg_color : '#E7EDC0'"
                        :coordination="{x:0, y:0}"
                        :data="note"
                        :style="{pointerEvents: !canEdit(project.cans)&&'none'}"
                    />
                </template>
            </div>

        </div>
    </div>
</template>


<script setup>
    import StickyNote from './StickyNote.vue';
    import { computed, ref } from 'vue';
    import router from '@/routes/routes.js';
    import store from "@/store";
    import usePermission from '@/composable/usePermission.js';
    import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'

    const { canEdit, canCreate, canDelete } = usePermission()
    const { shareContentApi } = brainstormContentSharing();
    
    // eslint-disable-next-line no-undef
    const props =  defineProps({
        stickyNotes: {
            type: Array
        },
        getSticky: Function,
        createSticky: Function,
        deleteSticky: Function,
        setStickyNotes: Function,
        removeDeletedSticky: Function,
        dataConvertToSticky: Function,
        activeBrainstormData: Object
    });

    const route = router.currentRoute;
    const sticky_wrapper = ref(null);
    const currentNote = ref(null)
    const searchField = ref({
      placeholder: 'Search',
      text: ''
    })
    const notes = computed(() => {
        // props.stickyNotes.forEach((note) => {
        //     if(note.id === currentNote.value?.id){
        //         note.selected = true
        //     }else{
        //         note.selected = false
        //     }
        // })

        if (searchField.value.text) {
          return props.stickyNotes.filter((item) => {
            return searchField.value.text
                .toLowerCase()
                .split(" ")
                .every((v) => item.title.toLowerCase().includes(v));
          });
        }

        return props.stickyNotes
    })

    const project = computed(() => {
        return store.getters['project/project']
    })

    const hasCoordination = (note) => {
       return note.coordination?.x == null && note.coordination?.y == null;
    }
    const deleteNote = async (id) => {
        await props.deleteSticky(id);
        props.removeDeletedSticky(id)
    }
    const addNote = async () => {
        const element = sticky_wrapper.value;
        element.scrollBy(0, element.scrollHeight) // scrollBy(x, y)
        await props.createSticky({
            brainstorm_id: route.params.brainstorm_id,
            project_id: route.params.id,
            title: 'Your note here',
            coordination: { x: null, y: null }
        });
       let notes = await props.getSticky(route.params.brainstorm_id, 'add note');
       props.setStickyNotes(notes)
    }

    const onDrop = async (e) => {
        const dragElementTypes = e.dataTransfer.types
        if(dragElementTypes.includes('level-data-type')) {
            //handleLevelDragover(e)
            let data = {
                brainstorm_id: route.params.brainstorm_id,
                project_id: route.params.id,
                model: getModelName(),
                title: e.dataTransfer.getData('data-title'),
                level: e.dataTransfer.getData('level'),
                description: e.dataTransfer.getData('data-description'),
                collection_id: e.dataTransfer.getData('data-id'),
                coordination: { x: null, y: null }
            }

            await props.dataConvertToSticky(data);
            shareContentApi(route.params.brainstorm_id);
            let notes = await props.getSticky(route.params.brainstorm_id, 'add note');
            props.setStickyNotes(notes)

        }
        // console.log(e)
    }

    function getModelName()  {
        const brainStormData = props.activeBrainstormData
        if(brainStormData.module_id) return 'ModuleCollection'
        if(brainStormData.scenario_id   ) return 'ScenarioCollection'
        return 'BrainstormCollection'
    }

    function outsideSearchInput() {
      searchField.value.placeholder = 'Search';
    }
</script>

<style scoped>
    .sticky_container {
        background: #F3F3F3;
    }
    .sticky_wrapper {
        min-height: 100px;
        max-height: 700px;
        overflow-y: auto;
    }
    .sticky_btn,
    .sticky_item {
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
    .sticky_btn {
        height: 50px;
        background: #E7EDC0;
    }
</style>
