import { useShare } from '@/components/teamwork/brainstorm/useShare.js';
import { isEmpty, isNull, find } from 'lodash';
export default function usePermission() {
    const { fullInvitationList } = useShare();

    const _checker = (source, key) => {
        if(!isEmpty(source)) {
            return source.includes(key)
        }

        if(!isEmpty(fullInvitationList.value)) {
            let user = JSON.parse(localStorage.getItem('_user'));
            let invitation = find(fullInvitationList.value, ['user_id', user.id]) || find(fullInvitationList.value, item => !isNull(item.till_valid));
            if(!isEmpty(invitation)) {
                return invitation.cans.includes(key);
            }
        }
        return false;
    }
    
    
    const canRead = (projectCans) => _checker(projectCans, 'read');
    const canCreate = (projectCans) => _checker(projectCans, 'create');
    const canEdit = (projectCans) => _checker(projectCans, 'edit');
    const canUpdate = (projectCans) => _checker(projectCans, 'update');
    const canDelete = (projectCans) => _checker(projectCans, 'delete');
    
    return {
        canCreate,
        canRead,
        canEdit,
        canDelete,
        canUpdate
    }
}