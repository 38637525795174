<template>
  <div>
    <div class="flex mt-9 items-center">
      <img src="/images/icons/build/navigation.svg" alt="icon"
        class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
        :class="{'opacity-100': activePanel === 1 && ($route.name == 'teamwork.mindmap' || $route.name == 'teamwork.brainstorm' || $route.name == 'teamwork.canvas')}"
        @click="clickNavigation($event), $emit('resetZoom'), $emit('closeIntel')" />
      <img src="/images/icons/build/global_library.svg" alt="icon"
        class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
        :class="{'opacity-100': activePanel === 2}"
        @click="setActivePanel(2), closeConcept($event), $emit('resetZoom'), $emit('closeIntel')" />
      <img src="/images/icons/build/properties.svg" alt="icon"
        class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
        :class="{'opacity-100': activePanel === 3}"
        @click="navigateToProperty(), $emit('resetZoom'), $emit('closeIntel')"/>
      <!-- <img src="/images/icons/brainstormV2/concept.svg" alt="icon"
        class="i-menu-icon cursor-pointer opacity-50  hover:bg-secondary-three"
        :class="{'opacity-100': $route.name == 'teamwork.concepts'}"
        @click="gotoConcept($event), $emit('closeIntel')"/> -->
        <img @click="setActivePanel(6)" src="/images/sticky-btn.png" alt="" class="i-menu-icon cursor-pointer i-ml-30 opacity-50  hover:bg-secondary-three" :class="{'opacity-100': activePanel === 6}">
        <!-- <SqureCopyIcon  class="w-6 h-6 i-ml-30 cursor-pointer" /> -->
      <img src="/images/icons/brainstorm/TagIcon.svg" alt="icon"
        class="i-menu-icon w-6 cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
        :class="{'opacity-100': activePanel === 5}"
        @click="activePanel = 5"/>
    </div>

    <!--components-->
    <div>
        <!-- Active panel 1 start -->
        <scenario-item-list
          :isolatedModules="isolatedModules"
          :isolatedModuleId="isolatedModuleId"
          v-if="activePanel === 1 && activeBrainstormData && activeBrainstormData.scenario_id &&
          ($route.name == 'teamwork.mindmap' || $route.name == 'teamwork.brainstorm' || $route.name == 'teamwork.canvas')"
          @toggleIsolation="toggleIsolation"
          @removeIsolation="removeIsolation"
          @moduleIsolation="moduleIsolation"
        />
        <item-list
          :isolatedModules="isolatedModules"
          v-if="activePanel === 1 && activeBrainstormData && !activeBrainstormData.scenario_id && ($route.name == 'teamwork.mindmap' || $route.name == 'teamwork.brainstorm' || $route.name == 'teamwork.canvas')"
          @toggleIsolation="toggleIsolation"
          @removeIsolation="removeIsolation"
        />
        <!-- Active panel 1 end -->

        <!-- Active panel 2 start -->
        <global-list v-if="activePanel === 2" />
        <!-- Active panel 2 end -->

        <!-- Active panel 3 start -->
        <scenario-property
          v-if="activePanel === 3 && activeBrainstormData && activeBrainstormData.scenario_id && $route.name != 'teamwork.diagrammatic' && $route.name != 'teamwork.concepts'"
          @changedDataFromCollaboration="changedDataFromCollaboration"
        />
        <property v-if="activePanel === 3 && activeBrainstormData && activeBrainstormData.scenario_id == null" @changedDataFromCollaboration="changedDataFromCollaboration" />
        <!-- Active panel 3 end -->


        <!-- Active panel concept start -->
        <concept :isolatedModules="isolatedModules"
          v-if=" activePanel !== 5 && $route.name == 'teamwork.concepts'"
          @toggleIsolation="toggleIsolation"
          @changedDataFromCollaboration="changedDataFromCollaboration" @removeIsolation="removeIsolation"
        />
        <!-- Active panel concept end -->

         <intel v-if="$route.name == 'teamwork.diagrammatic'" :isolatedModules="isolatedModules" @intelChartIsolation="intelChartIsolation" @setIntelGroup="setIntelGroup" @removeIntelIsolation="removeIntelIsolation" />

        <!-- Active panel 5 tags start -->
          <div v-if="activePanel === 5 && activeBrainstormData && activeBrainstormData.scenario_id">
            <AttachedTags class="overflow-auto mt-10" style="height: 900px" />
         </div>
        <!-- Active panel 5 tags end -->

        <!-- Active panel 6 tags end -->
         <StickyList  v-if="activePanel === 6" :activeBrainstormData="activeBrainstormData"/>
        <!-- Active panel 6 tags end -->

    </div>
  </div>
</template>

<script>
import ItemList from "./ItemList";
import StickyList from "./StickyList"
import GlobalList from "./GlobalList";
import Property from "./Property";
import Concept from "../concept/LeftDefault.vue";
import {mapGetters} from "vuex";
import ScenarioItemList from "./ScenarioItemList";
import ScenarioProperty from "./ScenarioProperty";
import Intel from "../intel/leftpart/ParentLeft";

export default {
  name: "LeftPart",
  components: {Intel, ScenarioItemList, GlobalList, ItemList, Property, ScenarioProperty, Concept, StickyList},
  props: ['navigationClicked', 'isolatedModules', 'isolatedModuleId'],
  data() {
    return {
      activePanel: 1,
    }
  },
  computed: {
    ...mapGetters({
        activeBrainstormData: "brainstormv1/activeBrainstormData",
        activeData: "programmatic/activeScenarioCollection",
        activeLevelId: "brainstormv1/getActiveLevelId",
    }),
    checkActiveCollection() {
      let active = this.activeBrainstormData.scenario_id ? this.$store.state.programmatic.activeScenarioCollection : this.$store.state.build.activeModuleCollection
      if (Object.entries(active).length === 0) {
        return false
      }
      return true
    },
  },
  methods: {
    setInitialPanel(value) {
      this.activePanel = value;
    },
    changedDataFromCollaboration(data) {
      this.$emit('changedDataFromCollaboration', data);
    },
    setActivePanel(value) {
      if(this.$route.name != 'teamwork.diagrammatic' && this.$route.name != 'teamwork.concepts') {
        this.activePanel = value;
      }
    },
    clickNavigation(event) {
      if(this.$route.name != 'teamwork.diagrammatic' && this.$route.name != 'teamwork.concepts') {
        if(this.activePanel !== 1) {
          this.activePanel = 1;
        } else {
          this.$emit("clickNavigation");
        }
        this.closeConcept(event);
      }
    },
    closeConcept(event) {
      this.$emit("closeConcept", event);
    },
    navigateToProperty() {
      if(this.$route.name != 'teamwork.diagrammatic' && this.$route.name != 'teamwork.concepts') {
        if(this.checkActiveCollection) {
          this.activePanel = 3;
        }
        this.closeConcept();
      }
    },
    gotoConcept(event) {
      // if(this.activePanel !== 4) {
      //   this.$emit("closeConcept", event);
      // } else {
      //   this.$emit("gotoConcept", event);
      // }
      this.activePanel = 0;
      this.$router.push({ name: 'teamwork.concepts' });
    },
    gotoIntel(event) {
      // if(this.activePanel !== 4) {
      //   this.$emit("closeConcept", event);
      // } else {
      //   this.$emit("gotoConcept", event);
      // }
      this.activePanel = 0;
      this.$router.push({ name: 'teamwork.diagrammatic' });
    },
    gotoCanvas() {
      this.$router.push({ name: 'teamwork.canvas' });
    },
    toggleIsolation(item) {
      this.$emit("toggleIsolation", item);
    },
    intelChartIsolation(data, itemId, serial) {
      this.$emit("intelChartIsolation", data, itemId, serial);
    },
    setIntelGroup(data, serial) {
      this.$emit("setIntelGroup", data, serial);
    },
    removeIsolation(moduleId) {
      this.$emit("removeIsolation", moduleId);
    },
    moduleIsolation(moduleId) {
      this.$emit("moduleIsolation", moduleId);
    },
    removeIntelIsolation(data, serial) {
      this.$emit("removeIntelIsolation", data, serial);
    }
  },
  watch:{
    $route (to, from){
      if(to.name == 'teamwork.concepts' || to.name == 'teamwork.diagrammatic') {
        this.activePanel = 0;
      }

      if(to.name == 'teamwork.canvas') {
        this.activePanel = 1;
      }
    },
    activeLevelId (val) {
      this.navigateToProperty()
    }
  },
}
</script>
