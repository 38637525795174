<template>
  <div class="i-pl-15 i-pr-15 border-transparent relative mb-8" :style="{'width': getWidth(item)+'px', 'background': item.appearance === 0 ?  getBg(item.level, item) : 'transparent'}"
       :class="{
          'i-border-1 border-primary-one': checkSelected(item.id),
          'i-border-b-1 border-primary-one' : item.appearance === 2,
          'shadow-two' : item.appearance === 0,
          'rounded i-border-1' : item.appearance !== 2,
          'border-secondary-five' : (item.appearance === 1 || item.appearance === 2) && !checkSelected(item.id),
          'border-primary-one rounded' : checkSelected(item.id),
          'mr-8' : index + 1 !== getLevelData.length,
          'i-mr-15' : index + 1 === getLevelData.length,
          'i-mb-15' : item.child.length === 0 && item.level === 1,
          'level1 item-no-des': item.level === 1,
          'item-des single': item.level !== 1,
          'text-secondary-one textarea-secondary-one' : item.appearance !== 0,
        }"
        @click="setActiveData(item)">
    <div class="flex items-center" :class="{'item-no-des': item.level === 1, 'pt-2': item.level !== 1}">
      <h2 class="fs-14 fw-bold mr-1"
          v-if="activeBrainstormData.scenario_id && indexTitle">
        {{ item.index_code }}
      </h2>
      <input type="text" class="bg-transparent fw-700 w-full panzoom-exclude"
        v-model="item.title"
        :id="`l${item.level}_input_${item.id}`"
        @keyup="changedDataFromCollaboration(`l${item.level}_input_${item.id}`, item, item.title)"
        v-on:keyup.delete="removeItem($event, item)"
        @change="regularUpdate($event, item)">
    </div>
    <!-- expand feature -->
    <div class="absolute expanded-div rounded z-20 shadow-one bg-primary-three overflow-y-auto" v-show="expandedArray.find(el => el.id === item.id)">
      <div class="px-4 py-3 flex justify-start text-secondary-one relative">
        <text-feature :item="item"
                      :index-title="indexTitle"
                      v-if="getExpandStatus(item) === 'text'"
                      @changedDataFromCollaboration="changedDataFromCollaboration"
                      @regularUpdate="regularUpdate" />

        <chart-feature :item="item"
                       :module-properties="moduleProperties"
                       :module="module"
                       v-else-if="getExpandStatus(item) === 'chart'" />
      </div>
    </div>


    <div v-if="getExpandStatus(item) === 'text' || getExpandStatus(item) === 'chart'" class="_option_buttons absolute z-20 flex gap-2" >
      <button title="Menu" @click="menuHandler($event, item)">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
          <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
        </svg>        
      </button>
      <button @click="canDelete(project.cans) && $emit('deleteLevelItem', item)">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
        </svg>
      </button>
      <button @click="addItem(item)">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
        </svg>        
      </button>
    </div>

    <div v-if="getExpandStatus(item) === 'text' || getExpandStatus(item) === 'chart'" class="absolute expanded-icon z-20" @click="collpaseItem(item)">
      <img src="/images/icons/brainstormV2/cross.svg"
           alt="icon"
           class="cursor-pointer" />
    </div>
    <!-- expand feature  -->

    <div class="fs-12" v-if="item.level !== 1">
      <textarea class="w-full bg-transparent bld-content-area content-desc-height panzoom-exclude"
        placeholder="Add descriptive text"
        v-model="item.description"
        :id="`l${item.level}_text_${item.id}`"
        @keyup="changedDataFromCollaboration(`l${item.level}_text_`, item, item.description)"
        @input="regularUpdate($event, item)"></textarea>
    </div>

    <div class="flex">
      <child-create-box 
        v-if="activeData && activeData.id === item.id"
        :l1="l1"
        :l2="l2"
        :l3="l3"
        :l4="l4"
        :current="item"
        :module="module"
        @newRootChild="newRootChild"
        @newSubChild="newSubChild"
        @updateParent="updateParent" />

      
      <div class="absolute context-icon z-10 pt-1" v-show="activeData.id === item.id" @click="menuHandler($event, item)">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
          <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
        </svg>        
      </div>
      <div class="absolute delete-icon z-10 pt-1" v-show="activeData.id === item.id" @click="canDelete(project.cans) && $emit('deleteLevelItem', item)" >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
        </svg>
      </div>
      <div class="absolute expand-text-icon z-10 pt-1 panzoom-exclude" v-show="activeData.id === item.id" @click="expandItem(item, 'text')">
        <img src="/images/icons/brainstormV2/exp-text.svg"
             alt="icon"
             class="cursor-pointer panzoom-exclude" />
      </div>
      <div class="absolute expand-icon z-10 pt-1 panzoom-exclude" v-show="activeData.id === item.id" @click="expandItem(item, 'chart')">
        <img src="/images/icons/build/properties.svg"
             alt="icon"
             class="cursor-pointer panzoom-exclude" />
      </div>
    </div>
  </div>
</template>


<script setup>
  // import usePermission from '@/composable/usePermission.js'
  import usePermission from '@/components/teamwork/brainstorm/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
  import {cloneDeep} from "lodash";
  import {mapGetters} from "vuex";
  import ChildCreateBox from "@/components/brainstormv2/canvas/ChildCreateBox";
  import TextFeature from "@/components/brainstormv2/canvas/scenario/TextFeature";
  import ChartFeature from "@/components/brainstormv2/canvas/scenario/ChartFeature";

  export default {
    name: "VerticalItem",
    props: {
      item: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
      },
      module: {
        type: Object,
        required: true
      },
      indexTitle: {
        type: Boolean
      },
      description: {
        type: Boolean
      },
      appearance: {
        type: Number
      },
      isolatedModules: {
        type: Array
      },
      defaultColors: {
        type: Array
      },
      expandedArray: {
        type: Array
      },
      moduleProperties: {
        type: Array
      },
      l1: {
        type: Object
      },
      l2: {
        type: Object
      },
      l3: {
        type: Object
      },
      l4: {
        type: Object
      },
      parent: {
        type: Object
      }
    },
    components: {
      ChartFeature,
      TextFeature,
      ChildCreateBox
    },
    computed: {
      ...mapGetters({
        activeBrainstormData: "brainstormv1/activeBrainstormData",
        activeData: "programmatic/activeScenarioCollection",
        colorCodes: "global/colorCodes",
        activeModule: "build/navigatorModule",
        project: "project/project",
      }),
      getLevelData() {
        if(this.item.level === 1) {
          if(this.module) {
            return this.module.collections;
          }
        } else {
          return this.parent.child;
        }
        return [];
      },
      moduleSettings() {
        if(this.module && this.activeBrainstormData.scenario_id) {
          let settings = JSON.parse(this.module.collection_settings);
          if(settings) {
            return settings;
          }
          return {};
        }
        return {};
      },
    },
    methods: {
      addItem(item){
        let data = cloneDeep(item);
        data.project_id = this.$route.params.id;
        data.title = this.moduleSettings.collections[data.level].title;
        this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
          if(response && response.data && response.data.data && response.data.data.id) {
            this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
              this.$Progress.finish();
            });
            this.$emit("pushToIsolation", {
              module_id: this.module_id,
              id: response.data.data.id,
              parent_id: response.data.data.parent_id,
            });
          }
        });
      },
      getData(items) {
        let itemIndex = items.findIndex(data => data.root);
        if(itemIndex >= 0) {
          items.splice(itemIndex, 1);
        }

        let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
        if(moduleInIsolation && moduleInIsolation.isolate) {
          return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
        }
        return items.filter(data => !data.root);
      },
      getWidth(item) {
        let child = this.getData(item.child);
        let pos = this.checkPosition(child)
        let count = child.length
        let width = 0
        let margin = 32

        if (pos === 'v') {
          return width += 265
        }

        if (count > 0) {
          child.forEach(el => {
            width += this.getWidth(el)
          });
          width += margin*(count-1)
        }
        else {
          width += 265
        }
        return width
      },
      checkPosition(child) {
        let pos = 'v'
        child.forEach(el => {
          if (el.child.length > 0) {
            pos = 'h'
          }
        });
        return pos
      },
      getLevelWiseColorCode(level) {
        let indexLevel = level - 1;
        return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
      },
      getBg(level, item) {
        if(item && item.background_color) {
          return item.background_color;
        } else {
          if(this.activeBrainstormData) {
            let settings = this.moduleSettings;
            if(settings) {
              let collection = settings.collections[level];
              if(collection) {
                if(collection.custom_bg) {
                  return collection.custom_bg;
                }
                let colorCodes = this.getLevelWiseColorCode(level);
                return colorCodes[collection.color];
              }
            }
          } else {
            return this.defaultColors[level - 1];
          }
        }
        return this.defaultColors[level - 1];
      },
      checkSelected(id) {
        if(this.activeData.id === id) {
          return true;
        }
        return false;
      },
      setActiveData(item) {
        if(this.activeBrainstormData.scenario_id) {
          this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item)
          if (this.activeModule !== this.module.id) {
            this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", this.module.id)
          }
        }
      },
      menuHandler(e, item) {
        this.$emit("menuHandler", e, item);
      },
      changedDataFromCollaboration(initial, item, value) {
        this.$emit("changedDataFromCollaboration", initial, item, value);
      },
      removeItem(e, item) {
        this.$emit("removeItem", e, item);
      },
      regularUpdate(e, item) {
        this.$emit("regularUpdate", e,  item);
      },
      newRootChild() {
        this.$emit("newRootChild");
      },
      newSubChild(data) {
        this.$emit("newSubChild", data);
      },
      updateParent(data) {
        this.$emit("updateParent", data);
      },
      expandItem(item, partname) {
        this.$emit("expandItem", item, partname);
      },
      collpaseItem(item) {
        this.$emit("collpaseItem", item);
      },
      getExpandStatus(item) {
        this.setExpandedData(item)
        let status = ''
        let expand = this.expandedArray.find(el => el.id === item.id)
        if (expand) {
          status = expand.partname
        }
        return status
      },
      setExpandedData(item) {
        let data = this.expandedArray.find(el => el.id === item.id)
        if(data) {
          item.choosed_property_id = item.choosed_property_id ? item.choosed_property_id : data.prop;
          item.choosed_property_param_id = item.choosed_property_param_id ? item.choosed_property_param_id : data.param;
        }
      },
    }
  }
</script>

<style scoped>
._option_buttons{
  position: absolute;
  left: 15px;
  top: 248px;
}
.context-icon{
  bottom: -25px;
  left: 163px;
  cursor: pointer;
}
.delete-icon{
  bottom: -25px;
  left: 187px;
  cursor: pointer;
}
.default-width {
  width: 265px;
}
.item-des {
  height: 132px;
}
.item-no-des {
  height: 50px;
}
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.content-desc-height {
  height: 90px;
}
.expanded-div {
  height: 270px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  left: 250px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  left: 212px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  top: 252px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item .bld-content-area {
  height: 250px;
}
.expand-item:first-child {
  width: 235px;
  margin-right: 28px
}
.expand-item:nth-child(2) {
  width: 270px;
}
</style>
