<template>
  <div>
    <!--right horizontal line-->
    <div style="position: absolute; width: 11px; height: 1px; background: #8D8D8D; top: 51%; right: -11px" v-if="hasChild"></div>

    <!--left circle-->
    <div style="position: absolute; width: 12px; height: 12px; border-radius: 50%; border: 1px solid #8D8D8D; top: 47%; left: -22px" v-if="parent"></div>

    <!--left horizontal line-->
    <div style="position: absolute; width: 10px; height: 1px; background: #8D8D8D; top: 51%; left: -10px" v-if="parent"></div>

    <!--left vertical line-->
    <div style="position: absolute; width: 1px; background: #8D8D8D; bottom: 69px; left: -17px"
         :style="{'height': getVerticalLineHeight() + 'px'}"
         v-if="parent && index > 0"></div>
  </div>
</template>

<script>
export default {
  name: "Indicator",
  props: ['hasChild', 'parent', 'index', 'previousChildCount'],
  methods: {
    getVerticalLineHeight() {
      if(this.previousChildCount > 1) {
        return (this.previousChildCount * 132) + (32 * (this.previousChildCount - 1)) + 21;
      } else {
        return 153;
      }
    }
  }
}
</script>

<style scoped>

</style>
