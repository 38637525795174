import { ref } from 'vue';
import axios from 'axios';
import useLevel from '@/components/teamwork/brainstorm/level';

const stickyNotes = ref([])

const {removeLevel} = useLevel()
export function useSticky() {
    async function createSticky(data)
    {
        return await axios.post(`brainstorms/sticky-notes`, data);
    }
    
    async function updateSticky(noteId, data)
    {
        return await axios.post(`brainstorms/sticky-notes/${noteId}/update`, data);
    }

    const getSticky = (brainstormId) => {
        return axios.get(`brainstorms/sticky-notes/${brainstormId}`).then(({data}) => data.data);
    }

    const setStickyNotes = (data) => {
        stickyNotes.value = data
    }

    const removeDeletedSticky = (id) => {
        stickyNotes.value = stickyNotes.value.filter(note => note.id != id);
    }

    async function deleteSticky(note_id)
    {
        return await axios.delete(`brainstorms/sticky-notes/${note_id}`);
    }

    function stickyConvertToData(data)
    {
        return axios.post(`brainstorms/sticky-notes/convert-to-data`, data);
    }
    
    function dataConvertToSticky(data)
    {
        removeLevel(data.collection_id)
        return axios.post(`brainstorms/sticky-notes/convert-to-sticky`, data);
    }

    const hasCoordination = (note) => {
        if (!note?.coordination) {
          return false;
        }
        return note?.coordination?.x != null && note?.coordination?.y != null;
    };

    return {
        createSticky,
        getSticky,
        updateSticky,
        deleteSticky,
        stickyConvertToData,
        stickyNotes,
        setStickyNotes,
        removeDeletedSticky,
        dataConvertToSticky,
        hasCoordination
    };
}