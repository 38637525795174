<template>
  <div class="relative">
    <div class="flex chart-top-content" :class="{'double-logic-chart': activeStyle === 2, 'double-ml': serial === 2}" v-if="intelItem && intelItems && intelItems.length > 0">
      <h4 class="fs-14 fw-700 text-primary-one">Diagram Canvas {{ serial }}: <span v-if="intelModule && intelModule.title">{{ intelModule.title }}</span></h4>
    </div>
    <div class="intel-chart-div flex relative" v-if="intelModule && intelModule.id">
      <div class="flex flex-col vertical-line-height justify-between rotate-180 i-pb-36 i-pt-36"
           :class="{'bar-chart-line-height': activeStyle !== 1}"
           v-if="intelItem && intelItem.definitions && serial === 1">
        <p class="vertical-lr primary fw-600" v-for="(item, index) in getDefinitions" :key="index">{{ item }}</p>
      </div>
<!--      <intel-bar-chart v-if="activeStyle !== 1"-->
<!--                       :class="{'side-by-side': activeStyle === 2, 'regular': activeStyle !== 2}"-->
<!--                       :chartData="chartData"-->
<!--                       :breakTheTitle="breakTheTitle"-->
<!--                       @loadSubLevelData="loadSubLevelData"-->
<!--                       :ref="'intel_bar_chart'" />-->
      <custom-bar-chart v-if="activeStyle !== 1"
                        :parameters="parameters"
                        :chartData="fullDataSet"
                        :hoveredItem="hoveredItem"
                        :parametersSelectedWithOverlay="parametersSelectedWithOverlay"
                        :intelChartColor="intelChartColor"
                        :intelLineColor="intelLineColor"
                        @setMouseOverPoint="setMouseOverPoint"
                        @loadSubLevelData="loadSubLevelData" />
      <intel-timeline-chart v-if="activeStyle === 1"
                            class="regular"
                            :chartData="getTimelineChartData()"
                            @loadSubLevelData="loadSubLevelData"
                            :ref="'intel_timeline_chart'" />

      <div class="absolute parameters-div" :class="{'double-parameters-div': serial === 2, 'ellipse w-1/2': activeStyle === 2}" v-if="activeStyle !== 1">
        <div class="flex relative" v-click-outside="hideParameterDropdown">
          <!--Parameters-->
          <div class="flex items-center relative" v-for="(originalParameter, pIndex) in parameters" :key="pIndex" :class="{'ml-4': pIndex > 0}">
            <div @click="setForOverlayParameter($event, originalParameter)" class="parameter-color rounded-sm shadow-two bg-primary-four border-primary-four mr-2 cursor-pointer" :style="{'background': parameterColors[pIndex]}"></div>
            <h4 class="fs-12">{{ getParameter(originalParameter) }}</h4>

            <!--Overlay-->
            <div class="absolute overlay-features" v-if="clickedForOverlayParameter && clickedForOverlayParameter === originalParameter">
              <div class="bg-primary-three shadow-two root-param-opt">
                <div class="flex py-1 px-2 cursor-pointer" @click="paramBackground = true, overlay = false">
                  <h4 class="fs-12 fw-600">Color</h4>
                </div>
                <div class="relative">
                  <div class="py-1 px-2 cursor-pointer relative">
                    <div class="flex" @click="toggleOverlayFeature()">
                      <h4 class="fs-12 fw-600">Overlay</h4>
                    </div>
                    <div class="absolute bg-primary-three shadow-two prop-div-in-ol" v-if="overlay">
                      <div class="flex justify-between cursor-pointer py-1 px-2 whitespace-no-wrap relative"
                           v-for="(property, oPIndex) in intelModule.properties"
                           :key="oPIndex"
                           @click="togglePropertyVisibility(property.id)">
                        <div class="flex">
                          <h4 class="fs-12">{{ property.title }}</h4>
                          <!--<img src="/images/icons/build/arrow_side_inactive.svg" alt="icon" />-->
                        </div>

                        <div class="bg-primary-three shadow-two absolute prop-param-ol" v-if="propertyIdInOverlay && propertyIdInOverlay === property.id">
                          <div class="flex items-center cursor-pointer py-1 px-2 whitespace-no-wrap text-secondary-one"
                               v-for="(parameter, pMIndex) in property.property_collections"
                               :key="pMIndex"
                               @click="setOverlayParameter(parameter, pIndex)">
                            <div class="parameter-box rounded-sm border-secondary-one mr-2 i-border-1" :class="{'bg-primary-one': checkInOverlayParameter(parameter, pIndex)}"></div>
                            <h4 class="fs-12 opacity-50" :class="{'text-primary-one opacity-100': checkInOverlayParameter(parameter, pIndex)}">{{ parameter.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div v-if="overlay" class="relative text-secondary-one fs-13 fw-600">-->
<!--                <div class="bg-primary-three shadow-two" v-if="!showOverlayProperty">-->
<!--                  <div class="flex justify-between cursor-pointer py-1 px-2 whitespace-no-wrap"-->
<!--                       v-for="(property, pIndex) in intelModule.properties"-->
<!--                       :key="pIndex"-->
<!--                       @click="overlayProperty = property, showOverlayProperty = !showOverlayProperty, showOverlayParameter = true">-->
<!--                    <h4 class="fs-12">{{ property.title }}</h4>-->
<!--                    &lt;!&ndash;              <img src="/images/icons/build/arrow_side_inactive.svg" alt="icon" />&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="bg-primary-three shadow-two" v-if="showOverlayParameter">-->
<!--                  <div class="flex items-center cursor-pointer py-1 px-2 whitespace-no-wrap text-secondary-one"-->
<!--                       v-for="(parameter, pMIndex) in overlayProperty.property_collections"-->
<!--                       :key="pMIndex"-->
<!--                       @click="setOverlayParameter(parameter)">-->
<!--                    <div class="parameter-box rounded-sm border-secondary-one mr-2 i-border-1" :class="{'bg-primary-one': checkInOverlayParameter(parameter)}"></div>-->
<!--                    <h4 class="fs-12 opacity-50" :class="{'text-primary-one opacity-100': checkInOverlayParameter(parameter)}">{{ parameter.title }}</h4>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <!--Overlay-->
          </div>
          <!--Parameters-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
// import IntelBarChart from "./charts/IntelBarChart";
import IntelTimelineChart from "./charts/IntelTimelineEChart";
import CustomBarChart from "./charts/CustomBarChart";

export default {
  name: "IntelLayout",
  components: {CustomBarChart, IntelTimelineChart},
  props: ['intelModule', 'parameters', 'serial', 'intelChartColor', 'intelLineColor'],
  created() {
    // this.setChartData();
  },
  data() {
    return {
      showOverlayProperty: false,
      showOverlayParameter: false,
      overlayProperty: null,
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
      parameterColors: ["#2B80AD", "#65A1C0", "#3DB692", "#FE5656", "#1C1C1D", "#2B80AD", "#65A1C0", "#3DB692", "#FE5656", "#1C1C1D"],
      chartData: {
        labels: [],
        datasets: []
      },
      opacities: [1, 0.75, 0.5, 0.25],
      overlayParameterColors: ["#C9C9C9", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D", "#8D8D8D"],
      intelItems: [],
      activeIndex: null,
      lastActivatedItem: null,
      activeLevel: null,
      stack: [
        { data: [] },
        { data: [] },
        { data: [] },
        { data: [] },
      ],
      overlayParameters: [],
      fullDataSet: [],
      hoveredItem: null,
      clickedForOverlayParameter: null,
      paramBackground: false,
      overlay: false,
      propertyIdInOverlay: null,
      parametersSelectedWithOverlay: []
    }
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      intelItem: "intel/intelItem",
      colorCodes: "global/colorCodes",
      assessmentData: "intel/assessmentData"
    }),
    getDefinitions() {
      if(this.intelItem && this.intelItem.definitions && this.intelItem.definitions.length > 0 && this.intelItem.definitions[0].length > 0) {
        return this.intelItem.definitions[0];
      }
      return [];
    },
    moduleSettings() {
      if(this.activeBrainstormData) {
        if(this.activeBrainstormData.scenario_id) {
          let settings = JSON.parse(this.intelModule.collection_settings);
          if(settings) {
            return settings;
          }
          return {};
        }
      }
      return {};
    },
    activeStyle() {
      return this.intelItem && this.intelItem.style ? this.intelItem.style : 0;
    },
    breakTheTitle() {
      let totalBars = this.parameters.length * this.intelItems.length;
      return totalBars > 20;
    }
  },
  watch: {
    intelModule: function (val) {
      this.stack = [
        { data: [] },
        { data: [] },
        { data: [] },
        { data: [] },
      ];
      this.activeLevel = null;
      this.lastActivatedItem = null;
      this.intelItems = this.intelModule && this.intelModule.collections ? this.intelModule.collections : [];
      this.setChartData();
    },
    intelItem: function(val) {
      this.intelItems = this.intelModule && this.intelModule.collections ? this.intelModule.collections : [];
      this.setChartData();
    },
    parameters: function (val) {
      if(this.serial === 2) {
        this.setChartData();
      }
    }
  },
  methods: {
    setForOverlayParameter(event, parameter) {
      if(this.clickedForOverlayParameter === parameter) {
        this.clickedForOverlayParameter = null;
        this.overlay = false;
        this.paramBackground = false;
        this.propertyIdInOverlay = null;
      } else {
        this.clickedForOverlayParameter = parameter;
      }
    },
    hideParameterDropdown() {
      this.overlay = false;
      this.paramBackground = false;
      this.clickedForOverlayParameter = null;
    },
    setOverlayParameter(parameter, paramIndex) {
      let data = {
        index: paramIndex,
        ref_param: this.parameters[paramIndex],
        parameter: parameter
      }
      let pIndex = this.parametersSelectedWithOverlay.findIndex(item => item.index === paramIndex);
      if(pIndex >= 0) {
        this.parametersSelectedWithOverlay[pIndex] = data;
      } else {
        this.parametersSelectedWithOverlay.push(data);
      }

      let overlayData = new Array(this.parameters.length);
      this.parametersSelectedWithOverlay.forEach((item, index) => {
        overlayData[item.index] = item.parameter;
      });
      this.overlayParameters = overlayData;
      this.setChartData();
    },
    setOverlayParamOpt() {
      this.paramBackground = false;
      this.overlay = true;
      this.paramBackground = false;
      this.showOverlayProperty = true;
    },
    toggleOverlayFeature() {
      this.paramBackground = false;
      this.overlay = !this.overlay;
    },
    togglePropertyVisibility(propertyId) {
      if(propertyId === this.propertyIdInOverlay) {
        this.propertyIdInOverlay = null;
      } else {
        this.propertyIdInOverlay = propertyId;
      }
    },
    checkInOverlayParameter(parameter, paramIndex) {
      let pIndex = this.parametersSelectedWithOverlay.findIndex(item => item.index === paramIndex && item.parameter && parseInt(item.parameter.id) === parseInt(parameter.id));
      return pIndex >= 0;
    },
    setIsolatedData(data) {
      if(data.length > 0) {
        this.intelItems = data;
      } else {
        this.intelItems = this.intelModule.collections;
      }

      this.stack = [
        { data: [] },
        { data: [] },
        { data: [] },
        { data: [] },
      ];
      this.activeLevel = null;
      this.lastActivatedItem = null;

      this.setChartData();
    },
    setIntelGroupData(data) {
      this.intelItems = data;
      this.stack = [
        { data: [] },
        { data: [] },
        { data: [] },
        { data: [] },
      ];
      this.activeLevel = null;
      this.lastActivatedItem = null;
    },
    setRootCollections() {
      this.stack= [];
      if(this.intelModule && this.intelModule.collections && this.intelModule.collections.length > 0) {
        this.intelItems = this.intelModule.collections;
        this.stack = [
          { data: [] },
          { data: [] },
          { data: [] },
          { data: [] },
        ];
        this.activeLevel = null;
        this.lastActivatedItem = null;
      } else {
        this.intelItems = [];
      }
    },
    loadSubLevelData(index) {
      let willActiveData = this.intelItems[index];
      this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", willActiveData)
      if(this.activeLevel && this.activeLevel >= willActiveData.level) {
        this.intelItems = this.stack[willActiveData.level - 1].data;
        for (let i = willActiveData.level - 1; i < 4; i++) {
          this.stack[i].data = [];
        }

        this.lastActivatedItem = null;
        if(willActiveData.level === 1) {
          this.intelItems = this.intelModule.collections;
          this.activeLevel = null;
        } else {
          this.activeLevel = willActiveData.level;
          if(this.activeLevel === willActiveData.level) {
            this.activeLevel = this.activeLevel - 1;
          }
        }
      } else {
        this.stack[willActiveData.level - 1].data = this.intelItems;
        this.setNextLevelData(index);
        this.lastActivatedItem = willActiveData;

        if(this.activeLevel === willActiveData.level) {
          this.activeLevel = null;
        } else {
          this.activeLevel = willActiveData.level;
        }
      }

      this.setChartData();
    },
    setNextLevelData(index) {
      let oldIntelItems = this.intelItems;
      this.activeIndex = index;

      if(this.lastActivatedItem && this.intelItems && this.intelItems.length > 0 && this.lastActivatedItem.id === this.intelItems[index].id) {
        // this.lastActivatedItem = this.intelItems[index];
      } else {
        if(this.intelItems && this.intelItems.length > 0 && this.intelItems[index].level > 1) {
          let item = this.intelItems[index];
          let parentSlot = this.loadSubLevelParent(item.level - 1);
          parentSlot.push(oldIntelItems[index]);
          this.intelItems = [];
          this.intelItems = [...parentSlot, ...oldIntelItems[index].child];
        } else {
          this.intelItems = [];
          this.intelItems = [oldIntelItems[index]];
          this.intelItems = [...this.intelItems, ...this.intelItems[0].child];
        }
      }
    },
    loadSubLevelParent(index) {
      return this.intelItems.slice(0, (index + 1));
    },
    setChartData() {
      if(this.intelModule) {
        const self = this;
        self.fullDataSet = [];
        let dataItems = this.intelItems ? this.intelItems : self.intelModule.collections;
        let moduleIndex = dataItems.findIndex(data => data.root);
        if(moduleIndex >= 0) {
          dataItems.splice(moduleIndex, 1);
        }

        // this.overlayParameters.forEach((ovl, ovlIndex) => {
        //   console.log(ovl)
        // });
        let overlayParameterIds = this.overlayParameters.map(data => data.id);

        dataItems.forEach(item => {
          delete item.parameters;
          delete item.overlay_parameters;
          let properties = item.properties.slice();
          let filteredParameters = properties.filter(parameter => self.parameters.includes(parameter.property_collection_id));

          item.parameters = [];
          self.parameters.forEach((param, pIndex) => {
            let objData = filteredParameters.find(fData => fData.property_collection_id === param);
            objData.parameter_title = self.getParameter(param);
            objData.bg = self.parameterColors[pIndex]
            item.parameters.push(objData);
          });

          // old process
          // item.parameters.forEach((param, pIndex) => {
          //   param.parameter_title = self.getParameter(param.property_collection_id);
          //   param.bg = self.parameterColors[pIndex]
          // });

          let oProperties = item.properties.slice();
          let overlayFilteredParameters = oProperties.filter(parameter => overlayParameterIds.includes(parameter.property_collection_id));
          item.overlay_parameters = [];
          overlayParameterIds.forEach((param, pIndex) => {
            let objOverlayData = overlayFilteredParameters.find(fData => fData.property_collection_id === param);
            objOverlayData.parameter_title = self.getParameter(param);
            objOverlayData.o_bg = self.overlayParameterColors[pIndex];
            objOverlayData.ref_param = self.parametersSelectedWithOverlay.find(oP => oP.parameter.id === param).ref_param;
            item.overlay_parameters.push(objOverlayData);
          });

          // old process
          // item.overlay_parameters.forEach((param, pIndex) => {
          //   param.parameter_title = self.getParameter(param.property_collection_id);
          //   param.o_bg = self.overlayParameterColors[pIndex]
          // });
        });

        // module data
        let moduleObj = {
          id: self.intelModule.id,
          title: self.intelModule.title,
          index_code: '',
          parameters: [],
          overlay_parameters: [],
          root: true,
          level: 0
        };
        let moduleIndexInRoot = self.intelModule.collections.findIndex(data => data.root);
        if(moduleIndexInRoot >= 0) {
          self.intelModule.collections.splice(moduleIndexInRoot, 1);
        }

        // original parameters
        self.parameters.forEach((param, pIndex) => {
          let paramValue = 0;
          self.intelModule.collections.forEach(item => {
            paramValue += item.properties.find(prop => parseInt(prop.property_collection_id) === parseInt(param)).sample_value;
          });

          moduleObj.parameters.push({
            property_collection_id: param,
            sample_value: paramValue / self.intelModule.collections.length,
            parameter_title: self.getParameter(param),
            bg: self.parameterColors[pIndex]
          });
        });

        // overlay parameters
        overlayParameterIds.forEach((param, pIndex) => {
          let paramValue = 0;
          self.intelModule.collections.forEach(item => {
            paramValue += item.properties.find(prop => parseInt(prop.property_collection_id) === parseInt(param)).sample_value;
          });

          moduleObj.overlay_parameters.push({
            property_collection_id: param,
            sample_value: paramValue / self.intelModule.collections.length,
            parameter_title: self.overlayParameters.find(data => data && data.id && data.id === param) ? self.overlayParameters.find(data => data && data.id && data.id === param).title : '',
            o_bg: self.overlayParameterColors[pIndex],
            ref_param: self.parametersSelectedWithOverlay.find(oP => oP.parameter.id === param).ref_param
          });
        });

        this.fullDataSet = self.intelItems;
        this.fullDataSet.unshift(moduleObj);
      }
    },
    chartJSData() {
      self.chartData.labels = [];
      self.chartData.datasets = [];
      self.chartData.indexing = [];
      let dataCollections = self.intelItems;
      let data = new Array(self.parameters.length);

      // make the array with parameters for datasets
      for(let i = 0; i < self.parameters.length; i++) {
        data[i] = {
          label: self.getParameter(self.parameters[i]),
          backgroundColor: [self.parameterColors[i]],
          data: [], // sample value of each item will place here for that parameter,
          borderColor: [],
          borderWidth: 2,
          borderRadius: 15,
          fill: true,
          borderSkipped: false,
          // type: "bar",
          // stack: i > 1 ? '1' : '2'
        };
      }

      let labels = [];
      let indexing = [];

      dataCollections.forEach((item, dataIndex) => {
        labels.push(item.title);
        indexing.push(item.index_code);

        // group the data
        self.parameters.forEach((prop, propIndex) => {
          let propParam = item.properties.find(parameter => parseInt(parameter.property_collection_id) === parseInt(prop));
          if(propParam) {
            data[propIndex].data.push(parseInt(propParam.sample_value));
            data[propIndex].item = item;
            data[propIndex].backgroundColor.push(self.addOpacityToBg(self.parameterColors[propIndex], 1));
            data[propIndex].borderColor.push(self.getBg(item.level, item));
          }
        });
      });

      self.chartData.labels = labels;
      self.chartData.datasets = data;
      self.chartData.indexing = indexing;
      if (this.activeStyle !== 1) {
        self.$refs.intel_bar_chart.renderBarChart();
      }
      else {
        self.$refs.intel_timeline_chart.getOption();
      }
    },
    getParameter(parameterId) {
      let propertyData = this.intelModule.properties.find(property => property.property_collections.find(param => parseInt(param.id) === parseInt(parameterId)));
      if(propertyData && propertyData.property_collections && propertyData.property_collections.length > 0) {
        let parameter = propertyData.property_collections.find(param => parseInt(param.id) === parseInt(parameterId));
        if(parameter) {
          return parameter.title;
        }
      }
      return "";
    },
    addOpacityToBg(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    getBg(level, item) {
      if(item && item.background_color) {
        return item.background_color;
      } else {
        if(this.activeBrainstormData.module_id || this.activeBrainstormData.scenario_id) {
          let settings = this.moduleSettings;
          if(settings) {
            let collection = settings.collections[level];
            if(collection) {
              if(collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    setMouseOverPoint(item) {
      this.hoveredItem = item;
    },
    getTimelineChartData() {
      // let data = [];
      // this.assessmentData.forEach(asdata => {
      //   let single = []
      //   asdata.assessment_properties.forEach(el => {
      //     let obj =  {
      //       name: asdata.title,
      //       value: [el.date, el.value]
      //     };
      //     single.push(obj)
      //   });
      //   data.push(single)
      // });
      // // console.log(data);
      // return data
      let data = [];
      this.assessmentData.forEach(asdata => {
        let obj = {};
        let itemData = []
        obj.name = asdata.title;
        obj.type = 'line';
        obj.showSymbol = false;
        asdata.assessment_properties.forEach(el => {
          let itemObj =  {
            // name: 'koi tui',
            value: [el.date, el.value]
          };
          itemData.push(itemObj)
        });
        obj.data = itemData
        data.push(obj)
      });
      // console.log(data);
      return data
    }
  },
  updated() {
    // this.setChartData();
  },
  mounted() {
    // this.setChartData();
  }
}
</script>

<style scoped>
.intel-chart-div {
  /*background: #FFFFFF;*/
  height: 750px;
  /*padding: 0 20px;*/
}
.chart-top-content {
  margin-top: 12px;
  margin-bottom: 20px;
  margin-left: 46px;
}
.vertical-line-height {
  height: 735px;
  margin-right: 10px;
}
.bar-chart-line-height {
  height: 720px;
  margin-right: 10px;
  margin-top: 35px;
}
.vertical-lr {
  writing-mode: vertical-lr;
}
.regular {
  height: 740px !important;
  width: 1420px !important;
}
.side-by-side {
  height: 740px !important;
  width: 700px !important;
}
.overlay-features {
  top: 18px;
  left: 0;
  z-index: 99999;
  white-space: nowrap;
}
.double-overlay-features {
  right: 0;
}
.overlay-prop {
  right: 0;
  top: 28px;
  min-width: 102px;
  z-index: 9999;
}
.parameter-box {
  width: 10px;
  height: 10px;
}
.overlay-active-parameter {
  width: 15px;
  height: 15px;
}
.parameters-div {
  left: 47px;
  top: -6px;
}
.double-parameters-div {
  left: 12px;
}
.parameter-color {
  width: 30px;
  height: 15px;
}
.double-ml {
  margin-left: 12px;
}
.root-param-opt {
  min-width: 120px;
}
.prop-div-in-ol {
  top: 4px;
  left: 120px;
  z-index: 99999;
}
.prop-param-ol {
  left: 80px;
  top: 0;
  z-index: 999999;
}
</style>
