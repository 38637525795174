<template>
  <div class="expand-item">
    <div class="flex">
      <h2 class="fs-14 fw-bold mr-1" v-if="indexTitle">{{ item.index_code }}</h2>
      <input type="text"
             class="bg-transparent fs-14 fw-bold w-full ellipse panzoom-exclude"
             v-model="item.title"
             :id="'item_input_' + item.id"
             @keyup="changedDataFromCollaboration('item_input_', item, item.title)"
             @input="regularUpdate($event, item)"
             :disabled="!canEdit(project.cans)">
    </div>
    <textarea class="bg-transparent fs-12 w-full content-desc-height bld-content-area item-textarea panzoom-exclude"
              v-model="item.description"
              :id="'item_text_' + item.id"
              @keyup="changedDataFromCollaboration('item_text_', item, item.description)"
              placeholder="Add descriptive text"
              @input="regularUpdate($event, item)"
              :disabled="!canEdit(project.cans)"></textarea>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
export default {
  name: "TextFeature",
  props: {
    item: {
      type: Object,
      required: true
    },
    indexTitle: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      project: "project/project"
    })
  },
  methods: {
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    }
  }
}
</script>

<style scoped>
.item-textarea {
  height: 210px;
}
</style>
