<template>
  <div class="fs-14" :class="appearance === 0 ? 'text-white textarea-primary-three' : 'text-secondary-one textarea-secondary-one'" @click="closeMenuHandler($event)">

    <!-- all level box container  -->
    <div class="flex">
      <!-- level-1 loop  -->
      <div v-for="(l1item, l1index) in getData(getLevelData)" :key="l1index">
        <div class="flex">
          <vertical-item :item="l1item"
                         :index="l1index"
                         :module="module"
                         :isolated-modules="isolatedModules"
                         :context-module="contextModule"
                         :index-title="indexTitle"
                         :description="description"
                         :appearance="appearance"
                         :default-colors="defaultColors"
                         :expanded-array="expandedArray"
                         :module-properties="getModuleProperties()"
                         :l1="l1item"
                         :parent="module"
                         @changedDataFromCollaboration="changedDataFromCollaboration"
                         @removeItem="removeItem"
                         @deleteLevelItem="deleteLevelItem"
                         @regularUpdate="regularUpdate"
                         @expandItem="expandItem"
                         @collpaseItem="collpaseItem"
                         @menuHandler="menuHandler"
                         @newRootChild="newRootChild"
                         @newSubChild="newSubChild"
                         @updateParent="updateParent" />
        </div>

        <!-- level2 box container -->
        <div :class="checkPosition(l1item.child) === 'h' ? 'flex' : 'flex-col'">
          <!-- level 2 loop  -->
          <div v-for="(l2item, l2index) in getData(l1item.child)" :key="l2index">
            <vertical-item :item="l2item"
                           :index="l2index"
                           :module="module"
                           :isolated-modules="isolatedModules"
                           :context-module="contextModule"
                           :index-title="indexTitle"
                           :description="description"
                           :appearance="appearance"
                           :default-colors="defaultColors"
                           :expanded-array="expandedArray"
                           :module-properties="getModuleProperties()"
                           :l1="l1item"
                           :l2="l2item"
                           :parent="l1item"
                           @changedDataFromCollaboration="changedDataFromCollaboration"
                           @removeItem="removeItem"
                           @deleteLevelItem="deleteLevelItem"
                           @regularUpdate="regularUpdate"
                           @expandItem="expandItem"
                           @collpaseItem="collpaseItem"
                           @menuHandler="menuHandler"
                           @newRootChild="newRootChild"
                           @newSubChild="newSubChild"
                           @updateParent="updateParent" />

            <!-- level3 box container -->
            <div :class="checkPosition(l2item.child) === 'h' ? 'flex' : 'flex-col'">
              <!-- level 3 loop  -->
              <div v-for="(l3item, l3index) in getData(l2item.child)" :key="l3index">
                <vertical-item :item="l3item"
                               :index="l3index"
                               :module="module"
                               :isolated-modules="isolatedModules"
                               :context-module="contextModule"
                               :index-title="indexTitle"
                               :description="description"
                               :appearance="appearance"
                               :default-colors="defaultColors"
                               :expanded-array="expandedArray"
                               :module-properties="getModuleProperties()"
                               :l1="l1item"
                               :l2="l2item"
                               :l3="l3item"
                               :parent="l2item"
                               @changedDataFromCollaboration="changedDataFromCollaboration"
                               @removeItem="removeItem"
                               @deleteLevelItem="deleteLevelItem"
                               @regularUpdate="regularUpdate"
                               @expandItem="expandItem"
                               @collpaseItem="collpaseItem"
                               @menuHandler="menuHandler"
                               @newRootChild="newRootChild"
                               @newSubChild="newSubChild"
                               @updateParent="updateParent" />

                <!-- level4 box container -->
                <div class="flex-col">
                  <!-- level 4 loop -->
                  <div v-for="(l4item, l4index) in getData(l3item.child)" :key="l4index">
                    <vertical-item :item="l4item"
                                   :index="l4index"
                                   :module="module"
                                   :isolated-modules="isolatedModules"
                                   :context-module="contextModule"
                                   :index-title="indexTitle"
                                   :description="description"
                                   :appearance="appearance"
                                   :default-colors="defaultColors"
                                   :expanded-array="expandedArray"
                                   :module-properties="getModuleProperties()"
                                   :l1="l1item"
                                   :l2="l2item"
                                   :l3="l3item"
                                   :l4="l4item"
                                   :parent="l3item"
                                   @changedDataFromCollaboration="changedDataFromCollaboration"
                                   @removeItem="removeItem"
                                   @deleteLevelItem="deleteLevelItem"
                                   @regularUpdate="regularUpdate"
                                   @expandItem="expandItem"
                                   @collpaseItem="collpaseItem"
                                   @menuHandler="menuHandler"
                                   @newRootChild="newRootChild"
                                   @newSubChild="newSubChild"
                                   @updateParent="updateParent" />
                  </div>
                </div>
                <!-- level4 box container -->
              </div>
            </div>
            <!-- level3 box container -->
          </div>
        </div>
        <!-- level2 box container -->
      </div>
    </div>
    <!-- all level box container  -->
    <context-menu
      :item="contextmenuItem"
      :menu="menu"
      :id="'context-menu-'+module.id" v-show="contextmenu" class="absolute"
      @appearance="applyAppearnce"
      @appearanceSelf="applyAppearanceSelf" @indexTitle="applyIndexTitle"
      @background="applyBackground"
      @description="applyDescription"
      @addNew="addNew"
      @deleteLevelItem="deleteLevelItem" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChildCreateBox from "./ChildCreateBox";
import ContextMenu from './CntxtMenu';
import Property from './ScenarioItemProperty';
import $ from "jquery";
import VerticalItem from "@/components/brainstormv2/canvas/scenario/VrScreen/VerticalItem";
import {brainstormContentSharing} from "@/composable/socket/brainstormContentSharing";
const { shareContentUpdating, shareContentApi, socket } = brainstormContentSharing();
import {uniqBy} from "lodash";
export default {
  name: "ScenarioVrCanvas",
  props: ['module', 'isolatedModules', 'contextModule'],
  components: {VerticalItem, ChildCreateBox, ContextMenu, Property},
  data() {
    return {
      flatten: [],
      defaultColors: ['#2B80AD', '#3D6A83', '#6A7881', '#818487'],
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      flippedArray: [],
      expandedArray: [],
      menu: {
        description: true
      }
    }
  },
  computed: {
    ...mapGetters({
      dataset: "brainstormv1/activeBrainstormData",
      activeData: "programmatic/activeScenarioCollection",
      colorCodes: "global/colorCodes",
      activeModule: "build/navigatorModule",
    }),
    getLevelData() {
      if(this.module) {
        return this.module.collections;
      }
      return [];
    },
    moduleSettings() {
      if(this.module && this.dataset.scenario_id) {
        let settings = JSON.parse(this.module.collection_settings);
        if(settings) {
          return settings;
        }
        return {};
      }
      return {};
    },
    isolate() {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      return !!(moduleInIsolation && moduleInIsolation.isolate);
    }
  },
  watch: {
    module: function(val) {
      this.setFlattenArray(module.collections);
    }
  },
  methods: {
    setFlattenArray(items) {
      this.flatten = this.getFlattenArray(items);
    },
    getFlattenArray(items) {
      let flatten = (arr) => {
        let data = [];
        if(!arr) {
          return
        }
        arr.forEach(recursiveFlatten);
        function recursiveFlatten(item) {
          data.push(item);
          if (item.child && item.child.length > 0) {
            item.child.forEach((child) => {
              recursiveFlatten(child);
            });
          }
        }
        return data;
      }
      return uniqBy(flatten(items), 'id')
    },
    checkPosition(child) {
      let pos = 'v'
      child.forEach(el => {
        if (el.child.length > 0) {
          pos = 'h'
        }
      });
      return pos
    },
    newRootChild() {
      let l1Items = this.getLevelData;
      let moduleId = this.module.id;
      let order = 1;
      if(l1Items.length > 0) {
        order = l1Items[l1Items.length - 1].order;
      }
      this.$emit("newRootChild", moduleId, order);
    },
    newSubChild(data) {
      data.module_id = this.module.id;
      this.$emit("newSubChild", data);
    },
    updateParent(data) {
      this.$Progress.start();
      data.brainstorm_id = this.$route.params.brainstorm_id;
      if(this.dataset.scenario_id) {
        this.$store.dispatch("brainstormv1/scenarioParentUpdate", data).then(response => {
          this.$Progress.finish();
        });
      } else {
        if(this.dataset.module_id) {
          this.$store.dispatch("brainstormv1/buildParentUpdate", data).then(response => {
            this.$Progress.finish();
          });
        } else {
          this.$store.dispatch("brainstormv1/regularUpdate", data).then(response => {
            this.$Progress.finish();
          });
        }
      }
    },
    regularUpdate(e, obj) {
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          scenario_id: this.dataset.scenario_id,
          id: obj.id
        };
        if(this.dataset.scenario_id) {
          this.$store.dispatch("programmatic/updateCollection", item);
        }

        shareContentUpdating({
          title: item.title,
          description: item.description,
          id: item.id
        }, `room_${this.activeBrainstormData.id}`);
      }
    },
    removeItem(e, item) {
      if (e.keyCode === 46) {
        this.$Progress.start();
        if(this.dataset.scenario_id) {
          this.$store.dispatch("programmatic/deleteLevelData", {
            project_id: this.$route.params.id,
            scenario_id: item.scenario_id,
            level_id: item.id
          }).then(response => {
            shareContentApi(this.$route.params.brainstorm_id);
            this.$Progress.finish();
            this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id});
          });
        }
      }
    },
    deleteLevelItem(item) {
      if (confirm("are you sure want to delete this?")) {
        this.$Progress.start();
        if (this.activeData.scenario_id) {
          this.$store
              .dispatch("programmatic/deleteLevelData", {
                project_id: this.$route.params.id,
                scenario_id: this.activeData.scenario_id,
                level_id: (item) ? item.id : this.contextmenuItem.id,
              })
              .then((response) => {
                shareContentApi(this.$route.params.brainstorm_id);
                this.$Progress.finish();
                this.contextmenu = false;
                this.$store.dispatch("brainstormv1/getActiveBrainstormData", {
                  brainstorm_id: this.$route.params.brainstorm_id,
                });
              });
        }
      }
    },
    getL1Indexing(index, selfLevel, own = false) {
      let settings = this.moduleSettings;
      if(settings) {
        let indexing = '';
        let collection = settings.collections[selfLevel];
        if(settings.index_number) {
          indexing = parseFloat(collection.index_number) + index;
        }
        return indexing;
      }
      return '';
    },
    getData(items) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", {
        id: `${initial}${item.id}`,
        flip_1: `flip_1_l${item.level}_${item.id}`,
        flip_2: `flip_2_l${item.level}_${item.id}`,
        value: value,
        title: item.title,
        description: item.description,
        load: false
      });
    },
    applyAppearnce(value) {
      this.appearance = value
      let data = this.getLevelData
      this.setAppearanceToAll(data, value)
      this.contextmenu = false
    },
    setAppearanceToAll(data, value) {
      data.forEach(el => {
        el.appearance = value
        if (el.child.length > 0) {
          this.setAppearanceToAll(el.child, value)
        }
      });
    },
    applyAppearanceSelf(value) {
      this.contextmenuItem.appearance = value;
      this.contextmenu = false
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyDescription(value) {
      this.description = value
      this.contextmenu = false
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = {... this.contextmenuItem}
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.activeBrainstormData.module_id) {
        this.$store.dispatch("build/regularUpdate", obj)
      }
      if (this.activeBrainstormData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false
    },
    getModuleProperties() {
      if(this.dataset && this.dataset.scenario_id && this.module) {
        return this.module.properties;
      }
      return [];
    },
    expandItem(item, partname) {
      if(this.dataset && this.dataset.scenario_id && this.module && this.module.properties && this.module.properties.length > 0) {
        item.choosed_property_id = this.module.properties[0].id;
        item.choosed_property_param_id = this.module.properties[0].property_collections[0].id;
      }
      this.expandedArray.push({id: item.id, partname: partname, prop: item.choosed_property_id, param: item.choosed_property_param_id, value: item.current_prop_param})
    },
    collpaseItem(item) {
      var rmindex = this.expandedArray.findIndex(el => el.id == item.id);
      this.expandedArray.splice(rmindex, 1)
    },
    menuHandler(e, item) {
      this.contextmenu = true
      this.contextmenuItem = item
      let top = e.clientY - this.$el.getBoundingClientRect().y
      let left = e.clientX - this.$el.getBoundingClientRect().x
      let context = document.getElementById('context-menu-' + item.module_id)
      context.style.left = left+'px'
      context.style.top = top+'px'
    },
    closeMenuHandler(e) {
      let module_id = this.module.id
      var context = document.getElementById('context-menu-' + module_id);
      if (context && !context.contains(e.target) && !e.target.closest('.context-icon, ._option_buttons')) {
        this.contextmenu = false
      }
    },
    addNew(value) {
      let parent = this.contextmenuItem
      let chcount = parent.child.length
      let order = 1;
      if (chcount > 0) {
        order = chcount + 1
      }
      let obj = {};
      obj.parent_id = parent.id;
      obj.title = this.moduleSettings.collections[parent.level + 1].title;
      obj.level = parent.level + 1;
      obj.item_order = order;
      obj.order = order;
      obj.module_id = parent.module_id;
      obj.scenario_id = parent.scenario_id;
      this.$Progress.start();
      this.$emit("newSubChild", obj);
      this.contextmenu = false;
    },
  },
  mounted() {
    if(this.module) {
      this.setFlattenArray(this.module.collections);
    }
    socket.value.on("brainstorm_content_updating", (data) => {
      let item = this.flatten.find(levelData => levelData.id == data.id);
      if(item) {
        item.title = data.title;
        item.description = data.description;
      }
    });

    socket.value.on('brainstorm_api_updating', (data) => {
      let brainstormId = data.brainstormId || this.$route.params.brainstorm_id;
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: brainstormId});
    });
  }
}
</script>

<style scoped>
/* .single {
  width: 265px;
}
.double {
  width: 562px;
} */
.default-width {
  width: 265px;
}
.item-des {
  height: 132px;
}
.item-no-des {
  height: 50px;
}
/*textarea {*/
/*  height: 72px;*/
/*}*/
.textarea-primary-three textarea::placeholder {
  color: #C9C9C9;
}
.textarea-secondary-one textarea::placeholder {
  color: #8d8d8d;
}
.content-desc-height {
  height: 90px;
}
.expanded-div {
  height: 298px;
  width: 268px;
  left: -2px;
  top: -2px;
}
.expand-icon {
  left: 250px;
  bottom: -26px;
  opacity: .75;
  width: 17px;
}
.expand-text-icon {
  left: 212px;
  bottom: -23px;
  opacity: .75;
  width: 24px;
}
.expanded-icon {
  width: 17px;
  right: 0px;
  bottom: 15px;
}
.expanded-icon img {
  width: 10px;
}
.expand-item .bld-content-area {
  height: 250px;
}
.chartcontent {
  height: 60px;
  width: 116px
}
.chart-value-cont {
  width: 270px
}
.brainstorm-prop-selector {
  /* width: 200px; */
}
</style>
