<template>
    <div class="property-body fs-14 text-secondary-one">
        <div class="mb-6">
          <img src="/images/build/demo_image_1.jpg" alt="image">
        </div>
        <div class="mb-10" v-if="activeItem && Object.keys(activeItem).length !== 0">
          <div class="flex items-center">
            <span class="text-primary-one ls-5 font-poppins fs-14 fw-600" v-if="activeBrainstormData.scenario_id">{{ activeItem.index_code }}</span>
            <input type="text"
                   :class="{'ml-1': activeBrainstormData.scenario_id}"
                   class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-primary-one fw-600 ls-5 font-poppins fs-14 truncate"
                   v-model="activeItem.title"
                   @change="updateItem(activeItem)" />
          </div>
          <textarea v-if="activeItem && Object.keys(activeItem).length !== 0"
                    class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize"
                    placeholder="No description available"
                    @input="autoHeight($event)"
                    id="txtarea"
                    v-model="activeItem.description"
                    @change="updateItem(activeItem)"></textarea>
        </div>
        <h2 class="fw-900 mb-5">Horizontal Parameter:</h2>
        <div class="p-0 bg-transparent relative">
            <div class="i-mb-30" v-for="(dataset, index) in properties.filter(el => el.is_visible)" :key="index">
                <div class="flex justify-start items-center i-mb-25 property-row"
                      v-for="(prop, pindex) in dataset.property_collections.filter(el => el.is_visible && el.id == concept.hr_property_collection_id)"
                      :key="pindex">
                    <p class="prop-items i-mr-15">{{ prop.title }}</p>
                    <div class="track-box relative">
                      <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" min="0" max="10" step=".1" type="range"
                            v-if="activeItem.child.length === 0"
                            v-model="activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value"
                            @change="updateData(prop)"/>
                      <div class="value-track absolute left-0 top-0 bg-secondary-five w-full" v-else></div>
                      <div class="progress-track absolute left-0 top-0 bg-primary-five"
                          :style="{'width': (activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value * 10) +'%'}">
                      </div>
                      <span class="absolute invisible hover-value" :style="{'left': '48%', top: '-25px'}">
                        {{ activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value }}
                      </span>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="fw-900 mb-5">Vertical Parameter:</h2>
        <div class="p-0 bg-transparent relative">
            <div class="i-mb-30" v-for="(dataset, index) in properties.filter(el => el.is_visible)" :key="index">
                <div class="flex justify-start items-center i-mb-25 property-row"
                      v-for="(prop, pindex) in dataset.property_collections.filter(el => el.is_visible && el.id == concept.vr_property_collection_id)"
                      :key="pindex">
                    <p class="prop-items i-mr-15">{{ prop.title }}</p>
                    <div class="track-box relative">
                      <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" min="0" max="10" step=".1" type="range"
                            v-if="activeItem.child.length === 0"
                            v-model="activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value"
                            @change="updateData(prop)"/>
                      <div class="value-track absolute left-0 top-0 bg-secondary-five w-full" v-else></div>
                      <div class="progress-track absolute left-0 top-0 bg-primary-five"
                          :style="{'width': (activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value * 10) +'%'}">
                      </div>
                      <span class="absolute invisible hover-value" :style="{'left': '48%', top: '-25px'}">
                        {{ activeItem.properties.find(p => p.property_collection_id == prop.id).sample_value }}
                      </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "Property",
        props: ['viewer'],
        data() {
          return {
            // active : this.properties[0]
          }
        },
        computed: {
          ...mapGetters({
              properties: "build/modulePropertySet",
              activeModuleCollection: "build/activeModuleCollection",
              activeScenarioCollection: "programmatic/activeScenarioCollection",
              activeModule: "build/navigatorModule",
              activeBrainstormData: "brainstormv1/activeBrainstormData",
              concept: "concept/concept",
          }),
          activeItem() {
            if (this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
              return this.activeScenarioCollection
            }
            else {
              return this.activeModuleCollection
            }
          }
        },
        methods: {
          updateData(obj) {
            let updateItem = {}
            if(this.activeBrainstormData.scenario_id) {
              updateItem = this.activeScenarioCollection.properties.find(p => p.property_collection_id == obj.id)
              if(updateItem) {
                updateItem.sample_value = parseFloat(updateItem.sample_value)
              }
              this.$store.dispatch("brainstormv1/updatePropertyItemFromScenario", updateItem)
              .then(response => {
                  if (response) {
                    this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.activeBrainstormData.id})
                  }
              })
            }
            else {
              updateItem = this.activeModuleCollection.properties.find(p => p.property_collection_id == obj.id);
              if(updateItem) {
                updateItem.sample_value = parseFloat(updateItem.sample_value)
              }
              this.$store.dispatch("brainstormv1/updatePropertyItemFromBuild", updateItem)
              .then(response => {
                  if (response) {
                    this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.activeBrainstormData.id})
                  }
              })
            }

            this.$emit("changedDataFromCollaboration", {
              load: true
            });
          },
          updateItem(obj) {
            if(this.activeBrainstormData.scenario_id) {
              this.$store.dispatch("programmatic/updateCollection", obj);
            } else {
              this.$store.dispatch("build/regularUpdate", obj);
            }
          },
          autoHeight(e) {
            let textarea = e.target
            textarea.addEventListener("input", function (e) {
              this.style.height = "auto";
              this.style.height = this.scrollHeight + "px";
            });
          }
        },
    }
</script>

<style scoped>
.property-body {
  padding: 35px 0;
  max-height: 907px;
  overflow-y: auto;
  overflow-x: hidden;
}
.prop-items {
  width: 150px;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
.property-row:hover .hover-value {
  visibility: visible;
}
</style>
