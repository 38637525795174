import { ref , set } from 'vue';
import { useSocket } from '@/composable/socket/index.js';

export const brainstormContentSharing = () => {

    const { socket, io } = useSocket();

    const createRoom = (name) => {
        room.value = name;
        socket.value.emit("join_to_brainstorm", name)
    }
    const shareContentUpdating = (item, name) => {
        socket.value.emit('brainstorm_content_update', {
            id: item.id,
            title: item.title,
            description: item.description,
            room: name
        });
    }

    const shareContentApi = (brainstormId) => {
        socket.value.emit('brainstorm_api_update', {
            brainstormId
        });
    }

   

    return {
        shareContentUpdating,
        shareContentApi,
        createRoom,
        socket
    }
}