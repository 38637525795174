import store from '@/store';
import { get } from 'lodash';
import { ref } from 'vue';

// We want to use invitations list in multiple places
// 1. ShareModal component as a invited users listing
// 2. Edit update and delete permission checking
const invitations = ref([]);
const fullInvitationList = ref([]);
export const useShare = () => {
    const invite = async (data) => {
        await store.dispatch('brainstormv1/invitation', data);
    }

    const getInvitations = async (brainstormId) => {
       return await store.dispatch('brainstormv1/getInvitations', brainstormId)
    }

    const getFullName = (invitation) => {
        if(!invitation.user) {
            return invitation.email;
        }
        let { user } = invitation;
        return `${user.first_name} ${user.last_name}`;
    }

    const getAvatar = (invitation) => {
        if(!invitation.user) {
            return '/images/profile.png';
        }
        return get(invitation.user, 'image.file_path', '/images/profile.png');
    }

    const deleteInvitation = async (invitationId) => {
        return await store.dispatch('brainstormv1/deleteInvitation', invitationId)
    }

    const copyToClipboard = async (text) => {
        let status = null
        try {
            await navigator.clipboard.writeText(text);
            status = true
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
        return status;
    }

    const loadInvitations = async (brainstormId) => {
        let response = await getInvitations(brainstormId);
        fullInvitationList.value = response;
        invitations.value = response.filter(item => (item.user_id || item.email))
    }

    return {
        invitations,
        fullInvitationList,

        invite,
        getAvatar,
        getFullName,
        getInvitations,
        copyToClipboard,
        loadInvitations,
        deleteInvitation,
    }
}